import {
  Box,
  Button,
  Checkbox,
  useColorModeValue,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Alert,
  AlertIcon,
  Stack,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
  Image,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  Avatar,
  TagLabel,
  InputRightAddon,
  RadioGroup,
  Radio,
  AvatarBadge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Select as MySelect,
} from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import React, { Fragment, useEffect, useState } from "react";
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from "react-icons/md";
import { BsGithub, BsDiscord, BsPerson, BsImageFill } from "react-icons/bs";
import axios from "axios";
import axiosClient from "../../axios-client";
import { useNavigate, useLocation } from "react-router-dom";
import imgAddModule from "../../assets/img/add_module.png";
import defaultImage from "../../assets/img/default-image.jpg";
import { useMediaQuery } from "@chakra-ui/react";
import { useStateContext } from "../../context/ContextProvider";
import { TbCameraPlus } from "react-icons/tb";
import { AiOutlineCheck } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const animatedComponents = makeAnimated();

function AddModule() {
  // current user
  const { user, setUser } = useStateContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [listModule, setListModule] = useState([]);
  const [titreModule, setTitreModule] = useState([]);

  const [listResponsable, setListResponsable] = useState([]);
  const [responsable, setResponsable] = useState([]);
  const [type_Diplome, setType_Diplome] = useState();
  const [loading, setLoading] = useState(false);
  const [date_Validite, setDate_Validite] = useState("");

  // current user data
  useEffect(() => {
    axiosClient.get("auth/user/").then(({ data }) => {
      setUser(data);
    });
    axiosClient
      .get("/responsables/?page=1&page_size=10000")
      .then((res) => setListResponsable(res.data.results));

    axiosClient
      .get(`/module/?search=${location.state.idFormation}`)
      .then((res) => {
        setListModule(res.data);
      });
  }, []);

  function dataResponsable() {
    const options = [];
    listResponsable.map((val) => {
      if (val.role === "RESPO") {
        options.push({
          value: val.id,
          label: `
              ${val.last_name} 
              ${val.first_name}
              `,
        });
      }
    });
    return options;
  }

  const handleSelectResponsable = (e) => {
    setResponsable(e);
  };

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 1196px)");

  const location = useLocation();
  const [message, setMessage] = useState("");

  //
  const [titre, setTitre] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [etat, setEtat] = useState(true);
  const [signateur, setSignateur] = useState("");
  const [postSignateur, setPostSignateur] = useState("");
  const [genreSignateur, setGenreSignateur] = useState("");
  const [type_Validite, setType_Validite] = useState();
  const [periode, setPeriode] = useState();

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const date = new Date();
  date.setDate(date.getDate());

  const checkTitre = (newTitre) => { };

  const toast = useToast();
  const navigate = useNavigate();
  const addModule = async (titleM) => {
    setLoading(true)
    if (
      !titre ||
      !description ||
      !selectedImage ||
      !responsable ||
      !type_Diplome ||
      !type_Validite ||
      (type_Validite === "true" && !periode) ||
      !postSignateur ||
      !signateur ||
      !genreSignateur ||
      titre.trim() === "" ||
      description.trim() === "" ||
      signateur.trim() === "" ||
      postSignateur.trim() === ""
    ) {
      setMessage("Veuillez remplir les champs!");
      window.scrollTo(0, 0);
      setLoading(false)
      return;
    }

    let respos = [];
    const formData = new FormData();

    formData.append("description", description);
    formData.append("image", selectedImage);
    formData.append("titre", titre);
    formData.append("etat", etat);
    formData.append("formation", location.state.idFormation);
    for (let index = 0; index < responsable.length; index++) {
      formData.append("responsable", responsable[index].value);
      respos.push(responsable[index].value);
    }
    formData.append("diplome", type_Diplome);
    formData.append("signateur", signateur);
    formData.append("postSignateur", postSignateur);
    formData.append("genreSignateur", genreSignateur);
    if (type_Validite === "true") {
      formData.append("type_validite", true);
      formData.append("periode", periode);
    }
    if (type_Validite === "false") {
      formData.append("type_validite", false);
      formData.append("periode", 0);
    }
    formData.append("added_by", user.id);
    formData.append(
      "modification",
      JSON.stringify([
        {
          edited_by: `${user.first_name} ${user.last_name}`,
          edited_at: date,
          field: {
            etat: etat,
            titre: titre,
            description: description,
            image: "Ajoutée",
            diplome: type_Diplome,
            responsable: "Ajouté",
            signateur: signateur,
            postSignateur: postSignateur,
            genreSignateur: genreSignateur,
            type_Validite: type_Validite === "true" ? true : false,
            periode: type_Validite === "true" ? parseInt(periode) : 0,
          },
        },
      ])
    );
    // if (listModule.length > 0) {
    //   const check = listModule.find((obj) => {
    //     return obj.titre.toLowerCase() === titre.toLocaleLowerCase().trim();
    //   });
    //   if (check) {
    //     window.scrollTo(0, 0);
    //     return setMessage("Ce Module existe déjà dans cette formation !");
    //   }
    // }
    const check = await axiosClient.get(`check_exist/?check=Module&value=${titre}&idFormation=${location.state.idFormation}`);
    if (check.data.exists) {
      //   setIsLoading(false);
      window.scrollTo(0, 0);
      setMessage("Ce Module existe déjà dans cette formation !");
      setLoading(false)
      return;
    }
    axiosClient.post(`module/`, formData).then(() => {
      toast({
        description: `le module ${titleM} est ajoutée avec succès à la formation ${location.state.titreFormation}`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
      setLoading(false)
      navigate("/list_allModules", {
        state: {
          idFormation: location.state.idFormation,
          titreF: location.state.titreFormation,
        },
      });
    });
  };

  const [messageDate_Validite, setMessageDate_Validite] = useState(false);

  const colorbg = useColorModeValue("white", "#2d3748");
  const txtcolor = useColorModeValue("black", "white");
  const colorbghover = useColorModeValue("#e2e8f0", "#171923");

  return (
    <Fragment>
      <Box mb={5} w="90%">
        <Heading
          bgGradient="linear(to-l,  #ffd140, #089bd7)"
          bgClip="text"
          fontSize={{ base: "2xl", sm: "3xl" }}
        >
          Nouveau Module
        </Heading>
      </Box>
      <Box
        w={isNotSmallerScreen ? "100%" : "100%"}
        bg={useColorModeValue("white", "gray.700")}
        borderRadius="lg"
        m={{ sm: 2, md: 2, lg: 2 }}
        p={{ sm: 4, md: 4, lg: 4 }}
      >
        <Box p={4} rounded="xl" align="center" justify="center">
          <VStack spacing={3} w={{base:"100%" , lg:"90%"}}>
            {message && (
              <Alert status="error" rounded="md">
                <AlertIcon />
                {message}
              </Alert>
            )}

            {/* <Divider /> */}
            <Flex direction={{base : 'column' , lg:'row'}} align={"center"} w={"100%"} gap={10}>
              <Avatar
                mb={2}
                size="2xl"
                src={!selectedImage ? defaultImage : image}
                cursor="pointer"
                position="relative"
              >
                <AvatarBadge
                  borderColor="papayawhip"
                  bg="tomato"
                  boxSize="1.25em"
                >
                  <TbCameraPlus size={20} color="black" />
                </AvatarBadge>
                <InputGroup
                  borderColor="#E0E1E7"
                  position="absolute"
                  top="0"
                  left={0}
                >
                  <Input
                    opacity="0"
                    cursor="pointer"
                    type="file"
                    h={105}
                    name="file"
                    accept="image/*"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                </InputGroup>
              </Avatar>
              <VStack w="100%">
                <Stack
                  mt={3}
                  w="100%"
                  direction={{ base: "column", md: "row", lg: "row" }}
                >
                  <FormControl id="name" isRequired mr={{ base: 0, md: 2, lg: 2 }}>
                    <FormLabel>Titre Module</FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                      <Input
                        maxLength={50}
                        type="text"
                        height="37px"
                        name="titre"
                        onChange={(e) => setTitre(e.target.value)}
                        placeholder="Titre Module"
                      />
                    </InputGroup>
                    <Flex justify="end">
                      {titre.length > 40 ? (
                        <Text fontSize="xs" color="red">
                          {titre.length}/50 caractères !
                        </Text>
                      ) : (
                        <Text fontSize="xs">
                          {titre.length}/50{" "}
                          {titre.length > 1 ? "caractères" : "caractère"}
                        </Text>
                      )}
                    </Flex>
                  </FormControl>
                  <FormControl
                    id="responsable"
                    isRequired
                    ml={{ base: 0, md: 2, lg: 2 }}
                  >
                    <FormLabel>Responsable</FormLabel>
                    <Select
                      placeholder="Selectionner les responsables..."
                      components={animatedComponents}
                      options={dataResponsable()}
                      onChange={handleSelectResponsable}
                      isSearchable
                      noOptionsMessage={() => "Responsable introuvable"}
                      isMulti
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          textAlign: "left",
                          backgroundColor: colorbg,
                          color: txtcolor,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          textAlign: "left",
                          backgroundColor: colorbg,
                          cursor: "pointer",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: txtcolor,
                          backgroundColor: state.isSelected ? colorbg : "inherit",
                          "&:hover": {
                            backgroundColor: colorbghover,
                          },
                          cursor: "pointer",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: txtcolor,
                        }),
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  w="100%"
                  direction={{ base: "column", md: "row", lg: "row" }}
                  spacing={{ base: 0, md: 4, lg: 4 }}
                >
                  <FormControl
                    id="signateur"
                    isRequired
                    mr={{ base: 0, md: 2, lg: 2 }}
                  >
                    <FormLabel>Signataire</FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                      <Input
                        maxLength={50}
                        type="text"
                        height="37px"
                        name="signateur"
                        onChange={(e) => setSignateur(e.target.value)}
                        placeholder="Signataire"
                      />
                    </InputGroup>
                    <Flex justify="end">
                      {signateur.length > 40 ? (
                        <Text fontSize="xs" color="red">
                          {signateur.length}/50 caractères !
                        </Text>
                      ) : (
                        <Text fontSize="xs">
                          {signateur.length}/50{" "}
                          {signateur.length > 1 ? "caractères" : "caractère"}
                        </Text>
                      )}
                    </Flex>
                  </FormControl>
                  <FormControl
                    id="postSignateur"
                    isRequired
                    ml={{ base: 0, md: 2, lg: 2 }}
                  >
                    <FormLabel>Post Signataire</FormLabel>
                    <InputGroup borderColor="#E0E1E7">
                      <Input
                        maxLength={50}
                        type="text"
                        height="37px"
                        name="postSignateur"
                        onChange={(e) => setPostSignateur(e.target.value)}
                        placeholder="Post Signataire"
                      />
                    </InputGroup>
                    <Flex justify="end">
                      {postSignateur.length > 40 ? (
                        <Text fontSize="xs" color="red">
                          {postSignateur.length}/50 caractères !
                        </Text>
                      ) : (
                        <Text fontSize="xs">
                          {postSignateur.length}/50{" "}
                          {postSignateur.length > 1 ? "caractères" : "caractère"}
                        </Text>
                      )}
                    </Flex>
                  </FormControl>
                </Stack>
              </VStack>
            </Flex>

            <Flex
              w="100%"
              align="start"
              direction={{ base: "column", md: "row", lg: "row" }}
            >
              <FormControl id="genreSignateur" isRequired>
                <FormLabel>Genre Signataire</FormLabel>

                <RadioGroup
                  name="type_Diplome"
                  onChange={setGenreSignateur}
                  value={genreSignateur}
                >
                  <Flex>
                    <Radio
                      mr={5}
                      size="md"
                      value="Femme"
                      onChange={(e) => setGenreSignateur(e.target.value)}
                      colorScheme="green"
                    >
                      Femme
                    </Radio>
                    <Radio
                      size="md"
                      value="Homme"
                      onChange={(e) => setGenreSignateur(e.target.value)}
                      colorScheme="green"
                    >
                      Homme
                    </Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </Flex>

            <Stack
              w="100%"
              direction={{ base: "column", md: "row", lg: "row" }}
              spacing={{ base: 0, md: 5, lg: 5 }}
            >
              <FormControl
                id="type_validite"

                w={{ base: "100%", md: "50%", lg: "50%" }}
                isRequired
                mr={{ base: 0, md: 2, lg: 2 }}
              >
                <FormLabel>Type de validité</FormLabel>

                <MySelect
                  value={type_Validite}
                  onChange={(e) => setType_Validite(e.target.value)}
                >
                  <option value="">Choisissez le type de validité</option>
                  <option value={true}>Par mois</option>
                  <option value={false}>À vie</option>
                </MySelect>
              </FormControl>


              {type_Validite === "true" ? (
                <FormControl

                  w={{ base: "100%", md: "50%", lg: "50%" }}
                  id="validite_certificat"
                  isRequired
                  ml={{ base: 0, md: 2, lg: 2 }}
                >
                  <FormLabel>Date de validité</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <Input
                      type="number"
                      pattern="[0-9]"
                      maxLength={3}
                      size="md"
                      name="validite_certificat"
                      onChange={(e) => {
                        if (e.target.value.length > 3) {
                          setMessageDate_Validite(true);
                        } else {
                          setMessageDate_Validite(false);
                        }
                        setPeriode(e.target.value);
                      }}
                      placeholder="Date de validité"
                    />
                    <InputRightAddon children="Mois" />
                  </InputGroup>
                  {parseInt(periode) < 0 ? (
                    <Flex justify="space-between">
                      <div>
                        <Text fontSize="xs" fontWeight="semibold" color="red">
                          Entrez un nombre positif
                        </Text>
                      </div>
                      <div>
                        <>
                          {periode?.length ? (
                            <Text fontSize="xs" color="red">
                              {periode.length}/3 caractères !
                            </Text>
                          ) : (
                            <Text fontSize="xs">
                              {periode?.length}/3{" "}
                              {periode?.length > 1 ? "caractères" : "caractère"}
                            </Text>
                          )}
                        </>
                      </div>
                    </Flex>
                  ) : (
                    <Flex justify="space-between">
                      <div>
                        {messageDate_Validite && (
                          <Text fontSize="xs" fontWeight="semibold" color="red">
                            Il ne faut pas dépasser 3 chiffres
                          </Text>
                        )}
                      </div>
                      <div>
                        <>
                          {periode?.length ? (
                            <Text fontSize="xs" color="red">
                              {periode?.length}/3 caractères !
                            </Text>
                          ) : (
                            <Text fontSize="xs">
                              {periode?.length}/3{" "}
                              {periode?.length > 1 ? "caractères" : "caractère"}
                            </Text>
                          )}
                        </>
                      </div>
                    </Flex>
                  )}
                </FormControl>
              ) : <FormControl
                visibility={false}
                w={{ base: "100%", md: "50%", lg: "50%" }}
                id="validite_certificat"
                isRequired
                ml={{ base: 0, md: 2, lg: 2 }}
              ></FormControl>}
            </Stack>

            <FormControl id="diplome" isRequired ml={{ base: 0, md: 0, lg: 0 }}>
              <FormLabel>Diplôme</FormLabel>

              <RadioGroup
                name="type_Diplome"
                onChange={setType_Diplome}
                value={type_Diplome}
              >
                <Flex>
                  <Radio
                    mr={5}
                    size="md"
                    value="Attestation"
                    onChange={(e) => setType_Diplome(e.target.value)}
                    colorScheme="green"
                  >
                    Attestation
                  </Radio>
                  <Radio
                    size="md"
                    value="Certificat"
                    onChange={(e) => setType_Diplome(e.target.value)}
                    colorScheme="green"
                  >
                    Certificat
                  </Radio>
                </Flex>
              </RadioGroup>
            </FormControl>

            <FormControl id="description" isRequired mt={2}>
              <FormLabel>Description</FormLabel>
              <Textarea
                maxLength={250}
                borderColor="gray.300"
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                _hover={{
                  borderRadius: "gray.300",
                }}
                placeholder="Description"
              />
              <Flex justify="end">
                {description.length > 240 ? (
                  <Text fontSize="xs" color="red">
                    {description.length}/250 caractères !
                  </Text>
                ) : (
                  <Text fontSize="xs">
                    {description.length}/250{" "}
                    {description.length > 1 ? "caractères" : "caractère"}
                  </Text>
                )}
              </Flex>
            </FormControl>

            {/* <FormControl id="etat" display="flex" justifyContent="start">
              <Checkbox
                size="lg"
                colorScheme="green"
                name="etat"
                onChange={() => setEtat(!etat)}
              >
                Activez le Module
              </Checkbox>
            </FormControl> */}
            <FormControl
              display="flex"
              justifyContent="end"
              id="name"
              float="right"
            >
              {!titre ||
                !description ||
                !selectedImage ||
                responsable.length === 0 ||
                !type_Diplome ||
                !signateur ||
                !postSignateur ||
                !genreSignateur ||
                !type_Validite ||
                (type_Validite === "true" &&
                  (!periode || periode < 0 || messageDate_Validite)) ? (
                <Button
                  variant="solid"
                  bg="#ffd140"
                  color="black"
                  _hover="none"
                  title="Veuillez remplir tout les champs"
                  isDisabled
                >
                  Ajouter
                </Button>
              ) : (
                <Button
                isLoading={loading}
                  variant="solid"
                  bg="#ffd140"
                  color="black"
                  _hover="none"
                  onClick={() => addModule(titre)}
                >
                  Ajouter
                </Button>
              )}
            </FormControl>
          </VStack>
        </Box>
      </Box>

      {/* <Modal
        closeOnOverlayClick={!selectedImage ? true : false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajout de l'image</ModalHeader>
          <ModalBody pb={6}>
            <FormControl
              id="image"
              isRequired
              display="flex"
              justifyContent="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                height="200px"
                w="80%"
                border={!selectedImage ? "1px dashed" : null}
                position="relative"
                borderColor="#2b6cb0"
                cursor="pointer"
              >
                {!selectedImage && (
                  <>
                    <BsImageFill size="70px" color="#2b6cb0" />
                    <Text
                      fontSize={{ base: "md", md: "lg", lg: "lg" }}
                      fontWeight="semibold"
                      color="#2b6cb0"
                    >
                      Parcourir l'image à télécharger
                    </Text>
                  </>
                )}
                {image && selectedImage && (
                  <Box textAlign="center">
                    <Image
                      src={image}
                      w="full"
                      h="auto"
                      maxH="200px"
                      objectFit="contain"
                      alt="Dan Abramov"
                      rounded="lg"
                    />
                  </Box>
                )}
              </Flex>
              <InputGroup
                borderColor="#E0E1E7"
                position="absolute"
                top="0"
                left={10}
              >
                <Input
                  opacity="0"
                  cursor="pointer"
                  type="file"
                  height="200px"
                  w="80%"
                  size="lg"
                  name="file"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                  placeholder="Titre de Formation"
                  accept="image/jpg, image/png, image/jpeg, image/svg, image/tif"
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          {selectedImage && (
            <ModalFooter>
              <Button
                onClick={() => {
                  setSelectedImage(null);
                  onClose();
                }}
                colorScheme="red"
                mr={3}
              >
                <FaTimes />
              </Button>
              <Button
                onClick={onClose}
                colorScheme="whatsapp"
                isDisabled={!selectedImage}
              >
                <AiOutlineCheck />
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal> */}
    </Fragment>
  );
}

export default AddModule;
