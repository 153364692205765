import {
  Text,
  Box,
  Thead,
  Table,
  Flex,
  Tbody,
  Spacer,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  HStack,
  Center,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  useColorModeValue,
  Skeleton,
  InputLeftElement,
} from "@chakra-ui/react";

import { CheckIcon, CloseIcon, Search2Icon, SearchIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { format } from "date-fns";
import PieChart from "./chart/StatisqueAdmission";
import Users from "./chart/ReussiteParTentative";
import Moyenne from "./chart/NoteMoyenneParModule";
import MyPagination from "../../components/MyPagination";

function Resultat() {
  const [globalResult, setGlobalResult] = useState([]);
  const [resultats, setResultats] = useState([]);
  const [tentative, setTentative] = useState("");
  const [admis, setAdmis] = useState(null);
  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('');
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true)
  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const mode = useColorModeValue("black", "white")
  const statColor = useColorModeValue("white", "gray.600")
  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    axiosClient.get(`/resultat/getAllResults/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchValue}&tentative=${tentative}&admis=${admis}&date1=${date1}&date2=${date2}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setResultats(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [pageSize, currentPage, searchValue, tentative, admis, date1, date2])


  //filtre date
  const handleDate1 = (dateString) => {
    setDate1(dateString);
  };

  const handleDate2 = (dateString) => {
    setDate2(dateString);
  };



  return (
    <Box w="100%" p={4} borderRadius="xl" color={mode}>
      {(paginationData?.length !== 0) && 
       <Flex
       bg={statColor}
       rounded={"lg"}
       mb={5}
       flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap" }}
       justifyContent={{ base: "flex-start", sm: "flex-start", md: "center" }}
       alignItems={"center"}
       py={5}
       gap={5}
       direction={{ base: "column", md: "row", lg: "row" }}
     >

       <Box w={{ base: "100%", sm: "80%", md: "30%" }} >
         <center>
           <PieChart />
         </center>
       </Box>

       <Box w={{ base: "100%", sm: "80%", md: "30%" }}>
         <center>
           <Users />
         </center>
       </Box>

       <Box w={{ base: "100%", sm: "80%", md: "30%" }}>
         <center>
           <Moyenne />
         </center>
       </Box>
     </Flex>
      }
     
     

      <Heading
        textAlign={"left"}
        fontSize={{ base: "2xl", sm: "3xl" }}
        fontWeight="bold"
        bgGradient="linear(to-l, #ffd140, #089bd7)"
        bgClip="text"
      >
        Liste des résultats
      </Heading>
      <br />
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          direction={{ base: "column", lg: "row" }}
          w={"100%"}
        >
          <InputGroup mt={{ base: 0, lg: 7 }}
              mb={{ base: 3, lg: 0 }} w={{ base: "100%", md: "40%", lg: "30%" }}>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                type="text"
                placeholder="Recherche..."
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputGroup>
          <Spacer />

          <Flex
            justifyContent="space-between"
            alignItems="center"
            direction={{ base: "column", lg: "row" }}
            w={{ base: "100%", lg: "55%" }}
            textAlign={"center"}
            fontSize={{ base: 9, md: 13 }}
            flex={25}
          >
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Admission:
              </Text>
              <Select
                mb={{ base: 3, lg: 0 }}
                color={useColorModeValue("black", "gray.300")}
                onChange={(e) => setAdmis(e.target.value === '' ? null : e.target.value === 'true')}
              >
                <option value="">Toutes</option>
                <option value="true">Admis</option>
                <option value="false">Non admis</option>
              </Select>
            </Box>
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Tentative:
              </Text>
              <Select
                mb={{ base: 3, lg: 0 }}
                color={useColorModeValue("black", "gray.300")}
                onChange={(e) => setTentative(e.target.value)}
              >
                <option value="">Toutes</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </Box>
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Aprés le:
              </Text>
              <form onChange={(e) => handleDate1(e.target.value)}>
                <Input
                  mb={{ base: 3, lg: 0 }}
                  color={useColorModeValue("black", "gray.300")}
                  type="date"
                />
              </form>
            </Box>
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Avant le:
              </Text>
              <form onChange={(e) => handleDate2(e.target.value)}>
                <Input
                  color={useColorModeValue("black", "gray.300")}
                  type="date"
                />
              </form>
            </Box>
          </Flex>
        </Flex>
        <br />

        <TableContainer mt={15} >
          <Table >
            <Thead>
              <Tr>
                <Th >Formation</Th>
                <Th>Module</Th>
                <Th>Email</Th>
                <Th>Niveau du test</Th>
                <Th>Tentative</Th>
                <Th>Score</Th>
                <Th>Date de passage</Th>
                <Th>Admis</Th>
              </Tr>
            </Thead>

            <Tbody>
              {loading &&
                <Tr>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>

                </Tr>}
              {(paginationData?.length !== 0) &&
                resultats.map((result, key) => (
                  <Tr key={key}>
                    <Td fontWeight={"bold"}>{result.formation}</Td>
                    <Td fontWeight={"bold"}>{result.module}</Td>
                    <Td fontWeight={"bold"}>{result.username}</Td>
                    <Td fontWeight={"bold"}>{result.niveau}</Td>
                    <Td fontWeight={"bold"}>{result.tentative}</Td>
                    {result.valider ? (
                      <Td size={13} color={"#1EB14C"} fontWeight="bold">
                        {result.resultat.toFixed(2)}%
                      </Td>
                    ) : (
                      <Td size={13} color={"red"} fontWeight="bold">
                        {result.resultat.toFixed(2)}%
                      </Td>
                    )}
                    <Td>{new Date(result.date_de_passage).toLocaleDateString()}</Td>
                    {result.valider ? (
                      <Td color={"#1EB14C"}>
                        {" "}
                        <button>
                          <CheckIcon />
                        </button>{" "}
                      </Td>
                    ) : (
                      <Td color={"red"}>
                        {" "}
                        <button>
                          <CloseIcon />
                        </button>{" "}
                      </Td>
                    )}
                  </Tr>
                ))}

              {(((searchValue || admis != null || tentative || date1 || date2) && paginationData?.length === 0) || (checkLengthData)) && (
                <Tr>
                  <Td colSpan={8}>
                    Aucune ligne correspondante n'a été trouvée.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
            <Box flex="1">
              <MyPagination
                paginationData={paginationData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                PAGE_SIZE={pageSize}
              />
            </Box>

            <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              {/* Add more options as needed */}
            </Select>
          </Flex>
      </Stack>
    </Box>
  );
}

export default Resultat;
