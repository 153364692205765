import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { AiFillDelete } from 'react-icons/ai';
import { SearchIcon } from '@chakra-ui/icons'
import MyPagination from "../MyPagination";
import { BiDetail } from 'react-icons/bi';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import moment from 'moment';


import {
  Flex,
  Box,
  Heading,
  Button,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  InputGroup,
  HStack,
  Td,
  TableContainer,
  useColorModeValue,
  InputLeftElement,
  Badge,
  useToast,
  Input,

  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Image,
  Alert,
  AlertIcon,
  Skeleton,
} from '@chakra-ui/react';

import { Switch } from '@chakra-ui/react';
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from 'react-icons/md';


export const Plainte = () => {

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);

  const [reponseUser, setReponseUser] = useState('')
  const [img, setImg] = useState('')

  const [resUserByWho, setResUserByWho] = useState('')
  const [userTime, setUserTime] = useState('')
  const [resByWho, setResByWho] = useState('')
  const [time, setTime] = useState('')

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onOpenImg = () => {
    setIsImgOpen(true);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
    setDeleteId(null)
  };

  const onCloseImg = () => {
    setIsImgOpen(false);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////
  const [deleteId, setDeleteId] = useState(null);
  const [reponse, setReponse] = useState('');
  const [sujet, setSujet] = useState('');
  const [content, setContent] = useState('');




  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  //toast variable
  const toast = useToast()

  //search variable
  const [searchTerm, setSearchTerm] = useState('');

  //plainte data
  const [data, setData] = useState([]);
  //selected plainte
  const [dataDetail, setDataDetail] = useState('');


  const [checkLengthDataStd, setCheckLengthDataStd] = useState(false)
  const [checkLengthDataAdm, setCheckLengthDataAdm] = useState(false)
  const [checkLengthDataRes, setCheckLengthDataRes] = useState(false)
  const [checkLengthDataMas, setCheckLengthDataMas] = useState(false)

  const [loadingData, setLoadingData] = useState(true)
  const [paginationDataSt, setPaginationDataSt] = useState()
  const [paginationDataAd, setPaginationDataAd] = useState()
  const [paginationDataRes, setPaginationDataRes] = useState()
  const [paginationDataMs, setPaginationDataMs] = useState()

  const [studentData, setStudentData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [respoData, setRespoData] = useState([]);
  const [masterData, setMasterData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedEtat, setSelectedEtat] = useState(null);
  const [selectedUrgence, setSelectedUrgence] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {

    axiosClient.get(`/plainte/getAllTicketsStudents/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataSt(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataStd(true)
        }
        else {
          setStudentData(filteredData);
          setCheckLengthDataStd(false)
        }
        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

    axiosClient.get(`/plainte/getAllTicketsMaster/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataMs(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataMas(true)
        }
        else {
          setMasterData(filteredData);
          setCheckLengthDataMas(false)
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

    axiosClient.get(`/plainte/getAllTicketsAdmin/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataAd(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataAdm(true)
        }
        else {
          setAdminData(filteredData);
          setCheckLengthDataAdm(false)
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

    axiosClient.get(`/plainte/getAllTicketsRespo/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataRes(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataRes(true)
        }
        else {
          setRespoData(filteredData);
          setCheckLengthDataRes(false)
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

  }, [selectedEtat, selectedCategory, pageSize, currentPage, searchTerm, , selectedUrgence]);

  function update() {
    axiosClient.get(`/plainte/getAllTicketsStudents/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataSt(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataStd(true)
        }
        else {
          setStudentData(filteredData);
          setCheckLengthDataStd(false)
        }
        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

    axiosClient.get(`/plainte/getAllTicketsMaster/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataMs(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataMas(true)
        }
        else {
          setMasterData(filteredData);
          setCheckLengthDataMas(false)
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

    axiosClient.get(`/plainte/getAllTicketsAdmin/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataAd(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataAdm(true)
        }
        else {
          setAdminData(filteredData);
          setCheckLengthDataAdm(false)
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });

    axiosClient.get(`/plainte/getAllTicketsRespo/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}&urgence=${selectedUrgence}`)
      .then((response) => {
        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationDataRes(response.data);

        if (filteredData.length === 0) {
          setCheckLengthDataRes(true)
        }
        else {
          setRespoData(filteredData);
          setCheckLengthDataRes(false)
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });
  }

  function formatDateToCustomFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }


  //delete plainte
  const handleDelete = (id = deleteId) => {
    if (!id) return;
    axiosClient.delete(`/plainte/${id}/`)
      .then((response) => {
        setData((prevData) => prevData.filter((row) => row.id !== id));
        toast({
          description: "le ticket est supprimé avec succès",
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: 'bottom-right'
        })
      })
      .catch((error) => {
        console.error(error);
      });
    onCloseAlert()
  }

  const [plainteData, setPlainteData] = useState({ content: "", sujet: "", etat: 0, user: "", response: "" });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPlainteData((prevUserData) => ({
      ...prevUserData,
      response: value,
    }));
  };

  const [message, setMessage] = useState('')

  //send response
  const sendResponse = (id = deleteId) => {
    if (!id) return;
    if (plainteData.response.trim() === "") {
      setMessage("Veuillez saisir une réponse");
      return;
    }
    function formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    const updatedPlainteData = {
      response: plainteData.response,
      edited_by: user.first_name + ' ' + user.last_name,
      responsebywho: user.first_name + ' ' + user.last_name,
      responsetime: formatDateTime(new Date())
    };
    axiosClient.patch(`/plainte/${deleteId}/`, updatedPlainteData)
      .then(() => {
        toast({
          description: "votre réponse est envoyée avec succès",
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        update()
        setMessage("")
      }
      )
      .catch(error => console.error(error));
    onCloseAlert()
  }



  //edit the plainte state ('encours','reglée')
  const handleEtatClick = (id, etat) => {
    const formData = new FormData();
    formData.append("etat", !etat);
    formData.append("edited_by", user.first_name + ' ' + user.last_name);

    axiosClient.patch(`/plainte/${id}/`, formData)
      .then((response) => {
        fetchCountTickets()
        const updatedRow = response.data;
        // Update the row object with the new etat value
        setData(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
              date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
              edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
            };
          }
          return row;
        }));
        update()
        setMessage("")
        console.error(response);
      })
      .catch((error) => console.error(error));
    if (!etat) {
      toast({
        description: "la plainte est reglée",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right'
      })

    }
    else {
      toast({
        description: "la plainte est encours",
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right'
      })
    }
  }
  const {countapprenant, countr ,counta ,countm} = useStateContext();

  const { user, setUser ,fetchCountTickets} = useStateContext();


  useEffect(() => {

    fetchCountTickets()

  }, [user.id, user.role]);


  const viderInputs = () => {
    setSearchTerm('')
    setSelectedCategory('')
    setSelectedEtat('')
  }

  const bg = useColorModeValue("white", "gray.700");

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
    }

  };
  return (
    <Box mt="5px" >
      <Heading mb={10} bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: '2xl', sm: '3xl' }}>
        Propositions d'amélioration
      </Heading>
      <Stack>
        <Tabs variant="enclosed">
          <TabList overflowX={'auto'} overflowY={'hidden'} css={{
            /* Hide scrollbar */
            "&::-webkit-scrollbar": {
              display: "none",
            },
            /* Optional: Firefox scrollbar */
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}>
            {user.role === "SUPERADMIN" &&
              <Tab _selected={{ color: "white", bg: "#089bd7" }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Master
                  {countm > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countm}
                    </Badge>
                  )}</Text>
              </Tab>}
            {(user.role === "SUPERADMIN" || user.role === "MASTER") &&

              <Tab _selected={{ color: "white", bg: "#089bd7" }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Administrateurs
                  {counta > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {counta}
                    </Badge>
                  )}
                </Text>
              </Tab>}
            {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&

              <Tab _selected={{ color: "white", bg: "#089bd7" }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Responsables
                  {countr > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countr}
                    </Badge>
                  )}</Text>
              </Tab>}
            {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN" || user.role === "RESPO") &&

              <Tab _selected={{ color: "white", bg: "#089bd7" }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Apprenants
                  {countapprenant > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countapprenant}
                    </Badge>
                  )}</Text>
              </Tab>}
          </TabList>
          <TabPanels >
            {user.role === "SUPERADMIN" &&

              <TabPanel>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex w={"100%"}>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedCategory(e.target.value)
                        }}>
                          <option value="">Toutes les catégories</option>
                          <option value="technical_support">Support technique</option>
                          <option value="account_assistance">Assistance pour le compte</option>
                          <option value="course_inquiries">Demandes de renseignements sur les cours</option>
                          <option value="feedback_suggestions">Commentaires et suggestions</option>
                          <option value="content_issues">Problèmes de contenu</option>
                          <option value="general_inquiries">Demandes générales</option>
                        </Select>

                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')
                        }}>
                          <option value="">Tout</option>
                          <option value="true">Reglée</option>
                          <option value="false">En cours</option>
                        </Select>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedUrgence(e.target.value)
                        }}>
                          <option value="">Tout</option>
                          <option value="élevée">élevée</option>
                          <option value="moyenne">moyenne</option>
                          <option value="faible">faible</option>
                        </Select>
                      </Flex>

                      <InputGroup w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          placeholder="Recherche..."
                          value={searchTerm}
                          onChange={(e) => {
                            setCurrentPage(0)
                            setSearchTerm(e.target.value)
                          }}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}
                    <TableContainer>
                      <Table >
                        <Thead>
                          <Tr>
                            <Th>Code</Th>
                            <Th>Utilisateur</Th>
                            <Th>Date</Th>
                            <Th>Etat</Th>
                            <Th>Categorie</Th>
                            <Th>Urgence</Th>
                            <Th>Image</Th>
                            <Th>Contenu</Th>
                            <Th>Reponse</Th>
                            <Th>Historique</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {loadingData &&
                            <Tr>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>

                            </Tr>}
                          {(paginationDataMs?.length !== 0) &&
                            masterData?.map((row) => (
                              <Tr key={row.id}>
                                <Td>{row.code}</Td>
                                <Td>{row.user_firstName} {row.user_lastName}</Td>

                                <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>
                                <Td>
                                  <Flex>
                                    <Stack direction='row' mr="3px">
                                      <Switch
                                        colorScheme='green'
                                        isChecked={row.etat}
                                        onChange={() => handleEtatClick(row.id, row.etat)}
                                      />
                                    </Stack>
                                    <Badge
                                      rounded={'lg'}
                                      colorScheme={!row.etat ? 'red' : 'green'}
                                    >
                                      {row.etat ? 'reglée' : 'encours'}
                                    </Badge>
                                  </Flex>
                                </Td>
                                <Td>{row.category === "technical_support" ? "Support technique" :
                                  row.category === "account_assistance" ? "Assistance pour le compte" :
                                    row.category === "course_inquiries" ? "Demandes de renseignements sur les cours" :
                                      row.category === "feedback_suggestions" ? "Commentaires et suggestions" :
                                        row.category === "content_issues" ? "Problèmes de contenu" :
                                          row.category === "general_inquiries" ? "Demandes générales" : ""}</Td>
                                <Td>
                                  {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>élevée</Badge> :
                                    row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>moyenne</Badge> :
                                      row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>faible</Badge> : ""}
                                </Td>
                                <Td>
                                  {row.image &&
                                    <Image
                                      onClick={() => {
                                        setImg(row.image)
                                        onOpenImg();
                                      }}
                                      cursor='pointer'
                                      objectFit='cover'
                                      boxSize={10}
                                      src={row.image}
                                    />}
                                </Td>
                                <Td p={4}><Button
                                  size="sm"
                                  onClick={() => {
                                    setDeleteId(row.id);
                                    setSujet(row.sujet);
                                    setContent(row.content);
                                    onOpen();
                                  }}
                                  colorScheme='blue'
                                  leftIcon={<BiDetail />}>
                                  Afficher
                                </Button></Td>
                                <Td p={4}>

                                  <Button
                                    size="sm"
                                    //onClick={onOpen}

                                    colorScheme='green'
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      setReponse(row.response);
                                      setReponseUser(row.responseUser)
                                      onOpenAlert();
                                      setResUserByWho(row.responseUserbywho)
                                      setUserTime(row.responseUsertime)

                                      setResByWho(row.responsebywho)
                                      setTime(row.responsetime)
                                    }}
                                    leftIcon={<MdOutlineQuestionAnswer />}>
                                    Repondre
                                  </Button></Td>
                                <Td p={4}><Popover placement="left" >
                                  <PopoverTrigger>
                                    <Button
                                      size="sm"
                                      mr="5px"
                                      bg="gray.300"
                                      color={"black"}
                                      leftIcon={<MdHistory />}>
                                      Historique
                                    </Button>

                                  </PopoverTrigger>
                                  <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                      Historique
                                    </PopoverHeader>
                                    <PopoverArrow bg="blue.200" />
                                    <PopoverCloseButton />
                                    <PopoverBody>

                                      <div style={{ marginBottom: '8px' }}>
                                        Modifié par : {row.edited_by}
                                      </div>

                                      <div>
                                        Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                      </div>
                                    </PopoverBody>
                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                    </PopoverHeader>
                                  </PopoverContent>
                                </Popover></Td>
                                {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Supprimer
                                    </Button></Td> */}
                              </Tr>
                            ))}

                          {/* when there is no search data found */}
                          {(((searchTerm || selectedCategory || selectedEtat !== null || selectedUrgence) && paginationDataMs?.length === 0) || (checkLengthDataMas)) && (
                            <Tr>
                              <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          paginationData={paginationDataMs}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          PAGE_SIZE={pageSize}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>}
            {(user.role === "SUPERADMIN" || user.role === "MASTER") &&

              <TabPanel>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex w={"100%"}>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedCategory(e.target.value)
                        }}>
                          <option value="">Toutes les catégories</option>
                          <option value="technical_support">Support technique</option>
                          <option value="account_assistance">Assistance pour le compte</option>
                          <option value="course_inquiries">Demandes de renseignements sur les cours</option>
                          <option value="feedback_suggestions">Commentaires et suggestions</option>
                          <option value="content_issues">Problèmes de contenu</option>
                          <option value="general_inquiries">Demandes générales</option>
                        </Select>

                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')
                        }}>
                          <option value="">Tout</option>
                          <option value="true">Reglée</option>
                          <option value="false">En cours</option>
                        </Select>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedUrgence(e.target.value)
                        }}>
                          <option value="">Tout</option>
                          <option value="élevée">élevée</option>
                          <option value="moyenne">moyenne</option>
                          <option value="faible">faible</option>
                        </Select>
                      </Flex>

                      <InputGroup w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          placeholder="Recherche..."
                          value={searchTerm}
                          onChange={(e) => {
                            setCurrentPage(0)
                            setSearchTerm(e.target.value)
                          }}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}
                    <TableContainer>
                      <Table >
                        <Thead>
                          <Tr>
                            <Th>Code</Th>
                            <Th >Utilisateur</Th>
                            <Th>Date</Th>
                            <Th>Etat</Th>
                            <Th>Categorie</Th>
                            <Th>Urgence</Th>
                            <Th>Image</Th>
                            <Th>Contenu</Th>
                            <Th>Reponse</Th>
                            <Th>Historique</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {loadingData &&
                            <Tr>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>

                            </Tr>}
                          {(paginationDataAd?.length !== 0) &&
                            adminData?.map((row) => (
                              <Tr key={row.id}>
                                <Td>{row.code}</Td>
                                <Td>{row.user_firstName} {row.user_lastName}</Td>

                                <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>
                                <Td>
                                  <Flex>
                                    <Stack direction='row' mr="3px">
                                      <Switch
                                        colorScheme='green'
                                        isChecked={row.etat}
                                        onChange={() => handleEtatClick(row.id, row.etat)}
                                      />
                                    </Stack>
                                    <Badge
                                      rounded={'lg'}
                                      colorScheme={!row.etat ? 'red' : 'green'}
                                    >
                                      {row.etat ? 'reglée' : 'encours'}
                                    </Badge>
                                  </Flex>
                                </Td>

                                <Td>{row.category === "technical_support" ? "Support technique" :
                                  row.category === "account_assistance" ? "Assistance pour le compte" :
                                    row.category === "course_inquiries" ? "Demandes de renseignements sur les cours" :
                                      row.category === "feedback_suggestions" ? "Commentaires et suggestions" :
                                        row.category === "content_issues" ? "Problèmes de contenu" :
                                          row.category === "general_inquiries" ? "Demandes générales" : ""}</Td>
                                <Td>
                                  {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>élevée</Badge> :
                                    row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>moyenne</Badge> :
                                      row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>faible</Badge> : ""}
                                </Td>
                                <Td>
                                  {row.image &&
                                    <Image
                                      onClick={() => {
                                        setImg(row.image)
                                        onOpenImg();
                                      }}
                                      cursor='pointer'
                                      objectFit='cover'
                                      boxSize={10}
                                      src={row.image}
                                    />}
                                </Td>
                                <Td p={4}><Button
                                  size="sm"
                                  onClick={() => {
                                    setDeleteId(row.id);
                                    setSujet(row.sujet);
                                    setContent(row.content);
                                    onOpen();
                                  }}
                                  colorScheme='blue'
                                  leftIcon={<BiDetail />}>
                                  Afficher
                                </Button></Td>
                                <Td p={4}>
                                  <Button
                                    size="sm"
                                    //onClick={onOpen}

                                    colorScheme='green'
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      setReponse(row.response);
                                      setReponseUser(row.responseUser)
                                      onOpenAlert();
                                      setResUserByWho(row.responseUserbywho)
                                      setUserTime(row.responseUsertime)

                                      setResByWho(row.responsebywho)
                                      setTime(row.responsetime)
                                    }}
                                    leftIcon={<MdOutlineQuestionAnswer />}>
                                    Repondre
                                  </Button></Td>
                                <Td p={4}><Popover placement="left" >
                                  <PopoverTrigger>
                                    <Button
                                      size="sm"
                                      mr="5px"
                                      bg="gray.300"
                                      color={"black"}
                                      leftIcon={<MdHistory />}>
                                      Historique
                                    </Button>

                                  </PopoverTrigger>
                                  <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                      Historique
                                    </PopoverHeader>
                                    <PopoverArrow bg="blue.200" />
                                    <PopoverCloseButton />
                                    <PopoverBody>

                                      <div style={{ marginBottom: '8px' }}>
                                        Modifié par : {row.edited_by}
                                      </div>

                                      <div>
                                        Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                      </div>
                                    </PopoverBody>
                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                    </PopoverHeader>
                                  </PopoverContent>
                                </Popover></Td>
                                {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Supprimer
                                    </Button></Td> */}
                              </Tr>
                            ))}


                          {/* when there is no search data found */}
                          {(((searchTerm || selectedCategory || selectedEtat !== null || selectedUrgence) && paginationDataAd?.length === 0) || (checkLengthDataAdm)) && (
                            <Tr>
                              <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          paginationData={paginationDataAd}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          PAGE_SIZE={pageSize}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>}
            {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&

              <TabPanel>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex w={"100%"}>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedCategory(e.target.value)
                        }}>
                          <option value="">Toutes les catégories</option>
                          <option value="technical_support">Support technique</option>
                          <option value="account_assistance">Assistance pour le compte</option>
                          <option value="course_inquiries">Demandes de renseignements sur les cours</option>
                          <option value="feedback_suggestions">Commentaires et suggestions</option>
                          <option value="content_issues">Problèmes de contenu</option>
                          <option value="general_inquiries">Demandes générales</option>
                        </Select>


                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')
                        }}>
                          <option value="">Tout</option>
                          <option value="true">Reglée</option>
                          <option value="false">En cours</option>
                        </Select>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedUrgence(e.target.value)
                        }}>
                          <option value="">Tout</option>
                          <option value="élevée">élevée</option>
                          <option value="moyenne">moyenne</option>
                          <option value="faible">faible</option>
                        </Select>
                      </Flex>

                      <InputGroup w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          placeholder="Recherche..."
                          value={searchTerm}
                          onChange={(e) => {
                            setCurrentPage(0)
                            setSearchTerm(e.target.value)
                          }}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}
                    <TableContainer>
                      <Table >
                        <Thead>
                          <Tr>
                            <Th>Code</Th>
                            <Th>Utilisateur</Th>
                            <Th>Date</Th>
                            <Th>Etat</Th>
                            <Th>Categorie</Th>
                            <Th>Urgence</Th>
                            <Th>Image</Th>
                            <Th>Contenu</Th>
                            <Th>Reponse</Th>
                            <Th>Historique</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {loadingData &&
                            <Tr>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>

                            </Tr>}
                          {(paginationDataRes?.length !== 0) &&
                            respoData?.map((row) => (
                              <Tr key={row.id}>
                                <Td>{row.code}</Td>
                                <Td>{row.user_firstName} {row.user_lastName}</Td>

                                <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>
                                <Td>
                                  <Flex>
                                    <Stack direction='row' mr="3px">
                                      <Switch
                                        colorScheme='green'
                                        isChecked={row.etat}
                                        onChange={() => handleEtatClick(row.id, row.etat)}
                                      />
                                    </Stack>
                                    <Badge
                                      rounded={'lg'}
                                      colorScheme={!row.etat ? 'red' : 'green'}
                                    >
                                      {row.etat ? 'reglée' : 'encours'}
                                    </Badge>
                                  </Flex>
                                </Td>
                                <Td>{row.category === "technical_support" ? "Support technique" :
                                  row.category === "account_assistance" ? "Assistance pour le compte" :
                                    row.category === "course_inquiries" ? "Demandes de renseignements sur les cours" :
                                      row.category === "feedback_suggestions" ? "Commentaires et suggestions" :
                                        row.category === "content_issues" ? "Problèmes de contenu" :
                                          row.category === "general_inquiries" ? "Demandes générales" : ""}</Td>
                                <Td>
                                  {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>élevée</Badge> :
                                    row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>moyenne</Badge> :
                                      row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>faible</Badge> : ""}
                                </Td>
                                <Td>
                                  {row.image &&
                                    <Image
                                      onClick={() => {
                                        setImg(row.image)
                                        onOpenImg();
                                      }}
                                      cursor='pointer'
                                      objectFit='cover'
                                      boxSize={10}
                                      src={row.image}
                                    />}
                                </Td>
                                <Td p={4}><Button
                                  size="sm"
                                  onClick={() => {
                                    setDeleteId(row.id);
                                    setSujet(row.sujet);
                                    setContent(row.content);
                                    onOpen();
                                  }}
                                  colorScheme='blue'
                                  leftIcon={<BiDetail />}>
                                  Afficher
                                </Button></Td>
                                <Td p={4}>
                                  <Button
                                    size="sm"
                                    //onClick={onOpen}

                                    colorScheme='green'
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      setReponse(row.response);
                                      setReponseUser(row.responseUser)
                                      onOpenAlert();
                                      setResUserByWho(row.responseUserbywho)
                                      setUserTime(row.responseUsertime)

                                      setResByWho(row.responsebywho)
                                      setTime(row.responsetime)
                                    }}
                                    leftIcon={<MdOutlineQuestionAnswer />}>
                                    Repondre
                                  </Button></Td>
                                <Td p={4}><Popover placement="left" >
                                  <PopoverTrigger>
                                    <Button
                                      size="sm"
                                      mr="5px"
                                      bg="gray.300"
                                      color={"black"}
                                      leftIcon={<MdHistory />}>
                                      Historique
                                    </Button>

                                  </PopoverTrigger>
                                  <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                      Historique
                                    </PopoverHeader>
                                    <PopoverArrow bg="blue.200" />
                                    <PopoverCloseButton />
                                    <PopoverBody>

                                      <div style={{ marginBottom: '8px' }}>
                                        Modifié par : {row.edited_by}
                                      </div>

                                      <div>
                                        Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                      </div>
                                    </PopoverBody>
                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                    </PopoverHeader>
                                  </PopoverContent>
                                </Popover></Td>
                                {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Supprimer
                                    </Button></Td> */}
                              </Tr>
                            ))}
                          {/* when there is no search data found */}
                          {(((searchTerm || selectedCategory || selectedEtat !== null || selectedUrgence) && paginationDataRes?.length === 0) || (checkLengthDataRes)) && (
                            <Tr>
                              <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          paginationData={paginationDataRes}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          PAGE_SIZE={pageSize}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>}
            {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN" || user.role === "RESPO") &&

              <TabPanel>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                    >
                      <Flex w={"100%"}>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedCategory} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedCategory(e.target.value)
                        }}>
                          <option value="">Toutes les catégories</option>
                          <option value="technical_support">Support technique</option>
                          <option value="account_assistance">Assistance pour le compte</option>
                          <option value="course_inquiries">Demandes de renseignements sur les cours</option>
                          <option value="feedback_suggestions">Commentaires et suggestions</option>
                          <option value="content_issues">Problèmes de contenu</option>
                          <option value="general_inquiries">Demandes générales</option>
                        </Select>

                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} mb={4} value={selectedEtat} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')
                        }}>
                          <option value="">Tout</option>
                          <option value="true">Reglée</option>
                          <option value="false">En cours</option>
                        </Select>
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} ml={5} mb={4} value={selectedUrgence} onChange={(e) => {
                          setCurrentPage(0)
                          setSelectedUrgence(e.target.value)
                        }}>
                          <option value="">Tout</option>
                          <option value="élevée">élevée</option>
                          <option value="moyenne">moyenne</option>
                          <option value="faible">faible</option>
                        </Select>
                      </Flex>

                      <InputGroup w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          placeholder="Recherche..."
                          value={searchTerm}
                          onChange={(e) => {
                            setCurrentPage(0)
                            setSearchTerm(e.target.value)
                          }}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}

                    <TableContainer>
                      <Table >
                        <Thead>
                          <Tr>
                            <Th>Code</Th>
                            <Th>Utilisateur</Th>
                            <Th>Date</Th>
                            <Th>Etat</Th>
                            <Th>Categorie</Th>
                            <Th>Urgence</Th>
                            <Th>Image</Th>
                            <Th>Contenu</Th>

                            <Th>Reponse</Th>
                            <Th>Historique</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {loadingData &&
                            <Tr>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>

                            </Tr>}
                          {(paginationDataSt?.length !== 0) &&
                            studentData?.map((row) => (
                              <Tr key={row.id}>
                                <Td>{row.code}</Td>
                                <Td>{row.user_firstName} {row.user_lastName}</Td>


                                <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>
                                <Td>
                                  <Flex>
                                    <Stack direction='row' mr="3px">
                                      <Switch
                                        colorScheme='green'
                                        isChecked={row.etat}
                                        onChange={() => handleEtatClick(row.id, row.etat)}
                                      />
                                    </Stack>
                                    <Badge
                                      rounded={'lg'}
                                      colorScheme={!row.etat ? 'red' : 'green'}
                                    >
                                      {row.etat ? 'reglée' : 'encours'}
                                    </Badge>
                                  </Flex>
                                </Td>
                                <Td>{row.category === "technical_support" ? "Support technique" :
                                  row.category === "account_assistance" ? "Assistance pour le compte" :
                                    row.category === "course_inquiries" ? "Demandes de renseignements sur les cours" :
                                      row.category === "feedback_suggestions" ? "Commentaires et suggestions" :
                                        row.category === "content_issues" ? "Problèmes de contenu" :
                                          row.category === "general_inquiries" ? "Demandes générales" : ""}</Td>
                                <Td>
                                  {row.urgence === "élevée" ? <Badge px={2} rounded={"lg"} colorScheme='red'>élevée</Badge> :
                                    row.urgence === "moyenne" ? <Badge px={2} rounded={"lg"} colorScheme='orange'>moyenne</Badge> :
                                      row.urgence === "faible" ? <Badge px={2} rounded={"lg"} colorScheme='green'>faible</Badge> : ""}
                                </Td>
                                <Td>
                                  {row.image &&
                                    <Image
                                      onClick={() => {
                                        setImg(row.image)
                                        onOpenImg();
                                      }}
                                      cursor='pointer'
                                      objectFit='cover'
                                      boxSize={10}
                                      src={row.image}
                                    />}
                                </Td>
                                <Td p={4}>

                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      setSujet(row.sujet);
                                      setContent(row.content);
                                      onOpen();
                                    }}
                                    colorScheme='blue'
                                    leftIcon={<BiDetail />}>
                                    Afficher
                                  </Button></Td>

                                <Td p={4}>
                                  <Button
                                    size="sm"
                                    //onClick={onOpen}

                                    colorScheme='green'
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      setReponse(row.response);
                                      setReponseUser(row.responseUser)
                                      onOpenAlert();
                                      setResUserByWho(row.responseUserbywho)
                                      setUserTime(row.responseUsertime)

                                      setResByWho(row.responsebywho)
                                      setTime(row.responsetime)
                                    }}
                                    leftIcon={<MdOutlineQuestionAnswer />}>
                                    Repondre
                                  </Button></Td>

                                <Td p={4}><Popover placement="left" >
                                  <PopoverTrigger>
                                    <Button
                                      size="sm"
                                      mr="5px"
                                      bg="gray.300"
                                      color={"black"}
                                      leftIcon={<MdHistory />}>
                                      Historique
                                    </Button>

                                  </PopoverTrigger>
                                  <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                      Historique
                                    </PopoverHeader>
                                    <PopoverArrow bg="blue.200" />
                                    <PopoverCloseButton />
                                    <PopoverBody>

                                      <div style={{ marginBottom: '8px' }}>
                                        Modifié par : {row.edited_by}
                                      </div>

                                      <div>
                                        Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                      </div>
                                    </PopoverBody>
                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                    </PopoverHeader>
                                  </PopoverContent>
                                </Popover></Td>
                                {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Supprimer
                                    </Button></Td> */}
                              </Tr>
                            ))}

                          {/* when there is no search data found */}
                          {(((searchTerm || selectedCategory || selectedEtat !== null || selectedUrgence) && paginationDataSt?.length === 0) || (checkLengthDataStd)) && (
                            <Tr>
                              <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>

                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                      <Box flex="1">
                        <MyPagination
                          paginationData={paginationDataSt}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          PAGE_SIZE={pageSize}
                        />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>}
          </TabPanels>
        </Tabs>
      </Stack>

      <Modal
        closeOnOverlayClick={plainteData.response ? false : true}
        isOpen={isAlertOpen}
        onClose={() => {
          setMessage('')
          setPlainteData({})
          onCloseAlert()
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réponse</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* <FormControl>
              <Textarea
                maxLength={250}
                borderColor="gray.300"
                defaultValue={reponse}
                _hover={{
                  borderRadius: 'gray.300',
                }}
                h="100px"
                name="response"
                onChange={handleInputChange}
                placeholder="Ecrivez votre réponse"
              />
            </FormControl> */}
            {message &&
              <Alert mb={2} status='error' rounded="md">
                <AlertIcon />
                {message}
              </Alert>
            }

            <FormControl>
              {

                <>
                  {formatDateToCustomFormat(time) > formatDateToCustomFormat(userTime) ?
                    <>
                      {reponseUser ?
                        <Flex direction={"column"} >

                          <Text ml={2} fontSize={"xs"}>{resUserByWho} </Text>
                          <Flex bg="gray.100" color="black" rounded="2xl" padding={2} w="50%" justifyContent="start">{reponseUser}</Flex>
                          <Text w={"50%"} align={"end"} fontSize={10}>{formatDateToCustomFormat(userTime)}</Text>
                        </Flex>

                        : null}
                      {reponse ? <Flex w="100%" justifyContent="end" alignItems="right">

                        <Flex direction={"column"} justifyContent="end" w={"50%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.100" color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{formatDateToCustomFormat(time)}</Text>

                        </Flex>
                      </Flex> : null}
                    </> : <>

                      {reponse ? <Flex w="100%" justifyContent="end" alignItems="right">

                        <Flex direction={"column"} justifyContent="end" w={"50%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.100" color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{formatDateToCustomFormat(time)}</Text>

                        </Flex>
                      </Flex> : null}
                      {reponseUser ?
                        <Flex direction={"column"} >

                          <Text ml={2} fontSize={"xs"}>{resUserByWho} </Text>
                          <Flex bg="gray.100" color="black" rounded="2xl" padding={2} w="50%" justifyContent="start">{reponseUser}</Flex>
                          <Text w={"50%"} align={"end"} fontSize={10}>{formatDateToCustomFormat(userTime)}</Text>
                        </Flex>

                        : null}
                    </>}


                </>
              }
              <Input mt={5} maxLength={250}
                borderColor="gray.300"
                _hover={{
                  borderRadius: 'gray.300',
                }}
                onChange={handleInputChange}
                name="reponse"
                placeholder="Ecrivez votre réponse"></Input>

            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={sendResponse} colorScheme='blue' isDisabled={!plainteData.response}>
              Envoyer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Supprimer le message
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{sujet}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {content}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




      <Modal size="5xl" isOpen={isImgOpen} onClose={onCloseImg}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image
              objectFit='cover'
              boxSize={"100%"}
              src={img}
            />
          </ModalBody>


        </ModalContent>
      </Modal>

    </Box>
  )
}


