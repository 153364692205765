import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    InputGroup,
    InputRightElement,
    Input,
    Text,
    Stack,
    useColorModeValue,
    HStack,
    Avatar,
    VStack,
    AvatarBadge,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    Select as MySelect,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Divider,
    Alert,
    AlertIcon,
    useToast,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuList,
    MenuOptionGroup,
    MenuItemOption
} from '@chakra-ui/react';
import axiosClient from "../../axios-client";
import React, { useState, useEffect, useRef } from 'react';
import { EditIcon, ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TfiReload } from 'react-icons/tfi';
import Select from "react-select";
import countryData from '../../assets/dataJson/countries.json';
import { useStateContext } from '../../context/ContextProvider';
import { BiChevronDown } from 'react-icons/bi';

function EditApprenant() {
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = useStateContext()

    const studentId = location.state.idApprenant
    const { id } = useParams();
    const [apprenant, setApprenant] = useState('');
    const [initialUserData, setInitialUserData] = useState({});
    const [loadingModules, setLoadingModules] = useState(false);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (studentId) {
            axiosClient.get(`/apprenants/${studentId}/`)
                .then(({ data }) => {
                    setApprenant(data)
                    setInitialUserData(data);
                    setSelection(data.company_type);
                    setCompany(data.company)
                    setCompanyext(data.companyext)

                })
        }

    }, [])

    //toast variable
    const toast = useToast()
    //modal variables
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const finalRef = useRef(null)

    //password variables
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const codepRegex = /^(\d{3})$|(\d{6})$|([A-Z]\d{4}[A-Z]{3})$|(\d{4})$|(\d{4})$|(?:FI)*(\d{5})$|(?:AZ)*(\d{4})$|(\d{5})$|(?:BB)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{3}\d?)$|([A-Z]{2}\d{2})$|([A-Z]{2}\d{4})$|(\d{8})$|(\d{6})$|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$|(\d{4})$|(\d{7})$|(\d{6})$|(\d{4})$|(?:CP)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{5})$|(\d{5})$|(?:FI)*(\d{5})$|(\d{5})$|(\d{4})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|(\d{10})$|(\d{3})$|(\d{5})$|(\d{5})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{7})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{4}(\d{4})?)$|(\d{4})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{5})$|(\d{5})$|(\d{6})$|(\d{4})$|(\d{7})$|(97500)$|(\d{9})$|(\d{7})$|(96940)$|(\d{4})$|((97|98)(4|7|8)\d{2})$|(\d{6})$|(\d{6})$|(\d{6})$|(\d{5})$|(\d{5})$|(?:SE)*(\d{5})$|(\d{6})$|(STHL1ZZ)$|(?:SI)*(\d{4})$|(\d{5})$|4789\d$|(\d{5})$|(?:CP)*(\d{4})$|([A-Z]\d{3})$|(TKCA 1ZZ)$|(\d{5})$|(\d{6})$|(\d{6})$|(\d{4})$|(\d{5})$|(\d{5})$|(986\d{2})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i;

    const initialForm = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
    const resetForm = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordStrength('');
        setPasswordColor('gray.500');
        setShowPassword(false);
        setShowPasswords(false);
    };
    const handleCancel = () => {
        setApprenant(initialUserData);
    };
    const handlepwdCancel = () => {
        resetForm();
        onClose();
    };


    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(newPassword)) {
            missingRequirements.push("caractère spécial");
        } else if (!lowercaseRegex.test(newPassword)) {
            missingRequirements.push("minuscule");
        } else if (!uppercaseRegex.test(newPassword)) {
            missingRequirements.push("majuscule");
        } else if (!digitRegex.test(newPassword)) {
            missingRequirements.push("chiffres");
        } else if (!minLengthRegex.test(newPassword)) {
            missingRequirements.push("longueur minimale de 8 caractères");
        }

        if (missingRequirements.length > 0) {
            const missingText = `Vous avez besoin de ${missingRequirements.join(", ")} dans votre mot de passe.`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength('Le mot de passe est correct!');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);


    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('Le mot de passe doit comporter au moins 8 caractères.');
        }

        if (!digitRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un chiffre.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre minuscule.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre majuscule.');
        }
        if (!specialchar.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('Le mot de passe ne doit pas dépasser 20 caractères.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };
    // change password 
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isPasswordValid(newPassword)) {
            return;
        }
        try {
            const response = await axiosClient.put(`/change_password/${apprenant.id}/`, {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
                useradd: user.first_name + ' ' + user.last_name

            });
            if (response.data.success) {
                toast({
                    description: "le mot de passe est modifié avec succes",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                onClose();
                resetForm();

            }
            setMessage(response.data.error);
        } catch (err) {
            setError(err.response.data.error);
        }
    };
    //get the inputs values
    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === "file" ? target.files[0] : target.value;
        setApprenant({ ...apprenant, [name]: value });
    };


    //get the image change
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setApprenant((prevUserData) => ({
                ...prevUserData,
                image: file,
                imageUrl: imageUrl,
            }));
        }
    };

    const [dataCompany, setDataCompany] = useState([]);


    //get the company data
    useEffect(() => {
        axiosClient.get('/company/?page=1&page_size=10000')
            .then((response) => {
                setDataCompany(response.data.results);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    const [groupedOptions, setGroupedOptions] = useState([]);

    const [selection, setSelection] = useState('');
    const [company, setCompany] = useState('');
    const [companyext, setCompanyext] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [listModule, setListModule] = useState()
    const [listFormation, setListFormation] = useState([])
    const handleItemSelect = (selectedValue) => {
        setSelectedItems(prevItems => [...prevItems, selectedValue]);
    };

    const handleItemDeselect = (selectedValue) => {
        setSelectedItems(prevItems => prevItems.filter(item => item !== selectedValue));
    };

    const [idsModule, setIdsModule] = useState()
    const [listAcces, setListAcces] = useState([])
    useEffect(() => {

        const fetchAccesAndModules = async () => {
            try {
                const response = await axiosClient.get(`/acces/apprenant_modules/?idApprenant=${studentId}`);
                const { acces, ids_modules } = response.data;

                // Set the filtered acces records
                setListAcces(acces);

                // Set the module IDs
                setIdsModule(ids_modules);
            } catch (error) {
                console.error('Error fetching acces and modules:', error);
            }
        };

        fetchAccesAndModules();


    }, [])
    useEffect(() => {
    }, [selectedItems]);

    useEffect(() => {

        const fetchFormationsAndModules = async () => {
            setLoadingModules(true)
            try {
                const response = await axiosClient.get('/formations_with_modules/');
                const formations = response.data.formations;

                // Map the API data to grouped options format for React Select
                const formattedOptions = formations.map(formation => ({
                    label: formation.titre, // The formation title as the group label
                    options: formation.modules.filter(module => !idsModule?.includes(module.id) && module.etat).map(module => ({
                        value: module.id,
                        label: module.titre
                    }))
                }));
                setGroupedOptions(formattedOptions);
            } catch (error) {
                console.error('Error fetching formations with modules:', error);
            }
            finally {
                setLoadingModules(false);
            }
        };

        fetchFormationsAndModules();
    }, [idsModule]);
    const handleSelectChange = (selectedOptions) => {
        setSelectedItems(selectedOptions);
        // You can now work with selected modules (selectedOptions)
    };
    //edit form submit
    const handleSubmit = async (event) => {
        setLoading(true)
        if (!emailRegex.test(apprenant.email)) {
            setMessage("Veuillez entrer un email valide.");
            setLoading(false)
            return;
        }
        if (apprenant.code_postal && !codepRegex.test(apprenant.code_postal)) {
            setMessage("Veuillez entrer un code postal valide.");
            setLoading(false)
            return;
        }
        event.preventDefault();

        const formData = new FormData();
        formData.append("first_name", apprenant.first_name);
        formData.append("last_name", apprenant.last_name);
        formData.append("email", apprenant.email);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);
        if (selectedCountry.label) { formData.append("pays", selectedCountry.label); }
        formData.append("ville", apprenant.ville);
        formData.append("rue", apprenant.rue);
        formData.append("code_postal", apprenant.code_postal);
        if (typeof apprenant.image === 'object') {
            formData.append("image", apprenant.image);
        }
        formData.append("company_type", selection)

        if (selection === "interne") {
            formData.append('company', company)
            formData.append('companyext', "")
        }

        if (selection === "externe") {
            formData.append('companyext', companyext)
            formData.append('company', null)
        }


        if (apprenant.first_name.trim() === "" || apprenant.last_name.trim() === "" || (apprenant.ville.length !== 0 && apprenant.ville.trim() === "") || (apprenant.code_postal.length !== 0 && apprenant.code_postal.trim() === "") || (apprenant.rue.length !== 0 && apprenant.rue.trim() === "") || (selection === "externe" && companyext.trim() === "")) {
            window.scrollTo(0, 0);
            setMessage("Veuillez remplir les champs");
            setLoading(false)
            return;
        }

        const selectedItemsValues = selectedItems.map(item => item.value);
        formData.append("selectedItems", JSON.stringify(selectedItemsValues));

        // Send the updated user data to the API

        try {
            const response = await axiosClient.put(`/update_apprenant/${apprenant.id}/`, formData);
            if (response.data.message) {
                toast({
                    description: "l'apprenant' est modifié avec succes",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                onClose();
                navigate(`/apprenants`)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            toast({
                description: "une erreur s'est produite",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            })
        }


    };
    const isEmpty = () => {
        return !apprenant.last_name || !apprenant.first_name || !apprenant.email || (selection === "interne" && !company) || (selection === "externe" && !companyext);
    };
    const generatePassword = () => {
        const length = 10; // Length of the generated password
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '[@#$%^&+=!*_|èàç()/.";:,?ù]';

        let newPassword = '';
        let characterSet = '';

        // Include at least one character from each set
        newPassword += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
        newPassword += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];
        newPassword += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        characterSet = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

        // Generate remaining characters randomly
        for (let i = newPassword.length; i < length; i++) {
            newPassword += characterSet[Math.floor(Math.random() * characterSet.length)];
        }

        setNewPassword(newPassword);
        setConfirmPassword(newPassword)
    };

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    useEffect(() => {
        const Mycountries = [];
        const data = countryData;
        for (let index = 0; index < data.countries.length; index++) {
            Mycountries.push({
                value: data.countries[index].value,
                label: data.countries[index].label.substring(5, 50)
            });
        }
        setCountries(Mycountries);
    }, []);

    const [getCountry, setGetCountry] = useState([
        {
            value: '',
            labale: ''
        }
    ])

    function pays(p) {
        let t
        t = {
            value: 1,
            label: p
        }

        return t
    }


    const colorbg = useColorModeValue('white', '#2d3748')
    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    function isModuleChecked() {
        let check = []
        {
            listFormation.filter(v => v.etat !== false).map((formation, index) => {

                { listModule?.filter(val => val.formation === formation.id && val.etat).length > 0 && check.push(formation.id) }

            })
        }
        return check
    }


    return (

        <Box mt="5px" >
            <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: '2xl', sm: '3xl' }}>
                Modifier apprenant
            </Heading>
            <Flex
                align={'center'}
                justify={'center'}

            >

                <Stack

                    w={{ base: '90%', md: '90%', lg: '80%' }}
                    maxW='full'

                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={{ base: 4, md: 6, lg: 8 }}
                    my={{ base: 4, md: 8 }}>

                    <Stack direction={{ base: 'column', xl: 'row' }} spacing={{ base: 4, xl: 8 }} p={{ base: 4, md: 6, lg: 8 }}>
                        <Box flex={{ base: '0 0 auto', xl: 'auto 0 0' }} w={{ base: '100%', xl: '30%' }} >
                            <Flex justifyContent="center" alignItems="center" h="100%" direction={'column'}>
                                <FormControl id="img" mb={{ base: '5', md: '5', xl: '10' }} >
                                    <Center>
                                        <label htmlFor="imageInput">
                                            <input
                                                id="imageInput"
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange}
                                            />
                                            <Avatar boxSize={['150px', '150px', '150px', '200px', '200px', '250px']} maxBlockSize={250} src={apprenant.imageUrl || apprenant.image}>
                                                <AvatarBadge
                                                    boxSize={{ base: '2em', sm: '2em', md: '3em' }}
                                                    rounded="full"
                                                    bg="#ffd140"
                                                    _hover={{
                                                        bg: '#ffc50f',
                                                    }}
                                                    position="absolute"
                                                    top="20%"
                                                    right={{ base: '-10px', sm: '-12px', md: '-16px' }}
                                                    transform="translateY(-20%)"
                                                    aria-label="remove Image"
                                                    icon={<EditIcon />}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <EditIcon />
                                                </AvatarBadge>
                                            </Avatar>

                                        </label>
                                    </Center>
                                </FormControl>
                                {/* <Stack mb={{ base: '0', md: '5', lg: '10' }} w="100%" direction={['column', 'row']} justify={'center'} align={'center'}>

                                    <Flex align={'center'} w={{ base: '100%', md: '100%' }}>
                                        <Button
                                            onClick={onOpen}

                                            w="100%"
                                            colorScheme="green"

                                        >
                                            modifier le mot de passe
                                        </Button>
                                    </Flex>
                                </Stack> */}
                            </Flex>
                        </Box>
                        <Center display={{ base: 'none', xl: 'flex' }} height={{ base: 0, xl: '500px' }}>
                            <Divider orientation='vertical' />
                        </Center>
                        <Box flex={{ base: '0 0 auto', xl: '1 0 0' }} w={{ base: '100%', xl: '70%' }} >
                            <VStack align={{ base: 'stretch', xl: 'start' }} spacing={4} p={2} >
                                {message &&
                                    <Alert status='error' rounded="md">
                                        <AlertIcon />
                                        {message}
                                    </Alert>
                                }
                                <Flex flexDir={{ base: 'column', md: 'row' }} gap={3} w="100%">
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="last_name" >
                                            <FormLabel>Nom</FormLabel>
                                            <Input
                                                placeholder='Entrez votre nom'
                                                maxLength={25}
                                                _placeholder={{ color: 'gray.500' }}
                                                type="text"
                                                id="last_name"
                                                name="last_name"
                                                value={apprenant.last_name}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="first_name" >
                                            <FormLabel>Prénom</FormLabel>
                                            <Input
                                                placeholder='Entrez votre prénom'
                                                maxLength={25}
                                                id="first_name"
                                                name="first_name"
                                                _placeholder={{ color: 'gray.500' }}
                                                type="text"
                                                value={apprenant.first_name}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </Box>
                                </Flex>
                                <FormControl id="email" >
                                    <FormLabel>Address Email</FormLabel>
                                    <Input
                                        disabled
                                        maxLength={50}
                                        id="email"
                                        name="email"
                                        _placeholder={{ color: 'gray.500' }}
                                        type="email"
                                        value={apprenant.email}

                                    />
                                </FormControl>
                                <Flex flexDir={{ base: 'column', md: 'row' }} gap={3} w="100%">                                    <Box w={{ base: '100%', md: '50%' }}>
                                    <FormControl id="rue" >
                                        <FormLabel>Rue</FormLabel>
                                        <Input
                                            placeholder='Entrez la rue'
                                            maxLength={50}
                                            _placeholder={{ color: 'gray.500' }}
                                            type="text"
                                            id="rue"
                                            name="rue"
                                            value={apprenant.rue}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Box>

                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="ville" >
                                            <FormLabel>Ville</FormLabel>
                                            <Input
                                                placeholder='Entrez la ville'
                                                maxLength={30}
                                                id="ville"
                                                name="ville"
                                                _placeholder={{ color: 'gray.500' }}
                                                type="text"
                                                value={apprenant.ville}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </Box>
                                </Flex>
                                <Flex flexDir={{ base: 'column', md: 'row' }} gap={3} w="100%">
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="code_postal" >
                                            <FormLabel>Code postale</FormLabel>
                                            <Input
                                                placeholder='Entrez le code postal'
                                                maxLength={15}
                                                id="code_postal"
                                                name="code_postal"
                                                _placeholder={{ color: 'gray.500' }}
                                                type="text"
                                                value={apprenant.code_postal}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="pays" >
                                            <FormLabel>Pays</FormLabel>
                                            <Select
                                                placeholder='Sélectionnez le pays'
                                                menuPlacement="top"
                                                id="pays"
                                                name="pays"
                                                options={countries}
                                                value={selectedCountry.label ? selectedCountry : pays(apprenant.pays)}
                                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                                                isSearchable
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        color: txtcolor
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        cursor: "pointer"
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: txtcolor,
                                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: colorbghover,
                                                        },
                                                        cursor: "pointer"
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: txtcolor
                                                    }),
                                                }}
                                            />
                                        </FormControl>
                                    </Box>

                                </Flex>


                                <Flex flexDir={{ base: 'column', md: 'row' }} gap={3} w="100%">
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl  >
                                            <FormLabel ml={0.5}>Type de société </FormLabel>
                                            <MySelect value={selection} onChange={(e) => setSelection(e.target.value)} >
                                                <option value="interne">Interne</option>
                                                <option value="externe">Externe</option>
                                            </MySelect>
                                        </FormControl>
                                    </Box>
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        {selection === 'interne' && (
                                            <FormControl  >
                                                <FormLabel ml={0.5} >Société </FormLabel>
                                                <MySelect defaultValue={apprenant.company} onChange={(e) => setCompany(e.target.value)}>
                                                    <option value="">Sélectionnez une société</option>
                                                    {dataCompany.map((company, key) => (
                                                        <option key={key} value={company.id}>
                                                            {company.name}
                                                        </option>
                                                    ))}
                                                </MySelect>
                                            </FormControl>
                                        )}

                                        {selection === 'externe' && (
                                            <FormControl id="company"  >
                                                <FormLabel ml={0.5}>Entrez le nom de votre société</FormLabel>
                                                <Input
                                                    defaultValue={apprenant.companyext}
                                                    name='companyext'
                                                    onChange={e => setCompanyext(e.target.value)}
                                                    placeholder="Tapez le nom de votre société"
                                                />
                                            </FormControl>
                                        )}
                                    </Box>
                                </Flex>
                                <Box display={'flex'} justify="left" align="left" w={{ base: '100%', md: "100%" }}>
                                    <FormControl id="access" isInvalid={message}>
                                        <FormLabel ml={0.5}>Acces</FormLabel>

                                        <Select

                                            isLoading={loadingModules}
                                            isMulti
                                            options={groupedOptions}
                                            onChange={handleSelectChange}
                                            value={selectedItems}
                                            menuPlacement="top"
                                            placeholder="Sélectionnez les modules"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    color: txtcolor
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                </Box>

                            </VStack>
                            <Stack mt={5} spacing={2} direction={['column', 'row']} justifyContent="end" >
                                <Button
                                    colorScheme="red"
                                    isDisabled={loading}
                                    onClick={() => navigate(-1)}
                                >
                                    Annuler
                                </Button>
                                {isEmpty() ? (
                                    <Button
                                        type='submit'
                                        isDisabled
                                        onClick={handleSubmit}
                                        colorScheme="blue">
                                        Enregistrer
                                    </Button>
                                ) : (
                                    <Button
                                        isLoading={loading}
                                        type='submit'
                                        onClick={handleSubmit}
                                        colorScheme="blue">
                                        Enregistrer
                                    </Button>
                                )
                                }



                            </Stack>
                        </Box>
                    </Stack>


                </Stack>
                <>


                    <Modal
                        closeOnOverlayClick={false}
                        initialFocusRef={initialRef}
                        finalFocusRef={finalRef}
                        isOpen={isOpen}
                        onClose={onClose}
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Modifier le mot de passe</ModalHeader>
                            <ModalCloseButton />
                            <hr />
                            {message &&
                                <Alert status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <ModalBody pb={6}>
                                <FormControl>
                                    <Text fontSize="sm" color='yellow.600'>Le mot de passe doit comporter au moins 8 caractères et contenir au moins un chiffre, une lettre minuscule et une lettre majuscule.</Text>

                                    <FormLabel>Ancien mot de passe</FormLabel>
                                    <InputGroup>
                                        <Input

                                            type={showPasswords ? 'text' : 'password'}
                                            id="old_password"
                                            onChange={(e) => setOldPassword(e.target.value)}

                                        />
                                        <InputRightElement h={'full'}>
                                            <Button
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPasswords((showPasswords) => !showPasswords)
                                                }>
                                                {showPasswords ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>

                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Nouveau mot de passe</FormLabel>
                                    <InputGroup>

                                        <InputLeftElement onClick={generatePassword} as={'button'} >
                                            <TfiReload color='gray.300' />
                                        </InputLeftElement>
                                        <Input

                                            type={showPassword ? 'text' : 'password'}
                                            id="new_password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            onCopy={(e) => e.preventDefault()}

                                        />

                                        <InputRightElement h={'full'}>
                                            <Button
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>

                                </FormControl>
                                {newPassword.length > 0 ?
                                    <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                    : ""}

                                <FormControl mt={4}>
                                    <FormLabel>Confirmer mot de passe</FormLabel>
                                    <Input
                                        type="password"
                                        id="confirm_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>

                                <Button onClick={handleFormSubmit} type="submit" colorScheme='blue' mr={3}>
                                    Enregistrer
                                </Button>
                                <Button onClick={handlepwdCancel}>Annuler</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            </Flex>
        </Box>
    )
}

export default EditApprenant
