import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdSkipNext, MdSkipPrevious } from 'react-icons/md';



function MyPagination({ paginationData, PAGE_SIZE, currentPage, setCurrentPage }) {
  const count = paginationData?.count
  const next = paginationData?.next
  const previous = paginationData?.previous
  const results = paginationData?.results


  const pageCount = Math.ceil(count / PAGE_SIZE);
  const [widthPage, setWitdhPage] = useState(window.innerWidth);

  const MAX_VISIBLE_PAGES = widthPage >= 500 ? 5 : 3;

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
    // onPageChange(newPage);
  };
  useEffect(() => {
    function handleResize() {
      setWitdhPage(window.innerWidth)
    }

    window.addEventListener("resize", handleResize);
  }, [])

  const renderPageButtons = () => {
    const pageButtons = [];

    // Calculate the range of visible page buttons.
    let startPage = Math.max(0, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
    let endPage = Math.min(pageCount - 1, startPage + MAX_VISIBLE_PAGES - 1);

    // Adjust the startPage if the endPage is at the end.
    if (endPage === pageCount - 1) {
      startPage = Math.max(0, endPage - MAX_VISIBLE_PAGES + 1);
    }

    if (startPage > 0 && widthPage < 500) {
      pageButtons.push(
        <IconButton
          key="start"
          size="xs"
          rounded="full"
          colorScheme="transparent"
          onClick={() => handlePageClick(0)}
          className="pagination-button"
          isDisabled={currentPage === 0}
          icon={<MdSkipPrevious color='gray' size={20} />}
        >
        </IconButton>
      );

    }
    // Add "Précédent" button and disable it if there is only one page.  
    pageButtons.push(
      <IconButton
        key="prev"
        size="xs"
        rounded="full"
        colorScheme="transparent"
        onClick={() => handlePageClick(currentPage - 1)}
        className="pagination-button"
        isDisabled={currentPage === 0}
        icon={<MdKeyboardDoubleArrowLeft color='gray' size={20} />}
      >
        {/* {t("Précédent")} */}
      </IconButton>
    );

    if (startPage > 0 && widthPage >= 500) {
      pageButtons.push(
        <Fragment key="dots-start">

          <Button

            size="xs"
            rounded="full"
            _hover={'none'}
            color={'white'}
            backgroundColor={0 === currentPage ? "#49b1cc" : "gray"}
            className="pagination-button"
            onClick={() => handlePageClick(0)}
          >
            1
          </Button>
          <Text key="dots-text" fontSize={'lg'} fontWeight={"semibold"}>...</Text>
        </Fragment>

      );
    }
    // Add page buttons with dots
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <Button
          key={`page-${i}`}
          size="xs"
          rounded="full"
          _hover={'none'}
          color={'white'}
          backgroundColor={i === currentPage ? "#49b1cc" : "gray"} // Highlight the current page.
          onClick={() => handlePageClick(i)}
          className="pagination-button"
        >
          {i + 1}
        </Button>
      );
    }
    if (endPage < pageCount - 1 && widthPage >= 500) {
      pageButtons.push(
        <Fragment key="dots-end">
          <Text fontSize={'lg'} fontWeight={"semibold"}>...</Text>
          <Button
            key={`page-${pageCount - 1}`}
            size="xs"
            rounded="full"
            _hover={'none'}
            color={'white'}
            backgroundColor={pageCount - 1 === currentPage ? "#49b1cc" : "gray"}
            className="pagination-button"
            onClick={() => handlePageClick(pageCount - 1)}
          >
            {pageCount}
          </Button>
        </Fragment>
      );
    }
    // Add "Suivant" button and disable it if there is only one page.
    pageButtons.push(
      <IconButton
        key="next"
        size="xl"
        rounded="full"
        colorScheme="transparent"
        onClick={() => handlePageClick(currentPage + 1)}
        className="pagination-button"
        isDisabled={currentPage === pageCount - 1}
        icon={<MdKeyboardDoubleArrowRight color='gray' size={20} />}
      >
        {/* {t("Suivant")} */}
      </IconButton>
    );
    if (endPage < pageCount - 1 && widthPage < 500) {
      pageButtons.push(
        <>
          <IconButton
            key={"end"}
            size="xs"
            rounded="full"
            _hover={'none'}
            color={'white'}
            colorScheme="transparent"
            className="pagination-button"
            onClick={() => handlePageClick(pageCount - 1)}
            icon={<MdSkipNext color='gray' size={20} />}
          >
          </IconButton>

        </>
      );
    }

    return pageButtons;
  };

  return (
    <Box my="4">
      <HStack justify="center" align="center">
        {renderPageButtons()}
      </HStack>
    </Box>
  );
}

export default MyPagination;
