import { AddIcon, DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Skeleton,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { MdHistory, MdOndemandVideo } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { GrCircleAlert } from "react-icons/gr";

function TableVideos() {

  

  // current user
  const { user, setUser } = useStateContext();

  const location = useLocation();

  const [listVideos, setListVideos] = useState([]);
  const [listVideosEdit, setListVideosEdit] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deletedId, setDeletedId] = useState();

  const cancelRef = React.useRef();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };
  const [search, setSearch] = useState("");

  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)

  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });

  }, [user.id]);

  useEffect(() => {
    axiosClient.get(`/videos/getAllVideos/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&chapitre=${location.state.idChapitre}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);
        
        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setListVideos(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [user, pageSize, currentPage, search])

  function update() {
    setCheckLengthData(false)

    axiosClient.get(`/videos/getAllVideos/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&chapitre=${location.state.idChapitre}`)
    .then((response) => {

      let filteredData = response?.data?.results ? response?.data?.results : [] || [];
      setPaginationData(response.data);

      if (filteredData.length === 0) {
        setCheckLengthData(true)
      }
      else {
        setListVideos(filteredData);
        setCheckLengthData(false)
      }
      setLoading(false)
    })
    .catch((error) => {
      console.error(error);
      setLoading(false)
    });
  }
  const [name, setName] = useState('');
  const [file, setFile] = useState()
  const [oldFileName, setOldFileName] = useState('')

  const [message, setMessage] = useState('')
  const [etatMessage, setEtatMessage] = useState(false)

  // Fonction pour générer un nom de fichier aléatoire désordonné
  const generateRandomFileName = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const fileNameLength = 10; // Longueur du nom de fichier désordonné

    let randomFileName = '';
    for (let i = 0; i < fileNameLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomFileName += characters.charAt(randomIndex);
    }

    const timestamp = Date.now().toString();
    return `${randomFileName}_${timestamp}`;
  };

  const [fileName, setFileName] = useState()

  const handleChangeVideoFile = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)

    // Séparer le nom de fichier et l'extension
    const fileNameParts = file?.name.split(".");
    const fileExtension = fileNameParts?.pop();
    const originalFileNameWithoutExtension = fileNameParts?.join(".");

    // Utiliser la fonction generateRandomFileName pour obtenir un nom aléatoire désordonné
    const newFileNameWithoutExtension = generateRandomFileName();

    // Former le nouveau nom de fichier avec le nouveau nom aléatoire et l'extension d'origine
    const newFileName = `${newFileNameWithoutExtension}.${fileExtension}`;

    // Créer un nouvel objet File avec le nouveau nom
    const renameFile = new File([file], newFileName, { type: file?.type });

    setFile(renameFile);

    setFile(renameFile);
    if (!file) {
      setFile(null)
      return
    }
    // Afficher le nouveau nom de fichier
  };

  const [uploaded, setUploaded] = useState(null)
  const addVideo = () => {

    if (!name || !file || (name.trim() === "")) {
      setMessage("Veuillez remplir les champs")
      setEtatMessage(true)
      return;
    }

    const formData = new FormData()
    formData.append('name', name)
    formData.append('type', "Video")
    formData.append('file', file)
    formData.append("fileName", fileName)
    formData.append('chapitre', location.state.idChapitre)
    formData.append("added_by", user.id)
    formData.append('modification',
      JSON.stringify([{
        edited_by: `${user.first_name} ${user.last_name}`,
        edited_at: edited_at,
        field: {
          name: name,
          file: fileName,
        },
      }])
    )

    if (listVideos.length > 0) {
      const check = listVideos.find(obj => {
        return obj.name.toLowerCase() === name.toLocaleLowerCase().trim()
      })
      if (check) {
        setEtatMessage(true)
        return setMessage('Cette vidéo existe déjà dans ce chapitre !')
      }
    }

    axiosClient.post('/videos/', formData, {
      onUploadProgress: (data) => {
        setUploaded(Math.round((data.loaded / data.total) * 100))
      }
    })
      .then((response) => {
        setListVideos([response.data, ...listVideos])
        setEtatMessage(false)
        setFile("");
        update()
        toast({
          title: 'Ajout',
          description: `La video ${name} est ajouté avec succès`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })

        setUploaded(null)
        onClose()
      })
  }

  const toast = useToast();
  const deleteVideo = (id) => {
    axiosClient.delete(`/videos/${id}/`).then(() => {
      setListVideos((prevData) => prevData.filter((row) => row.id !== id));
      toast({
        title: "Suppression",
        description: `La Vidéo est suppprimée avec succès`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };


  const [changeModal, setChangeModal] = useState(true)

  const openModalUpdate = (id) => {
    setListVideosEdit([]);
    axiosClient
      .get(`/videos/${id}/`)
      .then((res) => setListVideosEdit(res.data));
    onOpen();
  };


  const [modification, setModification] = useState([])

  const date = new Date();
  date.setDate(date.getDate());

  const [edited_by, setedited_by] = useState()
  const [edited_at, setedited_at] = useState(date)

  const [title, setTitle] = useState('')

  const modalUpdate = (id, myModification, title, oldFileName) => {
    if (!name || (name.trim() === "")) {
      setMessage("Veuillez remplir les champs")
      setEtatMessage(true)
      return;
    }

    const mod = myModification
    mod.push({
      edited_by: `${user.first_name} ${user.last_name}`,
      edited_at: edited_at,
      field: {
        name: name !== title ? name : null,
        file: fileName ? fileName : null
      }
    })

    const formData = new FormData()
    formData.append('name', name)
    if (fileName) formData.append("fileName", fileName)
    if (file) {
      formData.append('file', file)
    }
    if (name !== title || (file && fileName !== oldFileName)) {
      formData.append('modification', JSON.stringify(mod))
    }

    const newList = listVideos.filter((e) => e.id !== id)
    if (newList.length > 0) {
      const check = newList.find(obj => {
        return obj.name.toLowerCase() === name.toLocaleLowerCase().trim()
      })
      if (check) {
        setEtatMessage(true)
        return setMessage('Cette vidéo existe déjà dans ce chapitre !')
      }
    }

    axiosClient.patch(`/videos/${deletedId}/`, formData,
      {
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100))
        }
      })
      .then(() => {
        setListVideos((rows) =>
          rows.map((row) => {
            if (row.id === deletedId) {
              return {
                ...row,
                name: name,
                fileName: !fileName ? oldFileName : fileName
              };
            }
            return row;
          })
        );
        setName('')
        setFile("")
        toast({
          description: `la Vidéo est modifiée avec succès`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        setUploaded(null)
        if (file) {
          // window.location.reload();
          onClose()
          update()
        }
        else {
          onClose();
        }
      });


  };





  return (
    <>
      <Flex justifyContent="space-between" mb={3} alignItems="center" direction={{ base: 'column', lg: 'row' }}>
        <InputGroup mt="10px" w={{ base: '100%', lg: "30%" }} mb={{ base: 2 }}>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Recherche..."
            onChange={(e) => {
              setCurrentPage(0)
              setSearch(e.target.value)}}
          />
        </InputGroup>
        <Button
          mt="10px"
          mb={{ base: 5 }}
          w={{ base: '100%', lg: "180px" }}
          colorScheme="blue"
          size="md"
          onClick={() => {
            setChangeModal(true)
            setName('')
            setFile('')
            onOpen()
          }}
          leftIcon={<AddIcon />}
        >
          Ajouter une vidéo
        </Button>
      </Flex>
      <TableContainer>
        <Table size={"sm"}>
          <Thead>
            <Tr>
              <Th>Titre</Th>
              <Th>Type</Th>
              <Th>Fichier</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
          {loading &&
              <Tr>
                <Td> <Skeleton height='20px' /></Td>
                <Td> <Skeleton height='20px' /></Td>
                <Td> <Skeleton height='20px' /></Td>
                <Td> <Skeleton height='20px' /></Td>
                
              </Tr>}
            {(paginationData?.length !== 0) &&
              listVideos.map((val, key) => (
                <Tr key={key}>
                  <Td>{val.name}</Td>
                  <Td>{val.type}</Td>
                  <Td cursor="pointer" title="Voir le contenu">
                    <Tag onClick={() => {
                      navigate('/detail_media', {
                        state: {
                          file: val.file,
                          fileType: val.type
                        }
                      })
                    }}
                      size="lg"
                      colorScheme="whatsapp"
                      borderRadius="full"
                    >
                      <MdOndemandVideo fontSize={25} />
                      <TagLabel ml={2}>{val.fileName}</TagLabel>
                    </Tag>
                  </Td>
                  <Td display="flex" justifyContent="">
                    {(user.role === "SUPERADMIN" || user.role === "MASTER")
                      ?
                      <>
                        <IconButton
                          size="sm"
                          colorScheme="green"
                          mr={5}
                          icon={<EditIcon />}
                          onClick={() => {
                            setChangeModal(false)
                            setDeletedId(val.id);
                            setName(val.name)
                            openModalUpdate(val.id);
                            setTitle(val.name)
                            setModification(val.modification)
                            setOldFileName(val.fileName)
                            setUploaded(null)
                          }}
                        >
                          Modifier
                        </IconButton>
                        <IconButton
                          size="sm"
                          mr={5}
                          bg="gray.300"
                          icon={<MdHistory />}
                          onClick={() =>
                            navigate('/historique_media', {
                              state: {
                                idMedia: val.id,
                                Type: val.type
                              }
                            })
                          }
                        >
                          Historique
                        </IconButton>
                        <IconButton
                          size="sm"
                          colorScheme="red"
                          icon={<DeleteIcon />}
                          onClick={() => {
                            setDeletedId(val.id);
                            onOpenAlert();
                          }}
                        >
                          Supprimer
                        </IconButton>
                      </>
                      :
                      <>
                        <IconButton
                          size="sm"
                          colorScheme="green"
                          mr={5}
                          icon={<EditIcon />}
                          onClick={() => {
                            setChangeModal(false)
                            setDeletedId(val.id);
                            setName(val.name)
                            openModalUpdate(val.id);
                            setTitle(val.name)
                            setModification(val.modification)
                            setOldFileName(val.fileName)
                            setUploaded(null)
                          }}
                        >
                          Modifier
                        </IconButton>

                        <IconButton
                          size="sm"
                          bg="gray.300"
                          icon={<MdHistory />}
                          onClick={() =>
                            navigate('/historique_media', {
                              state: {
                                idMedia: val.id,
                                Type: val.type
                              }
                            })
                          }
                        >
                          Historique
                        </IconButton>
                      </>

                    }

                  </Td>
                </Tr>
              ))}
            {((search && paginationData?.length === 0) || (checkLengthData)) && (

              <Tr>
                <Td colSpan={6}>
                  Aucune ligne correspondante n'a été trouvée.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
        <Box flex="1">
          <MyPagination
            paginationData={paginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            PAGE_SIZE={pageSize}
          />
        </Box>

        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          {/* Add more options as needed */}
        </Select>
      </Flex>
      {/* Alert for delete */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer la Vidéo
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action
              ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  deleteVideo(deletedId);
                  onCloseAlert();
                }}
                colorScheme="red"
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {changeModal ?

        //Modal for Add
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
          onClose()
          setEtatMessage(false)
          setFile("")
        }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajout du video</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {etatMessage &&
                <Alert status='error' rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              }
              <Flex direction="column">
                <FormControl id="name" isRequired>
                  <FormLabel>Titre</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <Input
                      maxLength={50}
                      type="text"
                      size="lg"
                      name="name"
                      placeholder="Entrer le titre de la vidéo"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                  <Flex justify="end">
                    {name.length > 40
                      ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                      : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                    }
                  </Flex>
                </FormControl>
                <FormControl mt="3" id="file" isRequired>
                  <FormLabel>Fichier</FormLabel>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    height="100px"
                    border="1px dashed"
                    position="relative"
                    borderColor="#2b6cb0"
                  >
                    <BsFillCloudArrowUpFill size="70px" color="#2b6cb0" />
                    <Text fontSize="lg" fontWeight="semibold" color="#2b6cb0">
                      Parcourir le fichier à télécharger
                    </Text>
                  </Flex>
                  <InputGroup borderColor="#E0E1E7" position="absolute" top="8">
                    <Input
                      opacity="0"
                      cursor="pointer"
                      type="file"
                      height="100px"
                      size="lg"
                      name="file"
                      accept="video/mp4, video/webm"
                      onChange={handleChangeVideoFile}
                    />
                  </InputGroup>
                  <Text color="yellow.500">les extentions autorisées : MP4,WEBM *</Text>
                </FormControl>

                {file && (
                  <>
                    {fileName?.length - 5 > 95
                      ?
                      <Tag
                        mt="10px"
                        size='lg'
                        padding={2}
                        colorScheme='red'
                        borderRadius='full'>
                        <GrCircleAlert size="30px" />
                        <TagLabel ml={3}>
                          Le nom du fichier dépasse 90 caractère !
                        </TagLabel>
                      </Tag>
                      :
                      <Tag
                        mt="10px"
                        size="xl"
                        variant="outline"
                        px="2px"
                        py="5px"
                        position="relative"
                        colorScheme="linkedin"
                      >
                        <AiFillFileAdd size="40px" />
                        <Flex direction="column" justifyContent="center">
                          <Text>
                            {fileName?.length > 40
                              ? `${fileName.substring(0, 35)}...`
                              : `${fileName}`}
                          </Text>
                          <Text fontSize="xs">
                            {Math.floor(file.size) / (1024) < 1024
                              ? `${Math.floor(file.size / (1024))} Ko`
                              : Math.floor(file.size) / (1024 * 1024) < 1024
                                ? `${Math.floor(file.size / (1024 * 1024))} Mb`
                                : `${Math.floor(file.size / (1024 * 1024 * 1024))} GB`}
                            { }
                            {/* {Math.floor(file.size) / (1024 * 1024) > 1024 && `${Math.floor(file.size / (1024 * 1024 * 1024))} GB`} */}

                            {/* {Math.floor(file.size / (1024 * 1024)) < 1024
                              ? `${Math.floor(file.size / (1024 * 1024))} MB`
                              : `${Math.floor(file.size / (1024 * 1024 * 1024))} GB`} */}

                            {uploaded === 0 ? null : <Progress mt="1" colorScheme="linkedin" w="28em" rounded="lg" h="5px" size="xs" hasStripe value={uploaded} />}
                          </Text>
                        </Flex>
                      </Tag>}
                  </>
                )}

              </Flex>
            </ModalBody>

            <ModalFooter>

              {(!name || !file || file.name?.length > 100)
                ? <Button colorScheme="blue" title="Veuillez remplir tout les champs" isDisabled>
                  Ajouter
                </Button>
                : uploaded ?
                  <Button
                    isLoading
                    loadingText='En cours' colorScheme="blue" onClick={addVideo}>
                    Ajouter
                  </Button>
                  : <Button colorScheme="blue" onClick={addVideo}>
                    Ajouter
                  </Button>}
            </ModalFooter>
          </ModalContent>
        </Modal> :

        //Modal for update
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
          onClose()
          setEtatMessage(false)
          setFile("")
          setName('')
        }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modification du video</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {etatMessage &&
                <Alert status='error' rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              }
              {listVideos.map((val, key) => {
                if (val.id === deletedId) {
                  return (
                    <Flex direction="column" key={key}>
                      <FormControl id="name" isRequired>
                        <FormLabel>Titre</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <Input
                            maxLength={50}
                            type="text"
                            size="lg"
                            name="name"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </InputGroup>
                        <Flex justify="end">
                          {name.length > 40
                            ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                            : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                          }
                        </Flex>
                      </FormControl>
                      <FormControl mt="3" id="file" isRequired>
                        <FormLabel>Fichier</FormLabel>
                        <Tag
                          mb="10px"
                          size="xl"
                          w="100%"
                          variant="outline"
                          px="2px"
                          py="5px"
                          position="relative"
                          colorScheme="yellow"
                        >
                          <AiFillFileAdd size="40px" />
                          <Flex direction="column" justifyContent="center">
                            <Text>
                              {val.fileName?.length > 30
                                ? `${val.fileName.substring(
                                  0,
                                  35
                                )}...`
                                : `${val.fileName}`}
                            </Text>
                          </Flex>
                        </Tag>
                        <Flex
                          position="relative"
                          alignItems="center"
                          justifyContent="center"
                          direction="column"
                          height="100px"
                          border="1px dashed"
                          borderColor="#2b6cb0"
                        >
                          <BsFillCloudArrowUpFill size="70px" color="#2b6cb0" />
                          <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            color="#2b6cb0"
                          >
                            Parcourir le fichier à télécharger
                          </Text>
                          <InputGroup
                            borderColor="#E0E1E7"
                            position="absolute"
                            top="0"
                          >
                            <Input
                              opacity="0"
                              cursor="pointer"
                              type="file"
                              height="100px"
                              size="lg"
                              name="file"
                              accept="video/mp4, video/webm"
                              onChange={handleChangeVideoFile}
                            />
                          </InputGroup>
                        </Flex>
                        <Text color="yellow.500">les extentions autorisées : MP4,WEBM *</Text>

                      </FormControl>


                      {file && (
                        <>
                          {fileName?.length - 5 > 95
                            ?
                            <Tag
                              mt="10px"
                              size='lg'
                              padding={2}
                              colorScheme='red'
                              borderRadius='full'>
                              <GrCircleAlert size="30px" />
                              <TagLabel ml={3}>
                                Le nom du fichier dépasse 90 caractère !
                              </TagLabel>
                            </Tag>
                            :
                            <Tag
                              mt="10px"
                              size="xl"
                              variant="outline"
                              px="2px"
                              py="5px"
                              position="relative"
                              colorScheme="linkedin"
                            >
                              <AiFillFileAdd size="40px" />
                              <Flex direction="column" justifyContent="center">
                                <Text>
                                  {fileName?.length > 30
                                    ? `${fileName.substring(0, 33)}...`
                                    : `${fileName}`}
                                </Text>
                                <Text fontSize="xs">
                                  {Math.floor(file.size) / (1024) < 1024
                                    ? `${Math.floor(file.size / (1024))} Ko`
                                    : Math.floor(file.size) / (1024 * 1024) < 1024
                                      ? `${Math.floor(file.size / (1024 * 1024))} Mb`
                                      : `${Math.floor(file.size / (1024 * 1024 * 1024))} GB`}

                                  {uploaded === 0 ? null : <Progress mt="1" colorScheme="linkedin" w="28em" rounded="lg" h="5px" size="xs" hasStripe value={uploaded} />}
                                </Text>
                              </Flex>
                            </Tag>}

                        </>

                      )}
                    </Flex>
                  );
                }
              })}
            </ModalBody>

            <ModalFooter>

              {(!name || (file && fileName?.length - 5 > 70))
                ? <Button colorScheme="blue" title="Veuillez remplir tout les champs" isDisabled>
                  Modifier
                </Button>
                : uploaded ?
                  <Button
                    isLoading
                    loadingText='En cours' colorScheme="blue">
                  </Button>
                  :
                  <Button colorScheme="blue" onClick={() => modalUpdate(deletedId, modification, title, oldFileName)}>
                    Modifier
                  </Button>}
            </ModalFooter>
          </ModalContent>
        </Modal>
      }


    </>
  );
}

export default TableVideos;
