import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Avatar,
    Box,
    Button,
    Flex,
    Heading,
    Select,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Text,
    useColorModeValue,
    useDisclosure,
    useMediaQuery,
    useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import Lottie from 'react-lottie';
import Moment from 'react-moment';
import { useLocation } from "react-router-dom";
import feed from '../../assets/lot/feeds.json';
import learning from '../../assets/lot/learning.json';
import axiosClient, { link } from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import MyPagination from "../../components/MyPagination";

const defaultOpt = {
    loop: true,
    autoplay: true,
    animationData: feed,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const EmptyList = {
    loop: true,
    autoplay: true,
    animationData: learning,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function ListFeedBack() {

    // current user
    const { user, setUser } = useStateContext();

    const location = useLocation();
    const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef();

    const [listFeedBack, setListFeedBack] = useState([])
    const [listApprenant, setListApprenant] = useState([])

    const [checkLengthData, setCheckLengthData] = useState(false)
    const [paginationData, setPaginationData] = useState()
    const [loading, setLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(0);
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        axiosClient.get(`/feedback/getModulesRatings/?page_size=${pageSize}&page=${currentPage + 1}&module=${location.state.idModule}`)
            .then((response) => {

                let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setListFeedBack(filteredData);
                    setCheckLengthData(false)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });

    }, [user, pageSize, currentPage])

    useEffect(() => {
        axiosClient.get("auth/user/").then((res) => {
            setUser(res.data);
        });

    }, [])

    const bg = useColorModeValue("gray.200", "gray.500")
    const color = useColorModeValue("gray.700", "white.100")

    const deleteFeed = (id) => {
        axiosClient.delete(`/feedback/${id}/`).then((response) => {
            setListFeedBack((prevData) => prevData.filter((row) => row.id !== id));
            toast({
                title: "Suppression",
                description: `Le Commentaire est suppprimé avec succès`,
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        });
        onClose();
    }



    return (
        <Box mt='5px'>

            <Box mb={5} w="90%">
                <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: "2xl", sm: "3xl" }}>
                    Commentaire {location.state.title}
                </Heading>
            </Box>

            {/* {listFeedBack.length > 0 && */}
            <Box display="flex" w="100%" justifyContent="space-between">
                {loading &&
                <>
                    <Box m="3" w="40%" height={isNotSmallerScreen ? "500px" : ""} maxW={isNotSmallerScreen ? "50%" : "100%"} width="100%" overflowY={isNotSmallerScreen ? "auto" : "none"}>
                        <Box position="relative" my="15px" w="90%">
                            <SkeletonCircle size={10} position="absolute" top={-3} left="0" />
                            <Skeleton height='15px' w={55} ml={50} />
                            <Skeleton h={65} rounded="3xl" ml={10} my={2} w={"100%"} />
                        </Box>
                        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                                <Box flex="1">
                                    <MyPagination
                                        paginationData={paginationData}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        PAGE_SIZE={pageSize}
                                    />
                                </Box>

                                <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </Select>
                            </Flex>
                    </Box>
                    <Box w="50%" h="auto" display={isNotSmallerScreen ? "block" : "none"}>

                    <Lottie height="80%" width="80%" options={defaultOpt} />
                </Box>
                </>
                    }
                {!loading && paginationData?.length !== 0 &&
                    <>
                        <Box m="3" w="40%" height={isNotSmallerScreen ? "500px" : ""} maxW={isNotSmallerScreen ? "50%" : "100%"} width="100%" overflowY={isNotSmallerScreen ? "auto" : "none"}>

                            {( paginationData?.length !== 0) &&
                                listFeedBack.map((val, key) => (
                                    <Box key={key} position="relative" my="15px" w="90%">
                                        <Avatar size="sm" src={val.image} position="absolute" top="0" left="0" />
                                        <Text fontSize={"12px"} color={color} fontStyle={'oblique'} ml={"45px"}>
                                            {`${val.first_name} ${val.last_name}`}
                                        </Text>
                                        <Box
                                            bg={bg}
                                            color={color}
                                            px="3"
                                            py="2"
                                            rounded="3xl"
                                            shadow="md"
                                            w={'100%'}
                                            ml="8"
                                        >
                                            <Flex justify="space-between" direction="column">
                                                <Box textAlign="right">
                                                    <Text fontSize="2xs" fontWeight="bold" color="gray.400">
                                                        <Moment format='MMMM DD YYYY, h:mm:ss a'>{val.created_at}</Moment>
                                                    </Text>
                                                </Box>
                                                <Box>
                                                    <Text fontSize="sm" textAlign="left">
                                                        {val.message}
                                                    </Text>
                                                </Box>
                                                {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                                    <Box textAlign="right">
                                                        <Flex justify='end'>
                                                            <div style={{ marginLeft: '5px' }}>
                                                                <BsTrash cursor='pointer' fontSize="20" onClick={() => {
                                                                    setDeleteId(val.id)
                                                                    onOpen();
                                                                }} />
                                                            </div>
                                                        </Flex>
                                                    </Box>
                                                }
                                            </Flex>
                                        </Box>
                                    </Box>
                                ))}
                            <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                                <Box flex="1">
                                    <MyPagination
                                        paginationData={paginationData}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        PAGE_SIZE={pageSize}
                                    />
                                </Box>

                                <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </Select>
                            </Flex>

                        </Box>

                        <Box w="50%" h="auto" display={isNotSmallerScreen ? "block" : "none"}>

                            <Lottie height="80%" width="80%" options={defaultOpt} />
                        </Box>


                        <AlertDialog
                            isOpen={isOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={onClose}
                        >
                            <AlertDialogOverlay>
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        Supprimer Commentaire
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                        êtes-vous sûr ? Vous ne pourrez pas annuler cette action
                                        ultérieurement.
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={onClose}>
                                            Annuler
                                        </Button>
                                        <Button
                                            onClick={() => deleteFeed(deleteId)}
                                            colorScheme="red"
                                            ml={3}
                                        >
                                            Supprimer
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </>
                }

            </Box>


            {!loading && paginationData?.length === 0 &&
                <Box>
                    <Flex align="end" justify="center">
                        <Lottie height="30%" width="60%" options={EmptyList} />
                    </Flex>
                    <Flex align="end" justify="center">
                        <Text fontWeight="bold" fontSize="xl">Aucune ligne correspondante n'a été trouvée.</Text>
                    </Flex>

                </Box>
            }

        </Box>

    )
}

export default ListFeedBack