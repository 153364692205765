import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon
} from "@chakra-ui/icons";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { MdHistory, MdPermMedia } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";

function TableChapitre() {
  // current user
  const { user, setUser } = useStateContext();

  const [listChapitre, setListChapitre] = useState([])
  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });
  
  }, [user.id])

  useEffect(() => {
    axiosClient.get(`/chapitre/getAllChapitres/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&module=${location.state.idModule}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setListChapitre(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [user, pageSize, currentPage, search])
  function update(){
    setCheckLengthData(false)
    axiosClient.get(`/chapitre/getAllChapitres/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&module=${location.state.idModule}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setListChapitre(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });
  }

  const navigate = useNavigate();

  const location = useLocation();

  const toast = useToast()
  const [message, setMessage] = useState('')
  const [etatMessage, setEtatMessage] = useState(false)

  //Modal
  const [changeModal, setChangeModal] = useState(true)

  //Ajout de chapitre
  const [name, setName] = useState('')
  const [titre, setTitre] = useState('')
  const AjoutChapitre =async () => {
    if (!name || (name.trim() === "")) {
      setMessage("Veuillez remplir le titre")
      return;
    }
    const formData = new FormData()
    formData.append("name", name)
    formData.append("module", location.state.idModule)
    formData.append("added_by", user.id)
    formData.append('modification',
      JSON.stringify([
        {
          "edited_by": edited_by,
          "edited_at": edited_at,
          "field": {
            "name": name,
          }
        }
      ])
    )
   
    const check = await axiosClient.get(`check_exist/?check=Chapitre&value=${name}&idModule=${location.state.idModule}`);
    if (check.data.exists) {
      //   setIsLoading(false);
      window.scrollTo(0, 0);
      setMessage("Ce chapitre existe déjà dans ce module !");
      return;
    }
    axiosClient.post('/chapitre/', formData)
      .then((response) => {
        update()
        setListChapitre([response.data, ...listChapitre]);
        setName('')
       
        toast({
          title: 'Ajout',
          description: `Le Chapitre ${name} est ajouté avec succès`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        setMessage("")
      })
    onClose()
  }



  const [id, setId] = useState(null);
  const [openAlert, setOpenAlert] = useState(false)
  const cancelRef = React.useRef();
  //delete chapitre
  const DeleteChapitre = (id) => {
    axiosClient.delete(`/chapitre/${id}/`)
      .then(() => {
        setListChapitre(data => data.filter(e => e.id !== id))
        toast({
          title: 'Suppression',
          description: `Le Chapitre est supprimé avec succès`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      })
    setOpenAlert(false)
  }


  const [modification, setModification] = useState([])

  const date = new Date();
  date.setDate(date.getDate());

  const [edited_by, setedited_by] = useState(`${user.first_name} ${user.last_name}`)
  const [edited_at, setedited_at] = useState(date)

  //update chapitre
  const updateChapitre =async (id, titre, myModification) => {
    if (!name || (name.trim() === "")) {
      setMessage("Veuillez remplir le titre")
      return;
    }
    const mod = myModification
    mod.push({
      "edited_by": edited_by,
      "edited_at": edited_at,
      "field": {
        "name": name,
      }
    })

    const formData = new FormData();
    formData.append("name", name);
    if (name !== titre) {
      formData.append("modification", JSON.stringify(mod));
    }

    const check = await axiosClient.get(`check_exist/?check=ChapitreEdit&value=${name}&idModule=${location.state.idModule}&idChapitre=${id}`);
    if (check.data.exists) {
      //   setIsLoading(false);
      window.scrollTo(0, 0);
      setMessage("Ce chapitre existe déjà dans ce module !");
      return;
    }

    axiosClient.patch(`/chapitre/${id}/`, formData).then(() => {
      setListChapitre(rows => rows.map(row => {
        if (row.id === id) {
          return {
            ...row,
            name
          }
        }
        return row
      }
      
      ))
      if (name !== titre) {
        toast({
          title: 'Modification',
          description: `Le Chapitre ${name} est modifié avec succès`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      }
      setMessage("")
    })

    onClose()
  }

  const openListMediaWidthIdModule = (idC, titreC) => {
    navigate("/list_medias", {
      state: {
        idChapitre: idC,
        titreChapitre: titreC
      },
    });
  }

  return (
    <Box mt='5px'>
      <Box mb={5} w="90%">
        <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: "2xl", sm: "3xl" }}>
          Liste des Chapitres : {location.state.titreModule}
        </Heading>
      </Box>
      <Flex justifyContent="end" alignItems="center">
        <Button
          colorScheme="blue"
          size="md"
          leftIcon={<AddIcon />}
          onClick={() => {
            setChangeModal(true)
            onOpen()
          }}
        >
          Ajouter un chapitre
        </Button>
      </Flex>
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <Flex mb={3} justifyContent="end" alignItems="center">
          <InputGroup w={{ base: '100%', lg: "30%" }}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              type="tel"
              placeholder="Recherche..."
              onChange={(e) => {
                setCurrentPage(0)
                setSearch(e.target.value)}}
            />
          </InputGroup>
        </Flex>
        <TableContainer>
          <Table size={"sm"}>
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Module</Th>
                <Th>Media</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
            {loading &&
                  <Tr>
                   
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>

                  </Tr>}
              {(paginationData?.length !== 0) &&
                listChapitre.map((val, key) => (
                  <Tr key={key}>
                    <Td>{val.name}</Td>
                    <Td>{location.state.titreModule}</Td>
                    <Td>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<MdPermMedia />}
                        onClick={() => openListMediaWidthIdModule(val.id, val.name)}
                      >
                        Média
                      </Button>
                    </Td>
                    <Td>
                      {(user.role === "SUPERADMIN" || user.role === "MASTER")
                        ?
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<CiMenuKebab />}
                            variant="outline"
                            _hover="none"
                          />
                          <MenuList>
                            <MenuItem
                              icon={<EditIcon fontSize={15} />}
                              onClick={() => {
                                setChangeModal(false)
                                setName(val.name)
                                setTitre(val.name)
                                setId(val.id);
                                setModification(val.modification)
                                onOpen();
                              }}
                            >
                              Modifier
                            </MenuItem>
                            <MenuItem
                              icon={<MdHistory fontSize={15} />}
                              onClick={() =>
                                navigate('/historique_chapitre', {
                                  state: {
                                    idChapitre: val.id
                                  }
                                })
                              }
                            >
                              Historique
                            </MenuItem>
                            <MenuItem
                              icon={<DeleteIcon fontSize={15} />}
                              onClick={() => {
                                setOpenAlert(true)
                                setId(val.id)
                              }}
                            >
                              Supprimer
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        :
                        <>
                          <IconButton
                            size="sm"
                            colorScheme="green"
                            mr={2}
                            icon={<EditIcon />}
                            onClick={() => {
                              setChangeModal(false)
                              setName(val.name)
                              setTitre(val.name)
                              setId(val.id);
                              setModification(val.modification)
                              onOpen();
                            }}
                          >
                            Modifier
                          </IconButton>
                          <IconButton
                            size="sm"
                            bg="gray.300"
                            icon={<MdHistory />}
                            onClick={() =>
                              navigate('/historique_chapitre', {
                                state: {
                                  idChapitre: val.id
                                }
                              })
                            }
                          >
                            Historique
                          </IconButton>
                        </>

                      }

                    </Td>
                  </Tr>
                ))}
              {((search && paginationData?.length === 0) || (checkLengthData)) && (
                <Tr>
                  <Td colSpan={6}>
                    Aucune ligne correspondante n'a été trouvée.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              paginationData={paginationData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              PAGE_SIZE={pageSize}
            />
          </Box>

          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Stack>

      <AlertDialog
        isOpen={openAlert}
        onClose={() => setOpenAlert(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer chapitre
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action
              ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setOpenAlert(false)}>
                Annuler
              </Button>
              <Button
                onClick={() => DeleteChapitre(id)}
                colorScheme="red"
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>


      {
        changeModal ?
          //Modal d'ajout
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
            onClose()
            setName('')
            setMessage("")
          }}>
            <ModalOverlay />

            <ModalContent>

              <ModalHeader>Ajout de Chapitre</ModalHeader>

              <ModalCloseButton />
              <ModalBody pb={6}>
                {message &&
                  <Alert status='error' rounded="md">
                    <AlertIcon />
                    {message}
                  </Alert>
                }
                <FormControl isRequired>
                  <FormLabel>Nom de chapitre</FormLabel>
                  <Input maxLength={50} name="name" onChange={e => setName(e.target.value)} placeholder='Nom de chapitre...' />
                </FormControl>
                <Flex justify="end">
                  {name.length > 40
                    ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                    : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                  }
                </Flex>
              </ModalBody>

              <ModalFooter>
                {!name
                  ?
                  <Button colorScheme='blue' title="Entrer le titre du chapitre" isDisabled
                  >
                    Ajouter
                  </Button>
                  :
                  <Button colorScheme='blue' onClick={() => {
                    AjoutChapitre()
                  }}
                  >
                    Ajouter
                  </Button>
                }
              </ModalFooter>
            </ModalContent>
          </Modal>
          :
          //Modal de modifaction
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
            onClose()
            setMessage("")
          }}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Modification de Chapitre</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                {message &&
                  <Alert status='error' rounded="md">
                    <AlertIcon />
                    {message}
                  </Alert>
                }
                <FormControl isRequired>
                  <FormLabel>Nom de chapitre</FormLabel>
                  <Input maxLength={50} name="name" defaultValue={name} onChange={e => setName(e.target.value)} placeholder='Nom de chapitre...' />
                </FormControl>
                <Flex justify="end">
                  {name.length > 40
                    ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                    : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                  }
                </Flex>
              </ModalBody>

              <ModalFooter>
                {!name
                  ?
                  <Button colorScheme='blue' title="Entrer le titre du chapitre" isDisabled
                  >
                    Modifier
                  </Button>
                  :
                  <Button colorScheme='blue' onClick={() => {
                    updateChapitre(id, titre, modification)
                  }}>
                    Modifier
                  </Button>
                }
              </ModalFooter>
            </ModalContent>
          </Modal>
      }


    </Box >
  )
}

export default TableChapitre