import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Text,
  Stack,
  Icon,
  useColorModeValue,
  IconButton,
  Link,
  Textarea,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  InputRightElement,
  AlertIcon,
  Alert,
  useToast,
  Box
} from '@chakra-ui/react';
import { PhoneIcon, EmailIcon, AddIcon, CloseIcon } from '@chakra-ui/icons'
import { MdLocationOn } from 'react-icons/md';
import { BsLinkedin, BsYoutube, BsInstagram, BsFacebook } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';

import { Fragment, useEffect, useState } from 'react';
import axiosClient from '../../axios-client';
import contact from "../../assets/img/Contacts.png";

const confetti = {
  light: {
    primary: '1a365d', // blue.400
    secondary: 'BEE3F8', // blue.100
  },

  dark: {
    primary: '1a365d', // blue.900
    secondary: '2A4365', // blue.800
  },
};

export const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
        mr={'5'}>
        {icon}
      </Flex>
      <Text fontWeight={600} size={'xl'}>{text}</Text>
    </Stack>
  );
};

export default function GestionContact() {
  function update() {
    axiosClient.get('/contact/')
      .then((response) => {
        setDataContact(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [dataContact, setDataContact] = useState([]);
  const [message, setMessage] = useState('')
  const toast = useToast()

  const [ID, setID] = useState()
  const [content, setContent] = useState("")
  const [address, setAddress] = useState("")
  const [email] = useState('')
  const [phone] = useState('')
  const [listEmail, setListEmail] = useState([
    {
      email: ''
    }
  ])
  const [listPhone, setListPhone] = useState([
    {
      phone: '',
    }
  ]);

  //get the plainte data
  useEffect(() => {
    axiosClient.get('/contact/')
      .then((response) => {
        setDataContact(response.data.results);

        if (response.data.results.length > 0) {
          const contactData = response.data.results[0];
          setListEmail(contactData.email);
          setListPhone(contactData.phone);

          setID(contactData.id);

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const addEmailForm = () => {
    setListEmail(prevListEmail => [...prevListEmail, { email: '' }]);
  };
  const removeEmailForm = (i) => {
    const list = [...listEmail];
    list.splice(i, 1);
    setListEmail(list);

  };


  const addPhoneForm = () => {
    setListPhone(prevListPhone => [...prevListPhone, { phone: '' }]);
  };
  const removePhoneForm = (i) => {
    const list = [...listPhone];
    list.splice(i, 1);
    setListPhone(list);

  };


  const handleEmailChange = (index, value) => {
    setListEmail(prevListEmail => {
      const updatedEmailList = [...prevListEmail];
      updatedEmailList[index] = { email: value };
      return updatedEmailList;
    });
  }
  const handlePhoneChange = (index, value) => {
    setListPhone(prevListPhone => {
      const updatedPhoneList = [...prevListPhone];
      updatedPhoneList[index] = { phone: value };
      return updatedPhoneList;
    });
  }

  const [hoverEmail, setHoverEmail] = useState(-1)
  const [hoverPhone, setHoverPhone] = useState(-1)

  function getEmail(email) {
    return (
      <>
        {
          listEmail.map((val, index) => (
            <InputGroup key={index}
              onMouseEnter={() => setHoverEmail(index)}
              onMouseLeave={() => setHoverEmail(-1)}>
              <InputLeftElement pointerEvents="none">
                <Icon as={MdOutlineEmail} />
              </InputLeftElement>
              <Input
                maxLength={50}
                type="email"
                placeholder="Email address"
                value={val.email}
                onChange={e => handleEmailChange(index, e.target.value)}
              />

              <InputRightElement >
                {hoverEmail === index && listEmail.length > 1 && (
                  <CloseIcon color={'red'} className='btnclose' fontSize="xs" cursor="pointer" onClick={() => removeEmailForm(index)} />
                )}

                {index === listEmail.length - 1 && (
                  <AddIcon ml={2} mr={5} fontSize="sm" cursor="pointer" onClick={addEmailForm} />

                )}
              </InputRightElement>


            </InputGroup>
          ))}
      </>
    );
  }
  function getPhone(phone) {
    return (
      <>
        {
          listPhone.map((val, index) => (
            <InputGroup key={index}
              onMouseEnter={() => setHoverPhone(index)}
              onMouseLeave={() => setHoverPhone(-1)}>
              <InputLeftElement pointerEvents="none">
                <Icon as={PhoneIcon} />
              </InputLeftElement>
              <Input
                maxLength={20}
                type="text"
                placeholder="telephone"
                value={val.phone}
                onChange={e => handlePhoneChange(index, e.target.value)}
              />

              <InputRightElement>

                {hoverPhone === index && listPhone.length > 1 && (
                  <CloseIcon color={'red'} className='btnclose' fontSize="xs" cursor="pointer" onClick={() => removePhoneForm(index)} />
                )}
                {index === listPhone.length - 1 && (
                  <AddIcon ml={2} mr={5} fontSize="sm" cursor="pointer" onClick={addPhoneForm} />
                )}
              </InputRightElement>


            </InputGroup>
          ))}
      </>
    );
  }
  function GradientText({ children, gradient }) {
    const gradientBg = {
      background: `linear-gradient(to left, ${gradient})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    };

    return (
      <Text fontWeight={"bold"} fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }} as="span" sx={gradientBg}>
        {children}
      </Text>
    );
  }



  const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^(\+)?[\d\s*]+$/;

  const [uploaded, setUploaded] = useState(null)

  const updateContact = () => {
    for (let index = 0; index < listEmail.length; index++) {
      if (listEmail[index].email === "") {
        window.scrollTo(0, 0)
        setMessage("Veuillez remplir les champs!")
        return;
      }
      if (!emailRegex.test(listEmail[index].email)) {
        window.scrollTo(0, 0)
        setMessage("Veuillez entrer un email valide.");
        return;
      }
    }
    for (let index = 0; index < listPhone.length; index++) {
      if (listPhone[index].phone === "" || listPhone[index].phone.trim() === "") {
        window.scrollTo(0, 0)
        setMessage("Veuillez remplir les champs!")
        return;
      }
      if (!phoneRegex.test(listPhone[index].phone)) {
        window.scrollTo(0, 0)
        setMessage("Veuillez entrer un numéro de téléphone valide.");
        return;
      }
    }
    // if (!content || !address) {
    //   setMessage("Veuillez remplir les champs!")
    //   return;
    // }


    const updatedContact = {};

    if (content) {
      updatedContact.content = content;
    } else { updatedContact.content = "N'hésitez pas à nous contacter par téléphone ou par email pour toute question ou requête. Nous sommes là pour vous aider rapidement." }

    if (address) {
      updatedContact.address = address;
    }

    if (listEmail) {
      updatedContact.email = listEmail;
    }

    if (listPhone) {
      updatedContact.phone = listPhone;
    }

    axiosClient.patch(`/contact/${ID}/`, updatedContact, {
      onUploadProgress: (data) => {
        setUploaded(Math.round((data.loaded / data.total) * 100))
      }
    })
      .then(res => {
        toast({
          description: "les données sont modifiées avec succès",
          status: 'success',
          duration: 1200,
          isClosable: true,
          position: 'bottom-right'
        })
        //  setTimeout(() => {
        //   window.location.reload();
        // }, 1200)
        update()
        setUploaded('')
        setMessage("")
      }

      )
      .catch((error) => {
        toast({
          description: "erreur de modification",
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: 'bottom-right'
        })
      });
  }

  return (
    <Container maxW={'7xl'} p={{ base: '5', sm: '5', md: '5', lg: '10' }}>
      <GradientText gradient="#ffd140, #089bd7">
        Contact
      </GradientText>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} >

        <Stack p={3} spacing={1} overflow={'auto'} maxH={'450px'}>

          {message &&
            <Flex>
              <Alert status='error' rounded="md">
                <AlertIcon />
                {message}
              </Alert>
            </Flex>
          }

          {dataContact.map((val, key) => {
            return (
                <Box key={key}>
                  <Textarea
                    maxLength={250}
                    defaultValue={val.content}
                    borderColor="gray.300"
                    _hover={{
                      borderRadius: 'gray.300',
                    }}
                    h="80px"
                    mb={3}
                    name="content"
                    onChange={e => setContent(e.target.value)}
                    placeholder="Ecrivez votre message"
                  />
                  <Stack
                    spacing={4}
                  >

                    <InputGroup>
                      <InputLeftElement pointerEvents='none'>
                        <MdLocationOn size="25" color='gray.700' />
                      </InputLeftElement>
                      <Input
                        maxLength={250}
                        type='text'
                        name='address'
                        defaultValue={val.address}
                        placeholder='Adresse'
                        onChange={e => setAddress(e.target.value)} />
                    </InputGroup>

                    {getEmail(val.email)}

                    {getPhone(val.phone)}

                    {uploaded
                      ?
                      <Button
                        isLoading
                        loadingText="En cours"
                        colorScheme='whatsapp'
                        justifyContent="end"
                      />
                      :
                      <Button colorScheme='whatsapp' variant='solid' onClick={updateContact}>
                        Modifier
                      </Button>}


                  </Stack>

                </Box>
            )
          })}
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              contact}
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>
      <Stack

        align="center"
        justify="center"
        direction='row' spacing={'5'} mt={'10'}>
        <Link href="https://www.facebook.com/Paiperleck/">
          <IconButton
            aria-label="github"
            variant="ghost"
            size="md"
            fontSize="2xl"
            bg={'#ffd140'}
            color={'white'}
            icon={<BsFacebook />}
            _hover={{
              bg: 'blue.700',
              color: useColorModeValue('gray.100', 'gray.700'),
            }}
            isRound
          />
        </Link>

        <Link href="https://www.instagram.com/paiperleck_sarl/">
          <IconButton
            aria-label="github"
            variant="ghost"
            size="md"
            fontSize="2xl"
            bg={'#ffd140'}
            color={'white'}
            icon={<BsInstagram />}
            _hover={{
              bg: 'blue.700',
              color: useColorModeValue('gray.100', 'gray.700'),
            }}
            isRound
          />
        </Link>

        <Link href="https://www.youtube.com/channel/UCsZz3QR-6qUHsjtEAaFXrMQ">
          <IconButton
            aria-label="twitter"
            variant="ghost"
            size="md"
            fontSize="2xl"
            bg={'#ffd140'}
            color={'white'}
            icon={<BsYoutube size="20px" />}
            _hover={{
              bg: 'blue.700',
              color: useColorModeValue('gray.100', 'gray.700'),
            }}
            isRound
          />
        </Link>

        <Link href="https://www.linkedin.com/company/p%C3%A4iperleck-aides-et-soins-%C3%A0-domicile---r%C3%A9sidences-seniors/?originalSubdomain=lu">
          <IconButton
            aria-label="linkedin"
            variant="ghost"
            size="md"
            fontSize="2xl"
            bg={'#ffd140'}
            color={'white'}
            icon={<BsLinkedin size="20px" />}
            _hover={{
              bg: 'blue.700',
              color: useColorModeValue('gray.100', 'gray.700'),
            }}
            isRound
          />
        </Link>
      </Stack>

    </Container>
  );
}