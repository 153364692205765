import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Media, Video } from '@vidstack/player-react';
import poster from '../../assets/img/e-learn.jpg';

function ShowMedia() {
    const location = useLocation();
    const [isPlaying, setIsPlaying] = useState(false);
    const [progvid, setProgressVideo] = useState();
    const [currentTime, setCurrentTime] = useState(0);
    const videoRef = useRef(null);

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    const handleProgress = (event) => {
        const video = event.target;
        const { currentTime, duration } = video;
        setProgressVideo((currentTime / duration) * 100);
        setCurrentTime(currentTime);
    };
    console.log("location.state.fil", location.state.file);

    // Inline styles for centering
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
    };

    return (
        <>
            {location.state.fileType === "ppt" &&
                <DocViewer
                    documents={[{
                        uri: location.state.file,
                        fileType: "pptx"
                    }]}
                    pluginRenderers={DocViewerRenderers}
                    style={{ height: 1000 }}
                    onError={(error) => console.error('Error loading document:', error)}
                />
            }

            {location.state.fileType === "pdf" &&
                <DocViewer documents={[{
                    uri: location.state.file,
                }]} pluginRenderers={DocViewerRenderers} style={{ height: 1000 }} />
            }

            {location.state.fileType === "Video" &&
                <div style={containerStyle}>
                    <Media>
                        <Video
                            loading="visible"
                            poster={poster}
                            controls
                            preload="true"
                            onTimeUpdate={handleProgress}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            onEnded={handleEnded}
                        >
                            <video
                                loading="visible"
                                poster={poster}
                                src={location.state.file}
                                preload="none"
                                data-video="0"
                                controls
                                ref={videoRef}
                                controlsList="nodownload" // Disable download
                            />
                        </Video>
                    </Media>
                </div>
            }
        </>
    );
}

export default ShowMedia;
