import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from 'react-icons/md';
// import { LiaUserEditSolid } from 'react-icons/lia';
import moment from 'moment';

import {
    IconButton,
    Flex,
    Box,
    Heading,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    FormControl,
    FormLabel,
    useToast,
    TableContainer,
    useColorModeValue,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    HStack,
    Tooltip,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Skeleton,
    SkeletonCircle,
    Spinner

} from '@chakra-ui/react';

export const GestApprenant = () => {
    const [fullName, setFullName] = useState()
    const [oldRole, setOldRole] = useState()
    const [newRole, setNewRole] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');
    const [checkLengthData, setCheckLengthData] = useState(false)

    //search variable
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialFocusRef = React.useRef()
    const [paginationData, setPaginationData] = useState()
    const [loading, setLoading] = useState(true)

    const [messageAlert, setMessageAlert] = useState('')
    const [checkArrayOneRespo, setCheckArrayOneRespo] = useState()
    const [loadingCheckAutorisation, setLoadingCheckAutorisation] = useState(false)
    const [loadingCheckAutorisationSupp, setLoadingCheckAutorisationSupp] = useState(false)
    const [indexRole, setIndexRole] = useState(-1)
    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
        setMessageAlert('')
        setLoadingCheckAutorisationSupp(false)
        setCheckArrayOneRespo(false)
    };

    const onCloseModal = () => {
        setMessageAlert('')
        setLoadingCheckAutorisation(false)
        setCheckArrayOneRespo(false)
        setIndexRole(-1)
        setIsModalOpen(false);
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Apprenant data
    const [data, setData] = useState([]);
    const [listModule, setListModule] = useState([])
    const [listFormation, setListFormation] = useState([])

    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the apprenant data
    useEffect(() => {
        axiosClient.get('/formation/?page=1&page_size=10000').then((res) => {
            setListFormation(res.data.results)
        })
        axiosClient.get('/module/?page=1&page_size=10000').then((res) => {
            setListModule(res.data.results)
        })

    }, [user.role]);

    useEffect(() => {
        axiosClient.get(`/responsables/getAllRespo/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&role=${selectedUser}&user=${user?.id}`)
            .then((response) => {

                let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                    setCheckLengthData(false)
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });

    }, [user, selectedUser, selectedEtat, pageSize, currentPage, searchTerm])


    const handleRoleSubmit = () => {
        changeRole(deleteId, selectedRole);
        setIsModalOpen(false);
    };

    const [message, setMessage] = useState(false)
    const [modules, setModules] = useState({
        manyRespo: [],
        oneRespo: [],
        formattedOutput: '',
    });


    // const getModulebyRespo = (id) => {

    //     const moduleRespo = (listModule.filter(row => row.responsable.find(obj => obj === id)))

    //     const listModuleManyRespo = moduleRespo.filter(row => row.responsable.length > 1)
    //     const listModuleOneRespo = moduleRespo.filter(row => row.responsable.length < 2)

    //     let manyRespo = []
    //     let oneRespo = []

    //     if (listModuleManyRespo.length > 0) {
    //         listModuleManyRespo.map((val) => {
    //             manyRespo.push({
    //                 formation: listFormation.filter(row => row.id === val.formation)[0].titre,
    //                 titreModule: val.titre
    //             })
    //         })
    //     }

    //     if (listModuleOneRespo.length > 0) {
    //         listModuleOneRespo.map((val) => {
    //             oneRespo.push({
    //                 formation: listFormation.filter(row => row.id === val.formation)[0].titre,
    //                 titreModule: val.titre
    //             })
    //         })
    //     }

    //     //Regrouper les modules par formation
    //     const groupedByValue = oneRespo.reduce((acc, curr) => {
    //         const { formation, titreModule } = curr;
    //         if (!acc[formation]) {
    //             acc[formation] = [];
    //         }
    //         acc[formation].push(titreModule);
    //         return acc;
    //     }, {});

    //     const formattedOutput = Object.entries(groupedByValue)
    //         .map(([formation, titreModules]) => `${titreModules.length > 1 ? "des modules :" : "du module :"} ${titreModules.join(', ')} de la formation ${formation}`)
    //         .join(', ');

    //     return { manyRespo, oneRespo, listModuleManyRespo, listModuleOneRespo, formattedOutput }

    // }



    const changeRole = async (id) => {
        const dataRespo = await checkAutorisationRespo(id)
        const updatedData = data.map(row => {
            if (row.id === id) {
                const formData = new FormData();


                formData.append("role", selectedRole);
                formData.append("edited_by", user.first_name + ' ' + user.last_name);


                if (dataRespo.listModuleManyRespo.length > 0) {
                    dataRespo.listModuleManyRespo.map((val, key) => {
                        const filteredResponsableIds = val.responsable.filter(element => element !== id);
                        const formData = new FormData()
                        // Append each filtered ID as a separate field entry
                        filteredResponsableIds.forEach(responsableId => {
                            formData.append("responsable", responsableId); // Append each ID
                        });
                        axiosClient
                            .patch(`/module/${val.id}/`, formData)
                    })
                }


                axiosClient.put(`/role_update/${id}/`, formData)
                    .then((response) => {
                        const updatedRow = response.data;
                        // Update the row object with the new etat value
                        setData(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    role: selectedRole,
                                    date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                                    edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: "le role est changé",
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                            position: 'bottom-right'
                        })
                    })

                    .catch((error) => console.error(error));

            }
        });
    }

    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/responsables/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "l'utilisateur est supprimé avec succès",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //edit l'etat de responsable ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/etat_update/${id}/`, formData)
            .then((response) => {
                const updatedRow = response.data;
                // Update the row object with the new etat value
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                            date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                            edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: "le compte est activé",
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: "le compte est désactivé",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }


    const formatDate = (dateString) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

    };


    const checkAutorisationRespo = async (id) => {
        setLoadingCheckAutorisation(true);
        try {
            const response = await axiosClient.get(`get_module_by_respo/${id}/`);
            setLoadingCheckAutorisation(false);

            if (response.data.oneRespo.length > 0) {
                setCheckArrayOneRespo(true);
                setMessageAlert(response.data.formattedOutput);
            } else {
                setCheckArrayOneRespo(false);
            }


            return response.data;  // Return the data so you can access it outside the function
        } catch (error) {
            setCheckArrayOneRespo(false);
            setLoadingCheckAutorisation(false);
            console.log(error);
            return null;  // Return null or some fallback value in case of error
        }
    };


    const checkAutorisationRespoDelete = async (id) => {
        setLoadingCheckAutorisationSupp(true)
        try {
            await axiosClient
                .get(`get_module_by_respo/${id}/`)
                .then(res => {
                    setLoadingCheckAutorisationSupp(false)
                    if (res.data.oneRespo.length > 0) {
                        setCheckArrayOneRespo(true)
                        setMessageAlert(res.data.formattedOutput)
                    } else {
                        setCheckArrayOneRespo(false)
                    }
                    onOpenAlert()
                })
        } catch (error) {
            setCheckArrayOneRespo(false)
            setLoadingCheckAutorisationSupp(false)
            console.log(error);

        }
    }


    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" >
                    <Heading bgGradient='linear(to-l, #ffd140, #089bd7)' bgClip='text' fontSize={{ base: '2xl', sm: '3xl' }}>
                        Administrateurs
                    </Heading>
                </Box>

            </Flex>

            <Box
                justify={'end'}
                align={'end'}
                mt={5}>
                {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&
                    <Button
                        mt="10px"
                        onClick={() => navigate('/addResponsable')}
                        colorScheme="blue"
                        leftIcon={<AddIcon />}>
                        Ajouter responsable
                    </Button>}
            </Box>
            <Flex
                align={'center'}
                justify={'center'}
            >

                <Stack

                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex w={"100%"}>
                            <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedUser} onChange={(e) => {
                                setCurrentPage(0)
                                setSelectedUser(e.target.value)}}>
                                <option value="">Tout les utilisateurs</option>
                                {user.role==="SUPERADMIN" && 
                                <option value="MASTER">Master</option>
                                }
                                
                                <option value="ADMIN">Administrateur</option>
                                <option value="RESPO">Responsable</option>
                            </Select>
                            <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mr={5} mb={4} value={selectedEtat} onChange={(e) => {
                                setCurrentPage(0)
                                setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}}>
                                <option value="">Tout</option>
                                <option value="true">Activé</option>
                                <option value="false">Désactivé</option>
                            </Select>
                        </Flex>


                        <InputGroup w={{ base: '70%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                placeholder="Recherche..."
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)}}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table >
                            <Thead>
                                <Tr>
                                    <Th>Image</Th>
                                    <Th>Code</Th>
                                    <Th>Nom</Th>
                                    <Th>Prénom</Th>
                                    <Th>Email</Th>
                                    <Th >Adresse</Th>
                                    <Th >Pays</Th>
                                    <Th >Etat</Th>
                                    <Th >Role</Th>
                                    <Th>Date Creation</Th>
                                    {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                        <Th>Options</Th>
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loading &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                    </Tr>}
                                {(paginationData?.length !== 0) &&
                                    data.map((row) => (
                                        <Tr p={0} key={row.id}>
                                            <Td > <Avatar src={row.image} /></Td>
                                            <Td>{row.code}</Td>
                                            <Td p={4}>{row.last_name}</Td>
                                            <Td p={4}>{row.first_name}</Td>
                                            <Td p={1}>{row.email}</Td>
                                            <Td>
                                                <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                    {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                                </Tooltip>
                                            </Td>
                                            <Td>{row.pays}</Td>
                                            <Td p={5}>
                                                <Flex>
                                                    <Stack direction='row' mr="3px">
                                                        {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&
                                                            <Switch
                                                                colorScheme='green'
                                                                isChecked={row.etat}
                                                                onChange={() => handleEtatClick(row.id, row.etat)}
                                                            />
                                                        }
                                                    </Stack>
                                                    <Badge
                                                        rounded={'lg'}
                                                        colorScheme={!row.etat ? 'red' : 'green'}
                                                    >
                                                        {row.etat ? 'activé' : 'désactivé'}
                                                    </Badge>
                                                </Flex>
                                            </Td>
                                            {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") ?
                                                <Td w={"100%"} p={0}>
                                                    <Badge
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        rounded="full"
                                                        w={"fit-content"}
                                                        py={2}
                                                        px={4}
                                                        colorScheme={row.role === "MASTER" ? "telegram" : row.role === "ADMIN" ? "orange" : "purple"}
                                                        cursor={(user.role === "SUPERADMIN" || user.role === "MASTER") ? "pointer" : null}
                                                        title={(user.role === "SUPERADMIN" || user.role === "MASTER") ? "changer le rôle de l'utilisateur" : null}
                                                        onClick={() => {
                                                            if ((user.role === "SUPERADMIN" || user.role === "MASTER")) {
                                                                setFullName(`${row.first_name} ${row.last_name}`)
                                                                setSelectedRole(row.role)
                                                                setDeleteId(row.id);
                                                                checkAutorisationRespo(row.id);
                                                                setIsModalOpen(true);
                                                                setIndexRole(row.id)
                                                            }
                                                        }}
                                                        _disabled={loadingCheckAutorisation && indexRole === row.id}>
                                                        {/* <LiaUserEditSolid size={20} /> */}
                                                        <Text ml={1}>{row.role === "MASTER" ? "MASTER" : row.role === "ADMIN" ? "Administrateur" : "Responsable"}</Text>

                                                        {loadingCheckAutorisation && indexRole === row.id && <Spinner
                                                            ml={2}
                                                            speed='0.65s'
                                                            emptyColor='gray.200'
                                                            color='black'
                                                            size='xs'
                                                        />}
                                                    </Badge>
                                                </Td>
                                                : <Td> {row.role.toLowerCase() === 'admin' ? 'Administrateur' : row.role.toLowerCase() === 'respo' ? 'Responsable' : row.role.toLowerCase() === 'master' ? 'Super administrateur' : 'null'} </Td>
                                            }
                                            <Td>{new Date(row.date_creation).toLocaleDateString('fr-FR')}</Td>
                                            <Td>

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => navigate(`/editResponsable`, {
                                                        state: {
                                                            idResponsable: row.id
                                                        }
                                                    })}
                                                    mr="5px"
                                                    colorScheme="green"
                                                    icon={<EditIcon />}>
                                                </IconButton>
                                                <Popover placement="left" >
                                                    <PopoverTrigger>
                                                        <IconButton
                                                            size="sm"
                                                            mr="5px"
                                                            bg="gray.300"
                                                            color={"black"}
                                                            icon={<MdHistory />}>
                                                        </IconButton>
                                                    </PopoverTrigger>
                                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                                        <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                            Historique
                                                        </PopoverHeader>
                                                        <PopoverArrow bg="blue.200" />
                                                        <PopoverCloseButton />
                                                        <PopoverBody>
                                                            <div style={{ marginBottom: '8px' }}>
                                                                Ajouté par : {row.added_by}
                                                            </div>
                                                            <div style={{ marginBottom: '8px' }}>
                                                                Modifié par : {row.edited_by}
                                                            </div>

                                                            <div>
                                                                Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                            </div>
                                                        </PopoverBody>
                                                        <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                        </PopoverHeader>
                                                    </PopoverContent>
                                                </Popover>
                                                {(user.role === "SUPERADMIN" || user.role === "MASTER") &&

                                                    <IconButton
                                                        isLoading={loadingCheckAutorisationSupp && indexRole === row.id}
                                                        size="sm"
                                                        onClick={() => {
                                                            setDeleteId(row.id);
                                                            setSelectedRole(row.role === "MASTER" ? "Super-administrateur" : row.role === "ADMIN" ? "Administrateur" : "Responsable")
                                                            setIndexRole(row.id)
                                                            checkAutorisationRespoDelete(row.id)
                                                        }}
                                                        mr="5px"
                                                        colorScheme="red"
                                                        icon={<DeleteIcon />}>
                                                    </IconButton>}
                                            </Td>

                                        </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat !== null || selectedRole !== null) && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>Aucune ligne correspondante n'a été trouvée.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Supprimer {selectedRole}
                        </AlertDialogHeader>


                        {!loadingCheckAutorisationSupp ?
                            <AlertDialogBody >
                                {!loadingCheckAutorisationSupp && checkArrayOneRespo &&
                                    <Alert maxH={200} overflow='auto' status='error' alignItems="start" >
                                        <AlertIcon />
                                        <AlertDescription><AlertTitle>Erreur !</AlertTitle>
                                            La suppression de ce responsable {messageAlert}.<br />
                                            Pour procéder à cette suppression, un autre responsable doit être affecté à ces modules.</AlertDescription>
                                    </Alert>
                                }
                                {/* <Text fontWeight="bold">Impossible de modifier le rôle de ce responsable car il gère {getModulebyRespo(deleteId).formattedOutput} tout seul il faut ajouter un autre responsable à {getModulebyRespo(deleteId).oneRespo.length > 1 ? 'ces modules' : 'ce module'}</Text> */}

                                {!loadingCheckAutorisationSupp && !checkArrayOneRespo && <Stack>
                                    <Text>
                                        êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
                                    </Text>
                                </Stack>
                                }

                            </AlertDialogBody>
                            :

                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='md'
                            />
                        }



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                Annuler
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={messageAlert}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Modifier le rôle de {fullName}
                        </AlertDialogHeader>

                        {!loadingCheckAutorisation ?
                            <AlertDialogBody >
                                {!loadingCheckAutorisation && checkArrayOneRespo &&
                                    <Alert maxH={200} overflow='auto' status='error' alignItems="start" >
                                        <AlertIcon />
                                        <AlertDescription><AlertTitle>Erreur !</AlertTitle>
                                            La modification du rôle de ce responsable {messageAlert}.<br />
                                            Pour procéder à cette modification, un autre responsable doit être affecté à ces modules.</AlertDescription>
                                    </Alert>
                                }
                                {/* <Text fontWeight="bold">Impossible de modifier le rôle de ce responsable car il gère {getModulebyRespo(deleteId).formattedOutput} tout seul il faut ajouter un autre responsable à {getModulebyRespo(deleteId).oneRespo.length > 1 ? 'ces modules' : 'ce module'}</Text> */}

                                {!loadingCheckAutorisation && !checkArrayOneRespo && <Stack>
                                    <Text>
                                        Êtes-vous sûr de vouloir changer le rôle de cet utilisateur ? Changer le rôle mettra à jour ses privilèges et peut affecter sa capacité à effectuer certaines actions.
                                    </Text>
                                    <Select
                                        defaultValue={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                                        {(user.role === "SUPERADMIN" ) &&
                                            <option value="MASTER">Super-administrateur</option>
                                        }
                                        <option value="RESPO">Responsable</option>
                                        <option value="ADMIN">Administrateur</option>
                                    </Select>
                                </Stack>
                                }

                            </AlertDialogBody>
                            :
                            <AlertDialogBody >
                                <Flex w={"100%"} height={"100%"} align={"center"} justify={'center'}>

                                     <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='yellow.500'
                                    size='md'
                                />
                                </Flex>
                               
                            </AlertDialogBody>

                        }

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage('')
                                setNewRole(oldRole)
                                onCloseModal()
                            }}>
                                Annuler
                            </Button>
                            <Button onClick={handleRoleSubmit} colorScheme='green' ml={3} isDisabled={messageAlert}>
                                Modifier
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>

    )
}

export default GestApprenant