import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  Avatar,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useColorModeValue,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
  Image,
  useToast,
  Alert,
  AlertIcon,
  Tag,
  TagLabel,
  InputRightAddon,
  Radio,
  RadioGroup,
  AvatarBadge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select as MySelect,
  Divider,
} from "@chakra-ui/react";
import { Select as mySelect } from "@chakra-ui/react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import React, { Fragment, useEffect, useState } from "react";
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from "react-icons/md";
import { BsGithub, BsDiscord, BsPerson, BsImageFill } from "react-icons/bs";
import axios from "axios";
import axiosClient from "../../axios-client";
import { useNavigate, useLocation } from "react-router-dom";
import imgAddModule from "../../assets/img/add_module.png";
import defaultImage from "../../assets/img/default-image.jpg";
import { useMediaQuery } from "@chakra-ui/react";
import { useStateContext } from "../../context/ContextProvider";
import { TbCameraRotate } from "react-icons/tb";
import { AiOutlineCheck } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

const animatedComponents = makeAnimated();

function EditModule() {
  // current user
  const { user } = useStateContext();
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  function getListRespoForModule() {
    let Respo = []
    for (let index = 0; index < location.state.responsable.length; index++) {
      Respo.push({
        value: location.state.responsable[index].value,
        label: location.state.responsable[index].label
      })
    }
    return Respo
  }


  const { isOpen, onOpen, onClose } = useDisclosure();

  const [listResponsable, setListResponsable] = useState([]);
  const [responsable, setResponsable] = useState(getListRespoForModule());
  const [medias, setMedias] = useState([]);
  const [selectedResponsable, setSelectedResponsable] = useState(getListRespoForModule());
  const [selectedDiplome, setSelectedDiplome] = useState({});


  useEffect(() => {
    axiosClient
      .get("/responsables/?page=1&page_size=10000")
      .then((res) => setListResponsable(res.data.results));
    let myRespo = [];
    let respo = [];
    axiosClient.get(`/module/${location.state.idModule}/`).then((res) => {
      setMedias(res.data);
      respo = res.data.responsable;
      listResponsable.map((v, k) => {
        for (let o = 0; o < respo.length; o++) {
          if (v.id === respo[o]) {
            myRespo.push({
              value: v.id,
              label: `${v.last_name} ${v.first_name}`,
            });
          }
        }
      });
    });
    // respo.push({
    //   value: v.id,
    //   label: `
    //   ${v.last_name}
    //   ${v.first_name}
    //   `});
    // setSelectedResponsable(getListRespoForModule())
    // setResponsable(myRespo)
  }, []);

  useEffect(() => {
    if (listResponsable !== undefined && medias !== undefined) {
      // getResponsableForModule();
    }
  }, [listResponsable, medias]);

  useEffect(() => {
    if (medias !== undefined) {
      getDiplomeForModule();
    }
  }, [medias]);

  // function getResponsableForModule() {
  //   medias.map((val) => {
  //     if (val.id === location.state.idModule) {
  //       {
  //         listResponsable.map((v, k) => {
  //           for (let index = 0; index < val.responsable.length; index++) {
  //             if (v.id === val.responsable[index]) {
  //               setSelectedResponsable((currentSelectedResponsable) => [
  //                 ...currentSelectedResponsable,
  //                 {
  //                   value: v.id,
  //                   label: `
  //               ${v.last_name}
  //               ${v.first_name}
  //               `,
  //                 },
  //               ]);
  //             }
  //           }
  //         });
  //       }
  //     }
  //   });
  // }

  function dataResponsable() {
    const options = [];
    listResponsable.map((val) => {
      if (val.role === "RESPO") {
        options.push({
          value: val.id,
          label: `
              ${val.last_name} 
              ${val.first_name}
              `,
        });
      }
    });
    return options;
  }

  const handleSelectResponsable = (e) => {
    setResponsable(e);
  };

  const ListResponsableFromModule = (id) => {
    axiosClient.get();
  };

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 1196px)");
  const [message, setMessage] = useState("");

  const [listModule, setListModule] = useState([]);
  //
  const [titre, setTitre] = useState(location.state.titre || "");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState(
    location.state.description || ""
  );
  const [type_Diplome, setType_Diplome] = useState(
    location.state.diplome || ""
  );
  const [date_Validite, setDate_Validite] = useState();
  const [signateur, setSignateur] = useState(location.state.signateur || "");
  const [postSignateur, setpostSignateur] = useState(
    location.state.postSignateur || ""
  );

  const [genreSignateur, setGenreSignateur] = useState("");

  const [type_Validite, setType_Validite] = useState();
  const [periode, setPeriode] = useState();

  const [selectedImage, setSelectedImage] = useState(null);

  const date = new Date();
  date.setDate(date.getDate());
  const [edited_by, setedited_by] = useState(
    ``
  );
  const [edited_at, setedited_at] = useState(date);

  const [modification, setmodification] = useState([]);
  const [newListModule, setNewListModule] = useState([]);

  useEffect(() => {
    axiosClient
      .get(`/module/?search=${location.state.idFormation}`)
      .then((res) => {
        setListModule(res.data.results);
        setNewListModule(res.data.results);
      });

    axiosClient.get(`/module/${location.state.idModule}/`).then((res) => {
      setmodification(res.data.modification);
      setGenreSignateur(res.data.genreSignateur);
      setType_Validite(res.data.type_validite.toString());
      setDate_Validite(res.data.type_validite.toString());
      setPeriode(res.data.periode);
    });

    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const getDiplomeForModule = () => {
    listModule.map((val) => {
      if (val.id === location.state.idModule) {
        {
          setSelectedDiplome({ value: val.diplome, label: val.diplome });
        }
      }
    });
  };

  const toast = useToast();
  const navigate = useNavigate();
  const EditModule = async (title, desc, dipl, sign, postSign, genreSign, prd) => {
    setLoading(true)
    if (
      !titre ||
      !description ||
      !signateur ||
      !postSignateur ||
      (type_Validite === "true" && (!periode || periode < 1)) ||
      titre.trim() === "" ||
      description.trim() === "" ||
      signateur.trim() === "" ||
      postSignateur.trim() === ""
    ) {
      window.scrollTo(0, 0);
      setMessage("Veuillez remplir les champs!");
      setLoading(false)
      return;
    }

    const check = await axiosClient.get(`check_exist/?check=ModuleEdit&value=${titre}&idFormation=${location.state.idFormation}&idModule=${location.state.idModule}`);
    if (check.data.exists) {
      //   setIsLoading(false);
      window.scrollTo(0, 0);
      setMessage("Ce Module existe déjà dans cette formation !");
      return;
    }

    let respos = [];

    //modification log
    const mod = modification;

    mod.push({
      edited_by: `${user.first_name} ${user.last_name}`,
      edited_at: edited_at,
      field: {
        etat: null,
        titre: titre !== title ? titre : null,
        description: description != desc ? description : null,
        image: selectedImage ? true : false,
        diplome: type_Diplome !== dipl ? type_Diplome : null,
        responsable: JSON.stringify(responsable) !== JSON.stringify(getListRespoForModule()) ? responsable : [],
        signateur: signateur !== sign ? signateur : null,
        postSignateur: postSignateur !== postSign ? postSignateur : null,
        genreSignateur: genreSignateur !== genreSign ? genreSignateur : null,
        type_validite:
          type_Validite !== date_Validite
            ? type_Validite === "false"
              ? false
              : true
            : null,
        periode:
          type_Validite !== date_Validite && type_Validite === "false"
            ? 0
            : type_Validite !== date_Validite &&
              type_Validite === "true" &&
              periode !== prd
              ? parseInt(periode)
              : type_Validite === date_Validite &&
                type_Validite === "true" &&
                periode !== prd
                ? parseInt(periode)
                : null,
      },
    });

    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("description", description);
    if (selectedImage) formData.append("image", selectedImage);
    for (let index = 0; index < responsable.length; index++) {
      formData.append("responsable", responsable[index].value);
      respos.push(responsable[index].value);
    }
    if (type_Diplome) {
      formData.append("diplome", type_Diplome);
    }
    formData.append("signateur", signateur);
    formData.append("genreSignateur", genreSignateur);
    formData.append("type_validite", type_Validite);
    formData.append(
      "periode",
      type_Validite === "false" ? 0 : parseInt(periode)
    );

    if (
      titre !== title ||
      description !== desc ||
      type_Diplome !== dipl ||
      signateur !== sign ||
      postSignateur !== postSign ||
      genreSignateur !== genreSign ||
      postSignateur !== postSign ||
      type_Validite !== date_Validite ||
      periode !== prd ||
      selectedImage ||
      responsable.length > 0
    ) {
      formData.append("modification", JSON.stringify(mod));
    }

    axiosClient
      .patch(`/module/${location.state.idModule}/`, formData)
      .then(() => {
        if (
          titre !== title ||
          description !== desc ||
          type_Diplome !== dipl ||
          signateur !== sign ||
          postSignateur !== postSign ||
          genreSignateur !== genreSign ||
          postSignateur !== postSign ||
          type_Validite !== date_Validite ||
          periode !== prd ||
          selectedImage ||
          responsable.length > 0
        ) {
          toast({
            description: `le module ${titre} est modifiée avec succès`,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "bottom-right",
          });
          setLoading(false)
        }

        if (user.role === "RESPO") {
          navigate(-1, {
            state: {
              idFormation: location.state.idFormation,
              titreF: location.state.titreFormation,
              idRespo: user.id,
            },
          });
        } else {
          navigate("/list_allModules", {
            state: {
              idFormation: location.state.idFormation,
              titreF: location.state.titreFormation,
            },
          });
        }
      });
  };

  const [messageDate_Validite, setMessageDate_Validite] = useState(false);

  const colorbg = useColorModeValue("white", "#2d3748");
  const txtcolor = useColorModeValue("black", "white");
  const colorbghover = useColorModeValue("#e2e8f0", "#171923");

  return (
    <Fragment>
      <Box mb={5} w="90%">
        <Heading
          bgGradient="linear(to-l,  #ffd140, #089bd7)"
          bgClip="text"
          fontSize={{ base: "2xl", sm: "3xl" }}
        >
          Modifier Module
        </Heading>
      </Box>
      <Box
        w={isNotSmallerScreen ? "100%" : "100%"}
        bg={useColorModeValue("white", "gray.700")}
        borderRadius="lg"
        m={{ sm: 2, md: 2, lg: 2 }}
        p={{ sm: 4, md: 4, lg: 4 }}
      >
        <Box p={4} rounded="xl" align="center" justify="center">
          {listModule.map((val, key) => {
            if (val.id === location.state.idModule) {
              return (
                <VStack spacing={3} w={{ base: "100%", lg: "90%" }}>
                  {message && (
                    <Alert status="error" rounded="md">
                      <AlertIcon />
                      {message}
                    </Alert>
                  )}
                  <Flex direction={{ base: 'column', lg: 'row' }} align={"center"} w={"100%"} gap={10}>
                    <Avatar
                      mb={2}
                      size="2xl"
                      src={!selectedImage ? val.image : image}
                      cursor="pointer"
                      position="relative"
                    >
                      <AvatarBadge
                        borderColor="papayawhip"
                        bg="tomato"
                        boxSize="1.25em"
                      >
                        <TbCameraRotate size={20} color="black" />
                      </AvatarBadge>
                      <InputGroup
                        borderColor="#E0E1E7"
                        position="absolute"
                        top="0"
                        left={0}
                      >
                        <Input
                          opacity="0"
                          cursor="pointer"
                          type="file"
                          h={105}
                          name="file"
                          accept="image/*"
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                      </InputGroup>
                    </Avatar>
                    <VStack w="100%">

                      <Stack
                        w="100%"
                        mt={3}
                        direction={{ base: "column", md: "row", lg: "row" }}
                      >
                        <FormControl
                          id="name"
                          isRequired
                          mr={{ base: 0, md: 2, lg: 2 }}
                        >
                          <FormLabel>Titre Module</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <Input
                              maxLength={50}
                              type="text"
                              height="37px"
                              name="titre"
                              defaultValue={val.titre}
                              onChange={(e) => setTitre(e.target.value)}
                              placeholder="Titre Module"
                            />
                          </InputGroup>
                          <Flex justify="end">
                            {titre.length > 40 ? (
                              <Text fontSize="xs" color="red">
                                {titre.length}/50 caractères !
                              </Text>
                            ) : (
                              <Text fontSize="xs">
                                {titre.length}/50{" "}
                                {titre.length > 1 ? "caractères" : "caractère"}
                              </Text>
                            )}
                          </Flex>
                        </FormControl>
                        <FormControl id="responsable" isRequired ml={{ base: 0, md: 2, lg: 2 }}>
                          <FormLabel>Responsable</FormLabel>
                          <Select
                            placeholder="Selectionner les responsables..."
                            components={animatedComponents}
                            options={dataResponsable()}
                            defaultValue={getListRespoForModule()}
                            onChange={handleSelectResponsable}
                            isSearchable
                            noOptionsMessage={() => "Responsable introuvable"}
                            isMulti
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                textAlign: "left",
                                backgroundColor: colorbg,
                                color: txtcolor,
                              }),
                              menu: (provided) => ({
                                ...provided,
                                textAlign: "left",
                                backgroundColor: colorbg,
                                cursor: "pointer",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: txtcolor,
                                backgroundColor: state.isSelected
                                  ? colorbg
                                  : "inherit",
                                "&:hover": {
                                  backgroundColor: colorbghover,
                                },
                                cursor: "pointer",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: txtcolor,
                              }),
                            }}
                          />
                        </FormControl>
                      </Stack>
                      <Stack
                        w="100%"
                        direction={{ base: "column", md: "row", lg: "row" }}
                        spacing={{ base: 0, md: 4, lg: 4 }}
                      >
                        <FormControl
                          mr={{ base: 0, md: 2, lg: 2 }}
                          id="signateur"
                          isRequired
                        >
                          <FormLabel>Signataire</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <Input
                              maxLength={50}
                              type="text"
                              size="md"
                              name="signateur"
                              defaultValue={val.signateur}
                              onChange={(e) => setSignateur(e.target.value)}
                              placeholder="Signataire"
                            />
                          </InputGroup>
                          <Flex justify="end">
                            {signateur.length > 40 ? (
                              <Text fontSize="xs" color="red">
                                {signateur.length}/50 caractères !
                              </Text>
                            ) : (
                              <Text fontSize="xs">
                                {signateur.length}/50{" "}
                                {signateur.length > 1 ? "caractères" : "caractère"}
                              </Text>
                            )}
                          </Flex>
                        </FormControl>
                        <FormControl
                          ml={{ base: 0, md: 2, lg: 2 }}
                          id="postSignateur"
                          isRequired
                        >
                          <FormLabel>Post Signataire</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <Input
                              maxLength={50}
                              type="text"
                              size="md"
                              name="postSignateur"
                              defaultValue={val.postSignateur}
                              onChange={(e) => setpostSignateur(e.target.value)}
                              placeholder="Post Signataire"
                            />
                          </InputGroup>
                          <Flex justify="end">
                            {signateur.length > 40 ? (
                              <Text fontSize="xs" color="red">
                                {signateur.length}/50 caractères !
                              </Text>
                            ) : (
                              <Text fontSize="xs">
                                {signateur.length}/50{" "}
                                {signateur.length > 1 ? "caractères" : "caractère"}
                              </Text>
                            )}
                          </Flex>
                        </FormControl>
                      </Stack>
                    </VStack>
                  </Flex>


                  {/* <Divider /> */}


                  <FormControl id="genreSignateur" isRequired>
                    <FormLabel>Genre Signataire</FormLabel>

                    <RadioGroup
                      name="type_Diplome"
                      onChange={setGenreSignateur}
                      value={genreSignateur}
                    >
                      <Flex>
                        <Radio
                          mr={5}
                          size="md"
                          value="Femme"
                          onChange={(e) => setGenreSignateur(e.target.value)}
                          colorScheme="green"
                        >
                          Femme
                        </Radio>
                        <Radio
                          size="md"
                          value="Homme"
                          onChange={(e) => setGenreSignateur(e.target.value)}
                          colorScheme="green"
                        >
                          Homme
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  </FormControl>

                  <Stack
                    w="100%"
                    direction={{ base: "column", md: "row", lg: "row" }}
                    spacing={{ base: 0, md: 5, lg: 5 }}
                  >
                    <FormControl
                      id="type_validite"
                      w={{ base: "100%", md: "50%", lg: "50%" }}
                      isRequired
                      mr={{ base: 0, md: 2, lg: 2 }}
                    >
                      <FormLabel>Type de validité</FormLabel>

                      <MySelect
                        value={type_Validite}
                        onChange={(e) => setType_Validite(e.target.value)}
                      >
                        <option value={true}>Par mois</option>
                        <option value={false}>À vie</option>
                      </MySelect>
                    </FormControl>
                    {type_Validite === "true" ? (
                      <FormControl

                        w={{ base: "100%", md: "50%", lg: "50%" }}
                        id="validite_certificat"
                        isRequired
                        ml={{ base: 0, md: 2, lg: 2 }}
                      >
                        <FormLabel>Date de validité</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <Input
                            type="number"
                            pattern="[0-9]"
                            maxLength={3}
                            size="md"
                            name="validite_certificat"
                            defaultValue={val.periode}
                            onChange={(e) => {
                              if (e.target.value.length > 3) {
                                setMessageDate_Validite(true);
                              } else {
                                setMessageDate_Validite(false);
                              }
                              setPeriode(e.target.value);
                            }}
                            placeholder="Date de validité"
                          />
                          <InputRightAddon children="Mois" />
                        </InputGroup>
                        {periode < 0 ? (
                          <Flex justify="space-between">
                            <div>
                              <Text
                                fontSize="xs"
                                fontWeight="semibold"
                                color="red"
                              >
                                Entrez un nombre positif
                              </Text>
                            </div>
                            <div>
                              <>
                                {periode?.length ? (
                                  <Text fontSize="xs" color="red">
                                    {periode?.length}/3 caractères !
                                  </Text>
                                ) : (
                                  <Text fontSize="xs">
                                    {periode?.length}/3{" "}
                                    {periode?.length > 1
                                      ? "caractères"
                                      : "caractère"}
                                  </Text>
                                )}
                              </>
                            </div>
                          </Flex>
                        ) : (
                          <Flex justify="space-between">
                            <div>
                              {messageDate_Validite && (
                                <Text
                                  fontSize="xs"
                                  fontWeight="semibold"
                                  color="red"
                                >
                                  Il ne faut pas dépasser 3 chiffres
                                </Text>
                              )}
                            </div>
                            <div>
                              <>
                                {periode?.length ? (
                                  <Text fontSize="xs" color="red">
                                    {periode?.length}/3 caractères !
                                  </Text>
                                ) : (
                                  <Text fontSize="xs">
                                    {periode?.length}/3{" "}
                                    {periode?.length > 1
                                      ? "caractères"
                                      : "caractère"}
                                  </Text>
                                )}
                              </>
                            </div>
                          </Flex>
                        )}
                      </FormControl>
                    ) : <FormControl
                      visibility={false}
                      w={{ base: "100%", md: "50%", lg: "50%" }}
                      id="validite_certificat"
                      isRequired
                      ml={{ base: 0, md: 2, lg: 2 }}
                    ></FormControl>}
                  </Stack>
                  <FormControl id="diplome" isRequired>
                    <FormLabel>Diplôme</FormLabel>
                    <FormControl mt="3" id="type" isRequired>
                      <RadioGroup
                        name="type_Diplome"
                        onChange={setType_Diplome}
                        value={type_Diplome ? type_Diplome : val.diplome}
                      >
                        <Flex>
                          <Radio
                            mr={5}
                            size="md"
                            value="Attestation"
                            onChange={(e) => setType_Diplome(e.target.value)}
                            colorScheme="green"
                          >
                            Attestation
                          </Radio>
                          <Radio
                            size="md"
                            value="Certificat"
                            onChange={(e) => setType_Diplome(e.target.value)}
                            colorScheme="green"
                          >
                            Certificat
                          </Radio>
                        </Flex>
                      </RadioGroup>
                    </FormControl>
                  </FormControl>

                  <FormControl id="description" isRequired>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      maxLength={250}
                      borderColor="gray.300"
                      name="description"
                      defaultValue={val.description}
                      onChange={(e) => setDescription(e.target.value)}
                      _hover={{
                        borderRadius: "gray.300",
                      }}
                      placeholder="Description"
                    />
                    <Flex justify="end">
                      {description.length > 240 ? (
                        <Text fontSize="xs" color="red">
                          {description.length}/250 caractères !
                        </Text>
                      ) : (
                        <Text fontSize="xs">
                          {description.length}/250{" "}
                          {description.length > 1 ? "caractères" : "caractère"}
                        </Text>
                      )}
                    </Flex>
                  </FormControl>

                  <FormControl
                    id="name"
                    float="right"
                    display="flex"
                    justifyContent="end"
                  >
                    <Button
                      variant="solid"
                      colorScheme="red"
                      title="Veuiller remplir tout les champs"
                      mr={2}
                      onClick={() => {
                        navigate(-1)
                      }}
                    >
                      Annuler
                    </Button>
                    {titre === "" ||
                      description === "" ||
                      messageDate_Validite ||
                      !signateur ||
                      !postSignateur ||
                      responsable.length === 0 ||
                      (type_Validite === 'true' && (!periode || periode < 1))
                      ? (
                        <Button
                          variant="solid"
                          bg="#ffd140"
                          color="black"
                          _hover="none"
                          isDisabled
                          title="Veuillez remplir les champs"
                        >
                          Modifier
                        </Button>
                      ) : (
                        <Button
                        isLoading={loading}
                          variant="solid"
                          bg="#ffd140"
                          color="black"
                          _hover="none"
                          onClick={() => {
                            EditModule(
                              val.titre,
                              val.description,
                              val.diplome,
                              val.signateur,
                              val.postSignateur,
                              val.genreSignateur,
                              val.periode
                            );
                          }}
                        >
                          Modifier
                        </Button>
                      )}
                  </FormControl>
                </VStack>
              );
            }
          })}
        </Box>
      </Box>

      <Modal
        closeOnOverlayClick={!selectedImage ? true : false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modification de l'image</ModalHeader>
          <ModalBody pb={6}>
            <FormControl
              id="image"
              isRequired
              display="flex"
              justifyContent="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                height="200px"
                w="80%"
                border={!selectedImage ? "1px dashed" : null}
                position="relative"
                borderColor="#2b6cb0"
                cursor="pointer"
              >
                {!selectedImage && (
                  <>
                    <BsImageFill size="70px" color="#2b6cb0" />
                    <Text
                      fontSize={{ base: "md", md: "lg", lg: "lg" }}
                      fontWeight="semibold"
                      color="#2b6cb0"
                    >
                      Parcourir l'image à télécharger
                    </Text>
                  </>
                )}
                {image && selectedImage && (
                  <Box textAlign="center">
                    <Image
                      src={image}
                      w="full"
                      h="auto"
                      maxH="200px"
                      objectFit="contain"
                      alt="Dan Abramov"
                      rounded="lg"
                    />
                  </Box>
                )}
              </Flex>
              <InputGroup
                borderColor="#E0E1E7"
                position="absolute"
                top="0"
                left={10}
              >
                <Input
                  opacity="0"
                  cursor="pointer"
                  type="file"
                  height="200px"
                  w="80%"
                  size="lg"
                  name="file"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                  placeholder="Titre de Formation"
                  accept="image/jpg, image/png, image/jpeg, image/svg, image/tif"
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          {selectedImage && (
            <ModalFooter>
              <Button
                onClick={() => {
                  setSelectedImage(null);
                  onClose();
                }}
                colorScheme="red"
                mr={3}
              >
                <FaTimes />
              </Button>
              <Button
                onClick={onClose}
                colorScheme="whatsapp"
                isDisabled={!selectedImage}
              >
                <AiOutlineCheck />
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Fragment>
  );
}

export default EditModule;