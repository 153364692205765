import { SearchIcon } from "@chakra-ui/icons";

import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
  Spacer,
} from "@chakra-ui/react";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { GrCertificate } from "react-icons/gr";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import MyPagination from "../../components/MyPagination";
import html2pdf from "html2pdf.js";
import Certificat from "../certificat_resultat/Certificat";
import { MdHistory } from "react-icons/md";

function GestionDemandeDiplome() {
  // current user
  const { user, setUser ,fetchCounts} = useStateContext();
  const [listDemandeDiplome, setListDemandeDiplome] = useState([]);
  const [selectedEtat, setSelectedEtat] = useState("nouvelle");
  const [id, setid] = useState();
  const [nom, setnom] = useState("");
  const [prenom, setprenom] = useState("");
  const [typeD, settypeD] = useState("");
  const [idUser, setIdUser] = useState("");
  const [idModule, setIdModule] = useState("");
  const [search, setSearch] = useState("");

  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0);

  // Pagination variables
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  //date d'aujourd'hui
  const date = new Date();
  date.setDate(date.getDate());
  // const formattedDate = date.toISOString().slice(0, 10);

  // convertion du format de date
  function dateFormat(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString();
    const formattedDate = `${day}/${month}/${year}`;
    // Output: "31/03/2023"
    return formattedDate;
  }

  function getData() {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });
    if (user.role === "RESPO") {
      axiosClient
        .get(
          `/demandeDiplome/getDemandDiplomeByResponsable/?idResponsable=${user.id}`
        )
        .then((res) => {
          setListDemandeDiplome(res.data);
        });
    } else {
      axiosClient.get(`/demandeDiplome/getAllDemandDiplomes/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&etat=${selectedEtat}`)
        .then((response) => {

          let filteredData = response?.data?.results ? response?.data?.results : [] || [];
          setPaginationData(response.data);

          if (filteredData.length === 0) {
            setCheckLengthData(true)
          }
          else {
            setListDemandeDiplome(filteredData);
            setCheckLengthData(false)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
          setLoading(false)
        });
    }
  }


  function generateRandomText() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#*_';
    let randomText = '';

    for (let i = 0; i < 15; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomText += characters[randomIndex];
    }

    return randomText;
  }


  useEffect(() => {
    getData();
  }, [user.id, pageSize, currentPage, search, selectedEtat]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const cancelRef = React.useRef();
  const [isAlertOpen, setIsAlertOpen] = useState(false);


  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };


  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };


  const AcceptDemandeDiplome = (
    id,
    idR,
    typeD,
    nom,
    prenom,
    key,
    signateur
  ) => {
    axiosClient
      .patch(`/demandeDiplome/${id}/`, {
        accorder: true,
        rejetter: false,
        dateOperation: date,
        agent: user.first_name + " " + user.last_name,
      })
      .then(() => {
        setListDemandeDiplome((prevData) =>
          prevData.filter((row) => row.idDemande !== id)
        );

        handleCertificat(id, idR, typeD, nom, key, signateur);

        onClose();
        fetchCounts()
        toast({
          description:
            typeD === "Attestation"
              ? `L'attestation pour ${nom} ${prenom} est créée avec succès`
              : `Le certificat pour ${nom} ${prenom} est créé avec succès`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });

        //reload data
        getData();
      });
  };

  const RefusDemandeDiplome = (id, typeD, nom, prenom) => {
    axiosClient
      .patch(`/demandeDiplome/${id}/`, {
        accorder: false,
        rejetter: true,
        dateOperation: date,
        agent: user.first_name + " " + user.last_name,
      })
      .then(() => {
        setListDemandeDiplome((prevData) =>
          prevData.filter((row) => row.idDemande !== id)
        );
        toast({
          description:
            typeD === "Attestation"
              ? `La création de l'attestation pour l'apprenant ${nom} ${prenom} a été refusée`
              : `La création du certificat pour l'apprenant ${nom} ${prenom} a été refusée`,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        fetchCounts()
        //reload data
        getData();
      });
  };


  //recommencer le module
  const recommencer = (id, typeD, nom, prenom, idUser, idModule) => {
    console.log("essayyyyyyyyyyy ", idUser, idModule);
    axiosClient
      .get(`/demandeDiplome/restartModule_and_progress/?idUser=${idUser}&idModule=${idModule}`)
      //changer l'etat de la demande
      .then(() => {
        axiosClient
          .patch(`/demandeDiplome/${id}/`, {
            accorder: false,
            rejetter: true,
            recommencer_module: true,
            dateOperation: date,
            agent: user.first_name + " " + user.last_name,
          })
          .then(() => {
            setListDemandeDiplome((prevData) =>
              prevData.filter((row) => row.idDemande !== id)
            );
            fetchCounts()
            toast({
              description:
                typeD === "Attestation"
                  ? `La création de l'attestation pour l'apprenant ${nom} ${prenom} a été refusée et l'apprenant doit obligatoirement recommencer le module`
                  : `La création du certificat pour l'apprenant ${nom} ${prenom} a été refusée  et l'apprenant doit obligatoirement recommencer le module`,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "bottom-right",
            });

            //reload data
            getData();
          });
      })
  };


  const certificatRef = useRef([]);

  //ajout du certificat dans la base de données
  async function handleCertificat(
    id,
    refResultat,
    last_name,
    typeDiplome,
    key,
    signateur
  ) {
    try {
      // exportToPDF(certificatRef);
      const pdfBlob = await html2pdf()
        .from(certificatRef.current)
        .outputPdf("blob");
      const formData = new FormData();
      formData.append("idResultat", refResultat); // assuming certificatId is the ID of the certificate you want to associate the PDF with
      formData.append(
        "certificat_file",
        pdfBlob, generateRandomText() + `${id}` + generateRandomText() + ".pdf"
      );
      await axiosClient.post("/certificat/", formData);
    } catch (error) {
      console.log(error);
    }
  }
  function exportToPDF(ref) {
    const options = {
      margin: 0,
      filename: "certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" }, // Changed unit to 'pt'
    };
    html2pdf().set(options).from(ref.current).save();
  }
  const [myKey, setMykey] = useState();
  const [listVal, setListVal] = useState([]);

  return (
    <Fragment>
      <Box>
        <Flex justifyContent="right">
          <Select
            fontSize={{ base: 13, lg: 16 }}
            w={{ base: "30%", lg: "20%" }}
            mr={5}
            mb={4}
            value={selectedEtat}
            onChange={(e) =>
            {
              setCurrentPage(0)
              setSelectedEtat(
                e.target.value === ""
                  ? null
                  : e.target.value === "nouvelle"
                    ? "nouvelle"
                    : e.target.value === "accorder"
                      ? "accorder"
                      : "rejetter"
              )
            }
              
            }
          >
            <option value="">Toutes</option>
            <option value="nouvelle">Nouvelles demandes</option>
            <option value="accorder">Acceptées</option>
            <option value="rejetter">Refusées</option>
          </Select>
          <InputGroup w="30%">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              type="tel"
              placeholder="Recherche..."
              onChange={(e) => {
                setCurrentPage(0)
                setSearch(e.target.value)}}
            />
          </InputGroup>
        </Flex>

        {(paginationData?.length !== 0) &&
          listDemandeDiplome.map((val, key) => {
            if (val.accorder === false && val.rejetter === false) {
              return (
                <Stack
                  key={key}
                  h="auto"
                  borderRadius="lg"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  border="1px solid rgba(0, 0, 0, 0.25)"
                  p={4}
                  mb={3}
                >
                  <Flex
                    align={"center"}
                    direction={{ base: "column", lg: "row" }}
                    justifyContent={"space-between"}
                  >
                    <Box flexGrow={1} display="flex">
                      <Icon as={GrCertificate} h="32px" w="32px" mr="10px" />
                      <Box ml="30px">
                        L'apprenant{" "}
                        <b>{`${val.first_name} ${val.last_name} `}</b>
                        {/* {listApprenant.map((v, k) => {
                                            if (v.id === val.apprenant) {
            
                                                return <b>{` ${v.first_name}  ${v.last_name} `}</b>
            
                                            }
                                        })} */}
                        demande{" "}
                        {val.typeDiplome === "Attestation" ? (
                          <b>l'attestation</b>
                        ) : (
                          <b>le certificat</b>
                        )}{" "}
                        du module <b>{`${val.titre_module} `}</b>
                        {/* {listModule.map((v, k) => {
                                            if (v.id === val.module) {
                                                return <b>{` ${v.titre} `}</b>
                                            }
                                        })} */}
                        avec une note obtenue de <b>{`${val.note}% `}</b>
                        au test du niveau <b>{`${val.niveau} `}</b>
                      </Box>
                    </Box>
                    <Flex
                    ml={2}
                      mt={{ base: 2, lg: 0 }}
                      align={"center"}
                      justify={"center"}
                      gap={2}
                    >
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          // AcceptDemandeDiplome(val.idDemande, val.idResultat, val.typeDiplome, val.last_name, val.first_name, key)
                          onOpen();
                          setMykey(key);
                          setListVal(val);
                        }}
                      >
                        Accepter
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          setid(val.idDemande);
                          setnom(val.last_name);
                          setprenom(val.first_name);
                          settypeD(val.typeDiplome);
                          setIdUser(val.idUser);
                          setIdModule(val.idModule);
                          onOpenAlert();
                        }}
                      >
                        Refuser
                      </Button>


                    </Flex>
                  </Flex>
                </Stack>
              );
            }

            //demande accordées
            else if (val.accorder === true && val.rejetter === false) {
              return (
                <Stack
                  key={key}
                  h="auto"
                  borderRadius="lg"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  border="1px solid rgba(0, 0, 0, 0.25)"
                  p={4}
                  mb={3}
                >
                  <Flex
                    align={"center"}
                    direction={{ base: "column", lg: "row" }}
                    justifyContent={"space-between"}
                  >
                    <Box flexGrow={1} display="flex">
                      <Icon as={GrCertificate} h="32px" w="32px" mr="10px" />
                      <Box ml="30px">
                        L'apprenant{" "}
                        <b>{`${val.first_name} ${val.last_name} `}</b>

                        demande{" "}
                        {val.typeDiplome === "Attestation" ? (
                          <b>l'attestation</b>
                        ) : (
                          <b>le certificat</b>
                        )}{" "}
                        du module <b>{`${val.titre_module} `}</b>

                        avec une note obtenue de <b>{`${val.note}% `}</b>
                        au test du niveau <b>{`${val.niveau} `}</b>
                      </Box>
                    </Box>
                    <Flex
                      mt={{ base: 2, lg: 0 }}
                      align={"center"}
                      justify={"center"}
                    >
                      <Button pointerEvents={'none'} colorScheme="blue">Demande déja approuvée</Button>

                      {(user.role === "SUPERADMIN" ||
                        user.role === "RESPO" ||
                        user.role === "MASTER") && (
                          <Popover placement="left">
                            <PopoverTrigger>
                              <IconButton
                                // size="sm"
                                ml="5px"
                                bg="gray.300"
                                color={"black"}
                                icon={<MdHistory />}
                              ></IconButton>
                            </PopoverTrigger>
                            <PopoverContent
                              color="black"
                              bgGradient="linear(to-r, gray.300, blue.200)"
                            >
                              <PopoverHeader
                                letterSpacing={1.5}
                                pt={4}
                                fontWeight="bold"
                                border="0"
                              >
                                Historique
                              </PopoverHeader>
                              <PopoverArrow bg="blue.200" />
                              <PopoverCloseButton />
                              <PopoverBody>
                                <div style={{ marginBottom: "8px" }}>
                                  Accordée par : {val.agent}
                                </div>

                                <div>
                                  Date de dernière modification :{" "}
                                  {dateFormat(val.dateOperation)}
                                </div>
                              </PopoverBody>
                              <PopoverHeader
                                pt={4}
                                fontWeight="bold"
                                border="0"
                              ></PopoverHeader>
                            </PopoverContent>
                          </Popover>
                        )}
                    </Flex>
                  </Flex>
                </Stack>
              );
            }

            //demande diplomes rejetter
            else if (val.accorder === false && val.rejetter === true) {
              return (
                <Stack
                  key={key}
                  h="auto"
                  borderRadius="lg"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  border="1px solid rgba(0, 0, 0, 0.25)"
                  p={4}
                  mb={3}
                >
                  <Flex
                    align={"center"}
                    direction={{ base: "column", lg: "row" }}
                    justifyContent={"space-between"}
                  >
                    <Box flexGrow={1} display="flex">
                      <Icon as={GrCertificate} h="32px" w="32px" mr="10px" />
                      <Box ml="30px">
                        L'apprenant{" "}
                        <b>{`${val.first_name} ${val.last_name} `}</b>
                        {/* {listApprenant.map((v, k) => {
                                            if (v.id === val.apprenant) {
            
                                                return <b>{` ${v.first_name}  ${v.last_name} `}</b>
            
                                            }
                                        })} */}
                        demande{" "}
                        {val.typeDiplome === "Attestation" ? (
                          <b>l'attestation</b>
                        ) : (
                          <b>le certificat</b>
                        )}{" "}
                        du module <b>{`${val.titre_module} `}</b>

                        avec une note obtenue de <b>{`${val.note}% `}</b>
                        au test du niveau <b>{`${val.niveau} `}</b>
                      </Box>
                    </Box>
                    <Flex
                      mt={{ base: 2, lg: 0 }}
                      align={"center"}
                      justify={"center"}
                    >
                      {!val.recommencer_module ? <Button
                        colorScheme="teal"
                        onClick={() => {
                          // AcceptDemandeDiplome(val.idDemande, val.idResultat, val.typeDiplome, val.last_name, val.first_name, key)
                          onOpen();
                          setMykey(key);
                          setListVal(val);
                        }}
                      >
                        Créer le diplôme
                      </Button> : <Button colorScheme="red">Doit recommencer le module</Button>}

                      {(user.role === "SUPERADMIN" ||
                        user.role === "RESPO" ||
                        user.role === "MASTER") && (
                          <Popover placement="left">
                            <PopoverTrigger>
                              <IconButton
                                // size="sm"
                                ml="5px"
                                bg="gray.300"
                                color={"black"}
                                icon={<MdHistory />}
                              ></IconButton>
                            </PopoverTrigger>
                            <PopoverContent
                              color="black"
                              bgGradient="linear(to-r, gray.300, blue.200)"
                            >
                              <PopoverHeader
                                letterSpacing={1.5}
                                pt={4}
                                fontWeight="bold"
                                border="0"
                              >
                                Historique
                              </PopoverHeader>
                              <PopoverArrow bg="blue.200" />
                              <PopoverCloseButton />
                              <PopoverBody>
                                <div style={{ marginBottom: "8px" }}>
                                  Rejettée par : {val.agent}
                                </div>

                                <div>
                                  Date de dernière modification :{" "}
                                  {dateFormat(val.dateOperation)}
                                </div>
                              </PopoverBody>
                              <PopoverHeader
                                pt={4}
                                fontWeight="bold"
                                border="0"
                              ></PopoverHeader>
                            </PopoverContent>
                          </Popover>
                        )}
                    </Flex>
                  </Flex>
                </Stack>
              );
            }
          })}
        {(((search || selectedEtat) && paginationData?.length === 0) || (checkLengthData)) && (
          <Text>Aucune ligne correspondante n'a été trouvée.</Text>
        )}
        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              paginationData={paginationData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              PAGE_SIZE={pageSize}
            />
          </Box>

          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Box>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Refus de création
            </AlertDialogHeader>
            <AlertDialogBody>
              êtes-vous sûr de vouloir rejetter la creation de ce diplome?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  RefusDemandeDiplome(id, typeD, nom, prenom);
                  onCloseAlert();
                }}
                colorScheme="blue"
                ml={3}
              >
                Refuser
              </Button>

              <Button
                onClick={() => {
                  recommencer(id, typeD, nom, prenom, idUser, idModule);
                  onCloseAlert();
                }}
                colorScheme="red"
                ml={3}
              >
                Recommencer le module
              </Button>

            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Modal Diplôme */}
      <Modal
        size={"full"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent bg={"whiteAlpha.300"}>
          <ModalHeader ></ModalHeader>
          <ModalCloseButton />
          <ModalBody position='relative'>
            <Box position="absolute" top={'50%'} left={'50%'} transform={'translate(-50%,-48%) rotate(270deg)'}>
              <Certificat Data={listVal} ref={certificatRef} />
            </Box>
            {/* <Certificat Data={listVal} ref={certificatRef} /> */}
          </ModalBody>
          <ModalFooter pt={50}>
            <Button
              colorScheme={
                listVal.typeDiplome === "Certificat" ? "yellow" : "facebook"
              }
              onClick={() => {
                AcceptDemandeDiplome(
                  listVal.idDemande,
                  listVal.idResultat,
                  listVal.typeDiplome,
                  listVal.last_name,
                  listVal.first_name,
                  myKey,
                  listVal.signateur
                );
              }}
            >
              Créer le diplôme
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
}
export default GestionDemandeDiplome;
