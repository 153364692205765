import {
  Box,
  Heading,
  Center,
  Text,
  Flex,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import cachet from "../../assets/img/cachet.png";
import cadre_certificat from "../../assets/img/certificat.png";
import cadre_attestation from "../../assets/img/attestation.png";
import './style.css';

const Certificat = React.forwardRef((props, ref) => {
  const date = new Date();
  date.setDate(date.getDate());
  const formattedDate = date.toISOString().slice(0, 10);

  // convertion du format de date
  function dateFormat(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString();
    const formattedDate = `${day}/${month}/${year}`;
    // Output: "31/03/2023"
    return formattedDate;
  }

  return props.Data.typeDiplome === "Certificat" ? (
    <Center w="100%" h="100%" ref={ref}>
      <Center
        bg={`url(${cadre_certificat})`}
        // bgSize="cover"
        bgSize="100% 100% "
        bgPos="center"
        w="100%"
        h="100%"
        color="black"
        width="500pt"
        height="841pt"
      >
        <Box mr={-70} transform="rotate(90deg)">
          <Center>
          <Text letterSpacing={2} fontFamily="Libre Baskerville"  fontSize={"4xl"}>
              CERTIFICAT DE REUSSITE
            </Text>
          </Center>
          <br />
          <Center>
          <Text fontFamily="Radley" fontStyle={"italic"} fontSize={"2xl"}>
              Ce certificat certifie que
            </Text>
          </Center>
          <br />
          <Center>
          <Text fontFamily="Pinyon Script" fontSize={"5xl"}>
              {props.Data.first_name} {props.Data.last_name}
            </Text>
          </Center>
          <Center>
          <Text fontFamily="Radley" fontSize={"2xl"}>
              a validé avec succés le module
            </Text>
          </Center>
          <Center>
          <Text fontFamily="Libre Baskerville" fontWeight={"bold"} fontSize={"3xl"}>
              "{props.Data.titre_module}"
            </Text>
          </Center>
          <br/>
          <Center>
          <Text fontFamily="Radley" fontSize={"2xl"}>
              Certificat valide {props.Data.validite === "True" ? <span style={{ fontWeight: "bold" }}>
                jusqu'au: {props.Data.dateValiditeDiplome}
              </span> : " à vie"}
            </Text>
          </Center>
          <br />
          <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Flex   alignItems={"center"} justifyContent={"center"}>
              <Text fontSize={"xl"}>Date:</Text>
              
              <Text ml={3} fontSize={"lg"} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {dateFormat(props.Data.date_de_passage_de_test)}
              </Text>
            </Flex>   
            
            <Flex alignItems={"center"}>
            <Text fontSize={"xl"}>Signature :</Text>
              <Box ml={3}>
                <Box fontSize={15} style={{ fontWeight: "bold" }}>
                  {props.Data.genreSignateur === "Homme" ? "Mr" : "Mme"}  {props.Data.signateur}
                </Box>
                <Text
                  style={{ textDecoration: "underline" }}
                  fontFamily="Radley"
                  fontStyle={"italic"}
                  // fontSize="xs"
                  fontSize={15}
                >
                  {props.Data.postSignateur}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Center>
    </Center>
  ) : (
    <Center w="100%" h="100%" ref={ref}>
      <Center
        bg={`url(${cadre_attestation})`}
        // bgSize="cover"
        bgSize="100% 100% "
        bgPos="center"
        w="100%"
        h="100%"
        color="black"
        width="500pt"
        height="841pt"
      >
        <Box mr={-70} transform="rotate(90deg)">
          <Center>
            <Text letterSpacing={2} fontFamily="Libre Baskerville"  fontSize={"4xl"}>
              ATTESTATION DE REUSSITE
            </Text>
          </Center>
          <br />
          <Center>
            <Text fontFamily="Radley" fontStyle={"italic"} fontSize={"2xl"}>
              L'entreprise PAIPERLECK atteste que
            </Text>
          </Center>
          <br />
          <Center>
            <Text fontFamily="Pinyon Script" fontSize={"5xl"}>
              {props.Data.first_name} {props.Data.last_name}
            </Text>
          </Center>
          <Center>
            <Text fontFamily="Radley" fontSize={"2xl"}>
              a obtenue cette attestation prouvant sa reussite à l'examen du module
            </Text>
          </Center>
          <Center>
            <Text fontFamily="Libre Baskerville" fontWeight={"bold"} fontSize={"3xl"}>
              "{props.Data.titre_module}"
            </Text>
          </Center>
          <br />
          <Center>
            <Text fontFamily="Radley" fontSize={"2xl"}>
              Attestation valide {props.Data.validite === "True" ? <span style={{ fontWeight: "bold" }}>
                jusqu'au: {props.Data.dateValiditeDiplome}
              </span> : " à vie"}
            </Text>
          </Center>
          <br />
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex   alignItems={"center"} justifyContent={"center"}>
              <Text fontSize={"xl"}>Date:</Text>
              
              <Text ml={3} fontSize={"lg"} style={{ textDecoration: "underline", fontWeight: "bold" }}>
                {dateFormat(props.Data.date_de_passage_de_test)}
              </Text>
            </Flex>           
            <Flex alignItems={"center"} >
              <Text fontSize={"xl"}>Signature :</Text>
              <Box ml={3}>
                <Box fontSize={15} style={{ fontWeight: "bold" }}>
                  {props.Data.genreSignateur === "Homme" ? "Mr" : "Mme"} {props.Data.signateur}
                </Box>
                <Text
                  style={{ textDecoration: "underline" }}
                  fontFamily="Radley"
                  fontStyle={"italic"}
                  fontSize={15}
                >
                  {props.Data.postSignateur}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Center>
    </Center>
  );
});
export default Certificat;