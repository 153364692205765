import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Icon,
  Select,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import axiosClient from '../../axios-client';
import { FaBook, FaMailBulk, FaStar, FaSwatchbook, FaUserCog, FaUserGraduate, FaUserTie } from 'react-icons/fa';
import DonutChart from '../../components/charts/DonutChart';
import BarChart from '../../components/charts/BarChart';
import { RiAdminFill } from "react-icons/ri";
import { GiBookPile, GiOpenBook } from "react-icons/gi";
import { GrCertificate } from 'react-icons/gr';
import { TbCertificate } from 'react-icons/tb';

// Start StatCard component
function StatsCard(props) {
  const { title, stat, icon } = props;

  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'3'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue("gray.300","gray.600")}
      bg={useColorModeValue('gray.50', 'gray.800')}
      position="relative" // Allow positioning of child elements
      rounded={'lg'}
      overflow="hidden"
    >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 1 }}>
          <StatLabel fontSize={'17px'} fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'3xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
      </Flex>

      {/* Icon pinned to the bottom right */}
      <Box
        position="absolute"
        bottom={-2}
        right={-2}
        opacity={0.3}

        color={useColorModeValue('gray.300', 'gray.600')} // Adjust the color
      >
        {icon}
      </Box>
    </Stat>

  );
}
// End StatCard component

export default function Dashboard() {

  const [apprenant, setApprenant] = useState(0)
  const [admin, setAdmin] = useState(0)
  const [respo, setRespo] = useState(0)
  const [formation, setFormation] = useState(0)
  const [module, setModule] = useState(0)
  const [plainte, setPlainte] = useState(0)
  const [defaultDonut, setDefaultDonut] = useState('years')
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [successRateStats, setSuccessRateStats] = useState({
    students_succeeded: 0,
    total_unique_students: 0,
    avg_result: 0,
    success_rate: 0
  });

  // cerfificats chart data
  const [certificatChartData, setCertificatChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
    },
  });

  // plaintes par jours chart data
  const [plainteByDayChartData, setPlainteByDayChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
    },
  });

  // plaintes par mois chart data
  const [plainteByMonthChartData, setPlainteByMonthChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
    },
  });

  // plaintes par année chart data
  const [plainteByYearChartData, setPlainteByYearChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
    },
  });
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setDefaultDonut('year')
    if (event.target.value === "0") {
      setDefaultDonut('years')
      setSelectedYear(currentYear)
    }
  };

  const generateYearOptions = () => {
    const yearOptions = [];
    for (let year = 2022; year <= currentYear; year++) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return yearOptions;
  };
  // apprenants par year chart data
  const [apprenantByYearChartData, setApprenantByYearChartData] = useState({
    options: {
      plotOptions: {
        bar: {
          distributed: true,
        }
      },
      chart: {
        type: "bar"
      },
      xaxis: {
        categories: []
      }
    },

    series: [
      {
        data: []
      }
    ]
  });


  // plaintes par categorie chart data
  const [plainteCategoryChartData, setPlainteCategoryChartData] = useState({
    options: {
      plotOptions: {
        bar: {
          distributed: true,
        }
      },
      chart: {
        type: "bar"
      },
      xaxis: {
        categories: []
      }
    },

    series: [
      {
        data: []
      }
    ]
  });


  // count stats
  const fetchCountStats = () => {
    axiosClient.get('dash_count/')
      .then((response) => {
        setApprenant(response.data.student_count);
        setAdmin(response.data.admin_count);
        setRespo(response.data.respo_count);
        setFormation(response.data.formation_count);
        setModule(response.data.module_count);
        setPlainte(response.data.ticket_count);

      })
      .catch((error) => {
        console.log(error);
      });
  }
  const fetchSuccessRate = () => {
    axiosClient.get('exam_stats/')
      .then((response) => {
        setSuccessRateStats(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [formations, setFormations] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState('');
  const [selectedCount, setSelectedCount] = useState('');

  const [certificat, setCertificat] = useState([]);


  // afficher le nombre de module par formation
  const fetchModulesParFormation = () => {
    axiosClient.get('module/formation_count/')
      .then((response) => {
        const data = response.data;
        setFormations(data);

        if (data.length > 0) {
          setSelectedFormation(data[0].formation__titre);
          setSelectedCount(data[0].count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSelectChange = (e) => {
    const selectedFormationId = e.target.value;

    if (selectedFormationId === '') {
      setSelectedFormation('');
      setSelectedCount('');
    } else {
      const selected = formations.find(formation => formation.formation__titre === selectedFormationId);
      if (selected) {
        setSelectedFormation(selected.formation__titre);
        setSelectedCount(selected.count);
      }
    }
  };

  // afficher le nombre de module par formation
  // const fetchCertificatParModule = () => {
  //   axiosClient.get('certificat/module_count/')
  //     .then((response) => {
  //       const data = response.data;
  //       setCertificat(data);

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const fetchCertificatParModule = () => {
    axiosClient.get('certificat/module_count/')
      .then((response) => {
        const data = response.data;
        // Group data by formation title
        const groupedData = data.reduce((acc, curr) => {
          const formationTitle = curr.idResultat__idTest__idModule__formation__titre;
          const moduleTitle = curr.idResultat__idTest__idModule__titre;
          const moduleCount = curr.module_count;

          if (!acc[formationTitle]) {
            acc[formationTitle] = [];
          }

          acc[formationTitle].push({
            moduleTitle: moduleTitle,
            moduleCount: moduleCount
          });

          return acc;
        }, {});

        setCertificat(groupedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedModule, setSelectedModule] = useState("");
  const [selectedCountCert, setSelectedCountCert] = useState(0);

  const handleSelectChangeCert = (event) => {
    const selectedModuleTitle = event.target.value;
    const moduleCount = event.target.options[event.target.selectedIndex].getAttribute('data-count');

    setSelectedModule(selectedModuleTitle);
    setSelectedCountCert(moduleCount);
  };



  // afficher le nombre de plainte par jours
  const fetchPlainteParJours = () => {
    // Helper function to get the day name based on day index (Sunday is 0, Monday is 1, etc.)
    const getDayName = (dayIndex) => {
      const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Venderedi', 'Samedi'];
      return daysOfWeek[dayIndex - 1];
    };
    axiosClient.get('plainte/plaintes_per_day/')
      .then((response) => {
        const data = response.data;

        const count = data.map(item => item.count);
        const days = data.map(item => {
          const dayIndex = item.day_of_week;
          const dayName = getDayName(dayIndex);
          return dayName;
        });
        setPlainteByDayChartData({
          series: count,
          options: {
            chart: {
              type: 'donut',
            },
            labels: days,
            legend: { show: false }
          },
        });

      })
      .catch((error) => {
        console.log(error);
      });
  }

  // afficher le nombre de plainte par mois
  const fetchPlainteParMois = () => {

    // Helper function to get the month name based on month index (January is 0, February is 1, etc.)
    const getMonthName = (monthIndex) => {
      const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
      return monthNames[monthIndex];
    };

    axiosClient.get(`plainte/plaintes_per_month/?year=${selectedYear}`)
      .then((response) => {
        const data = response.data;
        const count = data.map(item => item.count);
        const month = data.map(item => {
          const monthIndex = item.month ? new Date(item.month).getMonth() : -1;
          const monthName = getMonthName(monthIndex);
          return monthName;
        });
        setPlainteByMonthChartData({
          series: count,
          options: {
            chart: {
              type: 'donut',
            },
            labels: month,
            legend: { show: false }
          },
        });

      })
      .catch((error) => {
        console.log(error);
      });
  }

  // afficher le nombre de plainte par jours
  const fetchPlainteParAnnee = () => {
    axiosClient.get('plainte/plaintes_per_year/')
      .then((response) => {
        const data = response.data;

        const years = data.map(item => item.year);
        const counts = data.map(item => item.count);
        setPlainteByYearChartData({
          series: counts,
          options: {
            chart: {
              type: 'donut',
            },
            labels: years,
            legend: { show: false }
          },
        });

      })
      .catch((error) => {
        console.log(error);
      });
  }

  // afficher le nombre des apprenants par année
  const fetchApprenantsParYear = () => {
    axiosClient.get('apprenants/apprenant_count_by_year/')
      .then((response) => {
        const count = response.data.apprenants_by_year.map(item => item.count);
        const years = response.data.apprenants_by_year.map(item => item.year);
        setApprenantByYearChartData({
          options: {
            chart: {
              type: "bar"
            },
            xaxis: {
              categories: years,
              labels: {
                show: false
              }
            }
          },
          series: [
            {
              data: count
            }
          ]
        });

      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [selectedMonth, setSelectedMonth] = useState('');

  const months = [
    { name: 'Janvier', value: 1 },
    { name: 'Février', value: 2 },
    { name: 'Mars', value: 3 },
    { name: 'Avril', value: 4 },
    { name: 'Mai', value: 5 },
    { name: 'Juin', value: 6 },
    { name: 'Juillet', value: 7 },
    { name: 'Août', value: 8 },
    { name: 'Septembre', value: 9 },
    { name: 'Octobre', value: 10 },
    { name: 'Novembre', value: 11 },
    { name: 'Décembre', value: 12 },
  ];

  // afficher le nombre des plaintes par catégorie
  const fetchPlaintesParCategorie = (month) => {
    axiosClient.get('plainte/plaintes_per_month_category/')
      .then((response) => {
        const data = response.data;
        let filteredData = data;

        if (month && month !== '0') {
          filteredData = data.filter(item => {
            const itemMonth = new Date(item.month).getMonth() + 1;
            return itemMonth === Number(month);
          });
        }

        const categories = filteredData.map(item => item.category);
        const counts = filteredData.map(item => item.count);

        const categoryTranslations = {
          technical_support: 'Support technique',
          account_assistance: 'Assistance pour le compte',
          course_inquiries: 'Demandes de renseignements sur les cours',
          feedback_suggestions: 'Commentaires et suggestions',
          content_issues: 'Problèmes de contenu',
          general_inquiries: 'Demandes générales',
        };

        // Translate the categories to French using the mapping
        const translatedCategories = categories.map(category => categoryTranslations[category]);


        setPlainteCategoryChartData({
          options: {
            chart: {
              type: "bar"
            },
            xaxis: {
              categories: translatedCategories,
              labels: {
                show: false
              }
            }
          },
          series: [
            {
              data: counts
            }
          ]
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    fetchPlaintesParCategorie(selectedMonth);
  }, [selectedMonth]);


  useEffect(() => {
    fetchCountStats()
    fetchModulesParFormation()
    fetchCertificatParModule()
    fetchApprenantsParYear()
    fetchPlainteParJours()
    fetchPlainteParMois()
    fetchPlainteParAnnee()
    fetchSuccessRate()
  }, [selectedYear]);

  return (
    <Box mx={'auto'} pt={5} px={{ base: 2, sm: 3, md: 17 }}>
      {/* <Text fontSize={30} mb={2}>Statistiques</Text> */}
      <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 6 }} spacing={{ base: 5, sm: 2, lg: 3 }}>
        <StatsCard
          title={'Administrateurs'}
          stat={admin}
          icon={
            <Icon as={FaUserTie} fontSize={60} color="green.500" />
          }
        />
        <StatsCard
          title={'Responsables'}
          stat={respo}
          icon={
            <Icon as={RiAdminFill} fontSize={60} color="cyan.500" />
          }
        />
        <StatsCard
          title={'Apprenants'}
          stat={apprenant}
          icon={
            <Icon as={FaUserGraduate} fontSize={60} color="blue.500" />
          }
        />
        <StatsCard
          title={'Formations'}
          stat={formation}
          icon={
            <Icon as={FaBook} fontSize={60} color="yellow.500" />
          }
        />
        <StatsCard
          title={'Modules'}
          stat={module}
          icon={
            <Icon as={FaSwatchbook} fontSize={60} color="red.500" />
          }
        />
        <StatsCard
          title={'Tickets'}
          stat={plainte}
          icon={
            <Icon as={FaMailBulk} fontSize={60} color="purple.500" />
          }
        />
      </SimpleGrid>


      <Flex
        pt={10}
        gap={5}
        w={"100%"}
        flexDirection={{ base: "column", xl: "row" }}
      >

        {/* Referral Tracking */}
        <Card shadow={'xl'} rounded={'lg'} bg={useColorModeValue('gray.50', 'gray.800')} w={{ base: "100%", xl: "50%" }} p="16px" >
          <Flex direction='column' p={5}>
            <Flex justify='space-between' align='center' mb='20px'>
              <Text fontSize='lg' fontWeight='bold'>
                Statistiques des tickets
              </Text>

              <Select rounded={'lg'} size={'sm'} width={150} defaultValue={selectedYear} onChange={handleYearChange}>
                <option value="0">	toutes les années</option>
                {generateYearOptions()}

              </Select>
            </Flex>
            <Flex h='100%' w={"100%"} direction={{ sm: 'row', md: "column", xl: 'row' }} justify={'center'} alignItems={'center'}>

              {plainteByYearChartData.series.length === 0 ?
                <Flex flexDir={"column"} align={"center"} justify={"center"} h={'100%'}>
                  <Text>Rien de disponible pour l’instant.</Text>
                  {/* <Image opacity={0.5} src={emptystats} w={"150px"} /> */}
                </Flex> :

                <>
                  {defaultDonut === "year" &&
                    <>
                      <Box   >
                        <DonutChart title={"Tickets par année"} data={plainteByYearChartData} />
                      </Box>
                      <Box >
                        <DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
                      </Box>
                    </>
                  }
                  {defaultDonut === "years" &&
                    <>
                      <Box >
                        <DonutChart title={"Tickets par mois"} data={plainteByYearChartData} />
                      </Box>
                      <Box >
                        <DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
                      </Box>
                    </>

                  }</>
              }

            </Flex>
          </Flex>
        </Card>
        <Card shadow={'xl'} rounded={'lg'} bg={useColorModeValue('gray.50', 'gray.800')} w={{ base: "100%", xl: "50%" }} p="16px" >
          <Flex direction='column' p={5}>
            <Flex justify='space-between' align='center' >
              <Text fontSize='lg' fontWeight='bold'>
                Tickets par catégorie
              </Text>

              {/* <Select rounded={'lg'} size={'sm'} width={150} defaultValue={selectedYear} onChange={handleYearChange}>
                <option value="0">	toutes les années</option>
                {generateYearOptions()}

              </Select> */}
            </Flex>
            <Flex h='100%' w={"100%"} direction={{ sm: 'row', md: "column", xl: 'row' }} justify={'center'} alignItems={'center'}>

              {/* <Box> */}
              <BarChart title={"Tickets par catégorie"} data={plainteCategoryChartData} />

              {/* </Box> */}

            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Flex
        py={10}
        gap={5}
        w={"100%"}
        flexDirection={{ base: "column", xl: "row" }}
      >
        <Flex
          w={{ base: "100%", xl: "50%" }}
          direction={'column'}
          gap={5}
          height={'auto'}
        >
          <Card height={'auto'} shadow={'xl'} rounded={'lg'} bg={useColorModeValue('gray.50', 'gray.800')} w={'100%'} p="16px" >
            <Flex direction='column' px={5}>

              <Flex pb={5} justify='space-between' align='center' >
                <Text fontSize='lg' fontWeight='bold'>
                  Modules par formarion
                </Text>

              </Flex>
              <Flex direction={{ base: "column", md: "row" }} alignItems={"center"} mb={3} gap={10}>
                <Select width={{ base: "100%", md: "50%" }} onChange={handleSelectChange} value={selectedFormation}>
                  <option value="">--Selectinez une formation--</option>
                  {formations.map((item, index) => (
                    <option key={index} value={item.formation__titre}>
                      {item.formation__titre}
                    </option>
                  ))}
                </Select>
                {selectedFormation && (<Text> Nombre de Modules : <Tag size={"sm"} colorScheme='blue' style={{ fontSize: "18px" }}>  <TagLabel>{selectedCount}</TagLabel>  <TagRightIcon as={GiOpenBook} /></Tag> </Text>)}

              </Flex>
            </Flex>


          </Card>
          <Card height={'auto'} shadow={'xl'} rounded={'lg'} bg={useColorModeValue('gray.50', 'gray.800')} w={"100%"} p="16px" >
            <Flex direction='column' px={5}>

              <Flex pb={5} justify='space-between' align='center' >
                <Text fontSize='lg' fontWeight='bold'>
                  Certificats par module
                </Text>

              </Flex>
              <Flex direction={{ base: "column", md: "row" }} alignItems={"center"} mb={3} gap={10}>
                <Select
                  width={{ base: "100%", md: "50%" }}
                  onChange={handleSelectChangeCert}
                  value={selectedModule}
                >
                  <option value="">-- Sélectionnez un module --</option>
                  {/* Loop over formations to group modules */}
                  {Object.keys(certificat).map((formationTitle, index) => (
                    <optgroup label={formationTitle} key={index}>
                      {certificat[formationTitle].map((module, idx) => (
                        <option key={idx} value={module.moduleTitle} data-count={module.moduleCount}>
                          {module.moduleTitle}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Select>
                
                {selectedModule && (
                  <Text>
                    Nombre de certificats : 
                    <Tag size={"sm"} colorScheme='orange' style={{ fontSize: "18px" }}>
                      <TagLabel>{selectedCountCert}</TagLabel>
                      <TagRightIcon as={TbCertificate} />
                    </Tag>
                  </Text>
                )}
              </Flex>

            </Flex>


          </Card>
        </Flex>

        <Card shadow={'xl'} rounded={'lg'} bg={useColorModeValue('gray.50', 'gray.800')} w={{ base: "100%", xl: "50%" }} p="16px" >
          <Flex direction='column' px={5}>

            <Flex mb={3} justify='space-between' align='center' >
              <Text fontSize='lg' fontWeight='bold'>
                Taux de réussite
              </Text>

            </Flex>
            <Flex w={"100%"} alignItems={"center"} direction={{ base: 'column', xl: 'row' }} px={5}>
              <Flex direction='column' me={{ md: '6px', lg: '30px' }} mb={{ sm: '16px', md: '0px' }}>
                <Flex
                  direction='column'
                  p='15px'
                  pe={{ sm: '22e', md: '8px', lg: '22px' }}
                  minW={{ sm: '220px', md: '140px', lg: '220px' }}
                  bg={useColorModeValue("linear-gradient(126.97deg, #fffae8 28.26%, rgb(183 234 255) 91.2%)","linear-gradient(126.97deg, #22262c 28.26%, rgb(0 16 23) 91.2%)")}
                  borderRadius='20px'
                  shadow={"xl"}
                  mb='20px'>
                  <Text fontSize='sm' mb='4px'>
                  Etudiants ayant réussi
                  </Text>
                  <Text fontSize='lg' fontWeight='bold'>
                  {successRateStats?.students_succeeded} Etudiants / {successRateStats?.total_unique_students}
                  </Text>
                </Flex>
                <Flex
                  direction='column'
                  p='15px'
                  shadow={"xl"}
                  pe={{ sm: '22px', md: '8px', lg: '22px' }}
                  minW={{ sm: '170px', md: '140px', lg: '170px' }}
                  bg={useColorModeValue("linear-gradient(126.97deg, #fffae8 28.26%, rgb(183 234 255) 91.2%)","linear-gradient(126.97deg, #22262c 28.26%, rgb(0 16 23) 91.2%)")}
                  borderRadius='20px'>
                  <Text fontSize='sm' mb='4px'>
                    Moyenne générale
                  </Text>
                  <Text fontSize='lg' fontWeight='bold'>
                  {successRateStats?.avg_result?.toFixed(2) || 0}
                  </Text>
                  {/* <HStack spacing={"2px"}>
                  {[...Array(5)].map((star, index) => {

                    return (
                      <Box
                        as="label"
                        key={index}
                        // color={index < avgStars(parseInt(selectedP)) ? "#ffc107" : "#e4e5e9"}
                        color={"e4e5e9"}
                      >
                        <FaStar
                          cursor={"pointer"}
                          size={20}
                          transition="color 200ms"
                        />
                      </Box>
                    );
                  })}
                </HStack> */}

                </Flex>
              </Flex>


              <Flex justify={'center'} w={"100%"} mx={{ sm: 'auto', md: '0px' }}>
                <CircularProgress
                  size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
                  value={successRateStats?.success_rate}
                  thickness={6}
                  color={successRateStats?.success_rate > '70' ? "#05CD99" : successRateStats?.success_rate > 40 ? "#ffd83a" : "#ff6767"}
                  // color={"05CD99"}
                  variant='vision'>
                  <CircularProgressLabel>
                    <Flex direction='column' justify='center' align='center'>
                      <Text color='gray.400' fontSize='sm'>
                        Taux
                      </Text>
                      <Text

                        fontSize={{ md: '36px', lg: '35px' }}
                        fontWeight='bold'
                        mb='4px'>
                       {successRateStats?.success_rate?.toFixed(2) || 0}%
                      </Text>

                    </Flex>
                  </CircularProgressLabel>
                </CircularProgress>
              </Flex>
            </Flex>

          </Flex>
        </Card>
      </Flex>


      {/* <Box
        mt={8}
        display={{ md: "flex" }}
        flexWrap={{ md: "wrap" }}
        justifyContent="space-between"
      >
        <Box width={{ base: "100%", md: "45%" }}>
          <DonutChart title={"Modules par formarion"} data={formationChartData} />
        </Box>
        <Box width={{ base: "100%", md: "45%" }}>
          <DonutChart title={"Certificats par module"} data={certificatChartData} />
        </Box>
      </Box> */}


      {/* <Box mt={8}
        display={{ md: "flex" }}
        flexWrap={{ md: "wrap" }}
        justifyContent="space-between"
      >
        <Box width={{ base: "100%", md: "33%" }}>
          <DonutChart title={"Tickets par jours"} data={plainteByDayChartData} />
        </Box>
        <Box width={{ base: "100%", md: "33%" }}>
          <DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
        </Box>
        <Box width={{ base: "100%", md: "33%" }}>
          <DonutChart title={"Tickets par année"} data={plainteByYearChartData} />
        </Box>

      </Box> */}
      {/* <Flex justifyContent="flex-end">
        <Select width={150} value={selectedMonth} onChange={(event) => setSelectedMonth(event.target.value)}>
          <option value="0">Tous les mois</option>
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {month.name}
            </option>
          ))}
        </Select>
      </Flex> */}
      {/* Apprenant par annee chart */}
      {/* <Box
        display={{ md: "flex" }}
        flexWrap={{ md: "wrap" }}
        justifyContent="space-between">
        <Box width={{ base: "100%", md: "45%" }}>
          <BarChart title={"Apprenants par année"} data={apprenantByYearChartData} />
        </Box>

        <Box width={{ base: "100%", md: "45%" }}>

          <BarChart title={"Tickets par catégorie"} data={plainteCategoryChartData} />
        </Box>

      </Box> */}

    </Box>



  );
}