import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import axiosClient from "../axios-client";
const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => { },
  setToken: () => { },
  setIsLoading: () => { },
  test: [],
  settest: () => { },
  postdata: () => { },

});

export const ContextProvider = ({ children }) => {

  const [listDemandeAccesModule, setListDemandeAccesModule] = useState()
  const [listReacticationAccesModule, setListReacticationAccesModule] = useState()
  const [listDemandeDiplome, setlistDemandeDiplome] = useState()
  const [listDemandeInscription, setlistDemandeInscription] = useState()
  const [totalRequestsCount, setTotalRequestsCount] = useState(0);

  const [counta, setCounta] = useState(0); // Admin Tickets count
  const [countr, setCountr] = useState(0); // Respo Tickets count
  const [countm, setCountm] = useState(0); // Master Tickets count
  const [countapprenant, setCountapprenant] = useState(0);
  const [totalTicketsCount, setTotalTicketsCount] = useState(0);

  const [user, setUser] = useState({});
  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [refresh, _setRefresh] = useState(
    localStorage.getItem("REFRESH_TOKEN")
  );

  const [isLoading, _setIsLoading] = useState(false);
  const [test, settest] = useState([]);

  const fetchCounts = async () => {
    try {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          axiosClient.get(`get-requests-counts/${data.id}/${data.role}/`)
          .then((response)=>{
            setListDemandeAccesModule(response.data.listDemandeAccesModule);
            setListReacticationAccesModule(response.data.listReacticationAccesModule);
            setlistDemandeDiplome(response.data.listDemandeDiplome);
            setlistDemandeInscription(response.data.listDemandeInscription);
            setTotalRequestsCount(response.data.totalCount);
          })
          .catch((error) => {
            console.log(error);
          });
        })
        .catch((err) => {
          console.log(err);
        });

    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };

  const fetchCountTickets = async () => {
    try {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          axiosClient.get(`get-tickets-counts/${data.id}/${data.role}/`)
          .then((response)=>{
            if (response.data) {
              setCounta(response.data.counta);
              setCountr(response.data.countr);
              setCountm(response.data.countm);
              setCountapprenant(response.data.countapprenant);
              setTotalTicketsCount(response.data.totalCount)
            }
      
          })
          .catch((error) => {
            console.log(error);
          });
        })
        .catch((err) => {
          console.log(err);
        });

    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  
  useEffect(() => {
    if (token) {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          setUser(data);
          
        })
        .catch((err) => {
          console.log(err);
        });
        fetchCounts()
        fetchCountTickets()
    }
  }, [token]);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
    }
  };


  const setRefresh = (refresh) => {
    _setRefresh(refresh);
    if (refresh) {
      localStorage.setItem("REFRESH_TOKEN", refresh);
    } else {
      localStorage.removeItem("REFRESH_TOKEN");
    }
  };


  const setIsLoading = (v) => {
    _setIsLoading(v);
  };


  const postdata = async (formdata) => {
    const postest = await axiosClient.post("/test/", formdata);
    settest(postest.data);
    window.location.reload();

  };

  return (
    <StateContext.Provider
      value={{
        postdata,
        user,
        setUser,
        token,
        setToken,
        refresh,
        setRefresh,
        setIsLoading,
        isLoading,
        fetchCounts,
        fetchCountTickets,
        listDemandeAccesModule,
        listReacticationAccesModule,
        listDemandeDiplome,
        listDemandeInscription,
        totalRequestsCount,
        counta,
        countr,
        countm,
        countapprenant,
        totalTicketsCount
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
