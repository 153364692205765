import {
  Box,
  Thead,
  Table,
  Flex,
  Tbody,
  Spacer,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  HStack,
  Center,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useColorModeValue,
  Select,
  Text,
  TagLabel,
  Tag,
  Skeleton,
  InputLeftElement,
} from "@chakra-ui/react";

import { FaFolderOpen, FaMedal } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { CheckIcon, CloseIcon, Search2Icon, SearchIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import { format } from "date-fns";
import axiosClient from "../../axios-client";
import MyPagination from "../../components/MyPagination";

function ListeCertificatAdmin() {
  const [globalResult, setGlobalResult] = useState([]);
  const [resultats, setResultats] = useState([]);
  const [certificats, setCertificats] = useState([]);
  const [score, setScore] = useState("");
  const [typeDip, setTypeDip] = useState("");
  const [loading, setLoading] = useState(true)
  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const [date1, setDate1] = useState("")
  const [date2, setDate2] = useState("")
  const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  const mode = useColorModeValue("black", "white")
  useEffect(() => {
    axiosClient.get(`/certificat/getAllCertificats/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchValue}&score=${score || ""}&typeDiplome=${typeDip}&date1=${date1}&date2=${date2}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setCertificats(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [pageSize, currentPage, searchValue, score, typeDip, date1, date2])
  // useEffect(() => {
  //   axiosClient
  //     .get("/certificat/")
  //     .then(({ data }) => {
  //       data.map((dat, index) => {
  //         //convertion de date en format "yyyy-mm-dd"
  //         const date = new Date(dat.date_obtention);
  //         date.setDate(date.getDate());
  //         const formattedDate = date.toISOString().slice(0, 10);
  //         data[index].date_obtention = formattedDate;
  //       });
  //       const sortedData = data.sort().reverse();
  //       setGlobalResult(sortedData);
  //       setResultats(sortedData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);



  //Filtrer les résultats en fonction de la propriété "tentative"
  const handleScore = (variable) => {
    if (variable === "") {
      setScore(variable);
    } else {
      variable = parseInt(variable);
      setScore(variable);
    }
  };

  //filtre date
  const handleDate1 = (dateString) => {
    setCurrentPage(0)
    setDate1(dateString);
  };

  const handleDate2 = (dateString) => {
    setCurrentPage(0)
    setDate2(dateString);
  };


  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleDownloadClick = (certification) => {
    window.open(certification, "_blank");
  };

  return (
    <>
      <Box w="100%" p={4} color={mode} borderRadius="xl">
        <Heading
          textAlign={"left"}
          fontSize={{ base: "2xl", sm: "3xl" }}
          fontWeight="bold"
          bgGradient="linear(to-l, #ffd140, #089bd7)"
          bgClip="text"
        >
          Liste des diplômes
        </Heading>
        <Stack
          w={"full"}
          maxW="full"
          bg={useColorModeValue("white", "gray.700")}
          rounded={"lg"}
          p={6}
          my={5}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            direction={{ base: "column", lg: "row" }}
            w={"100%"}
          >
            <InputGroup mt={{ base: 0, lg: 7 }}
              mb={{ base: 3, lg: 0 }} w={{ base: "100%", md: "40%", lg: "30%" }}>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                type="text"
                placeholder="Recherche..."
                onChange={(e) => {
                  setCurrentPage(0)
                  setSearchValue(e.target.value)}}
              />
            </InputGroup>

            <Spacer />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={{ base: "column", lg: "row" }}
              w={{ base: "100%", lg: "50%" }}
              textAlign={"center"}
              fontSize={{ base: 9, md: 13 }}
              flex={25}
            >
              <Box w={"100%"} mr={{ base: 0, lg: 5 }}>
                <Text
                  fontWeight={"bold"}
                  mb={3}
                  color={useColorModeValue("black", "gray.300")}
                >
                  Score superieur à:{" "}
                </Text>

                <form>
                  <Input
                    mb={{ base: 3, lg: 0 }}
                    color={useColorModeValue("black", "gray.300")}
                    type="number"
                    min="0"
                    max="100"
                    onChange={(e) => {
                      setCurrentPage(0)
                      let value = parseInt(e.target.value, 10);
                      if (value > 100) {
                        value = 100;
                      } else if (value < 0) {
                        value = 0;
                      }

                      e.target.value = value;
                      handleScore(value); 
                    }}
                  />
                </form>
              </Box>
              <Spacer />
              <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
                <Text
                  fontWeight={"bold"}
                  mb={3}
                  color={useColorModeValue("black", "gray.300")}
                >
                  {" "}
                  Type Diplôme:
                </Text>
                <Select
                  mb={{ base: 3, lg: 0 }}
                  color={useColorModeValue("black", "gray.300")}
                  onChange={(e) => {setCurrentPage(0)
                    setTypeDip(e.target.value)}}
                >
                  <option value="">Tous</option>
                  <option value="Attestation">Attestation</option>
                  <option value="Certificat">Certificat</option>
                </Select>
              </Box>
              <Spacer />
              <Box w={"100%"} mr={{ base: 0, lg: 5 }}>
                <Text
                  fontWeight={"bold"}
                  mb={3}
                  color={useColorModeValue("black", "gray.300")}
                >
                  Après le:
                </Text>
                <form onChange={(e) => handleDate1(e.target.value)}>
                  <Input
                    mb={{ base: 3, lg: 0 }}
                    color={useColorModeValue("black", "gray.300")}
                    type="date"
                  />
                </form>
              </Box>
              <Spacer />
              <Box w={"100%"}>
                <Text
                  fontWeight={"bold"}
                  color={useColorModeValue("black", "gray.300")}
                  mb={3}
                >
                  Avant le:
                </Text>
                <form onChange={(e) => handleDate2(e.target.value)}>
                  <Input
                    color={useColorModeValue("black", "gray.300")}
                    type="date"
                  />
                </form>
              </Box>
            </Flex>
          </Flex>
          <br />
          <TableContainer borderRadius="lg">
            <Table  alignItems={"center"}>
              <Thead>
                <Tr>
                  <Th>Formation</Th>
                  <Th>Module</Th>
                  <Th>Email</Th>
                  <Th>Type du diplôme</Th>
                  <Th>Resultat</Th>
                  <Th>Date d'obtention</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>

              <Tbody>
                {loading &&
                  <Tr>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>
                    <Td> <Skeleton height='20px' /></Td>

                  </Tr>}
                {(paginationData?.length !== 0) &&
                  certificats.map((val, key) => (
                    <Tr key={key}>
                      <Td>{val.formation}</Td>
                      <Td>{val.module}</Td>
                      <Td>{val.username}</Td>
                      <Td>
                        {val.typeDiplome === "Certificat" ? (
                          <Tag size="lg" colorScheme="teal" borderRadius="full">
                            <FaMedal />
                            <TagLabel ml={2}>{val.typeDiplome}</TagLabel>
                          </Tag>
                        ) : (
                          <Tag
                            size="lg"
                            colorScheme="messenger"
                            borderRadius="full"
                          >
                            <GrCertificate />
                            <TagLabel ml={2}>{val.typeDiplome}</TagLabel>
                          </Tag>
                        )}
                      </Td>
                      <Td>{val.resultat}%</Td>
                      <Td>{new Date(val.date_obtention).toLocaleDateString()}</Td>
                      <Td color={"#1EB14C"}>
                        {" "}
                        <HStack>
                          <button
                            onClick={() =>
                              handleDownloadClick(val.certificat_file)
                            }
                          >
                            <DownloadIcon />
                          </button>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}

                {(((searchValue || typeDip || score || date1 || date2) && paginationData?.length === 0) || (checkLengthData)) && (
                  <Tr>
                    <Td colSpan={7}>
                      Aucune ligne correspondante n'a été trouvée.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
            <Box flex="1">
              <MyPagination
                paginationData={paginationData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                PAGE_SIZE={pageSize}
              />
            </Box>

            <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
              {/* Add more options as needed */}
            </Select>
          </Flex>
        </Stack>
      </Box>
    </>
  );
}

export default ListeCertificatAdmin;
