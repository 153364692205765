import { useState, useEffect } from "react";
import axiosClient from "../../../axios-client";
import Select from 'react-select'; // React Select for searchable dropdown
import { Flex, useColorModeValue, CircularProgress, CircularProgressLabel, Text } from "@chakra-ui/react";

function Moyenne() {
  const [count, setCount] = useState([]);
  const [ready, setReady] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  // Fetch the module data from API
  useEffect(() => {
    axiosClient
      .get("/resultat/get_module_average_results/")
      .then(({ data }) => {
        setCount(data.module_results);

        // Set default to the first module when data is fetched
        if (data.module_results.length > 0) {
          setSelectedModule(data.module_results[0]); // set the first module as default
        }
        setReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Handle selection change
  const handleSelectChange = (selectedOption) => {
    const selectedModuleName = selectedOption.value;
    setSelectedModule(count.find((module) => module.module === selectedModuleName));
  };

  // Format the data for React Select
  const moduleOptions = count.map((module) => ({
    value: module.module,
    label: module.module
  }));
  
  // Colors and styles
  const colorbg = useColorModeValue('white', 'gray.600');
  const txtcolor = useColorModeValue('black', 'white');
  const colorbghover = useColorModeValue('#e2e8f0', '#171923');

  // Set CircularProgress bar color based on average_result
  const getProgressColor = (result) => {
    if (result > 70) return "#05CD99";
    if (result > 40) return "#ffd83a";
    return "#ff1b1b";
  };

  return (
    <Flex flexDir={"column"} style={{ width: "100%", height: "100%" }}>
      <Text fontWeight={'bold'} fontSize={"13px"} mb={2}>Pourcentage de réussite</Text>
      {ready && (
        <>
          {/* React Select dropdown with search feature */}
          <Select
            options={moduleOptions}
            onChange={handleSelectChange}
            defaultValue={moduleOptions[0]}
            placeholder="Sélectionnez un module"
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                width: "60%",
                textAlign: 'left',
                backgroundColor: colorbg,
                color: txtcolor,
              }),
              menu: (provided) => ({
                ...provided,
                textAlign: 'left',
                backgroundColor: colorbg,
                cursor: "pointer",
              }),
              option: (provided, state) => ({
                ...provided,
                color: txtcolor,
                backgroundColor: state.isSelected ? colorbg : 'inherit',
                '&:hover': {
                  backgroundColor: colorbghover,
                },
                cursor: "pointer",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: txtcolor,
              }),
            }}
          />

          {selectedModule && (
            <Flex justify={'center'} w={"100%"} mx={{ sm: 'auto', md: '0px' }} mt={5}>
              <CircularProgress
                size={{base:"150px",sm:"200px",md:"150px" , lg:"200px",xl:'250px'}}
                value={selectedModule.average_result}
                thickness={6}
                color={getProgressColor(selectedModule.average_result)}
              >
                <CircularProgressLabel>
                  <Flex direction='column' justify='center' align='center'>
                    <Text color='gray.400' fontSize='sm'>Taux</Text>
                    <Text fontSize={{ base :"22px",md: '25px', lg: '35px' }} fontWeight='bold' mb='4px'>
                      {selectedModule.average_result?.toFixed(2) || 0}%
                    </Text>
                  </Flex>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
}

export default Moyenne;
