import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import TableDocs from "./TableDocs";
import TableLinks from "./TableLinks";
import TableVideos from "./TableVideos";

function TableMedia() {
  const location = useLocation();


  return (
    <Box mt='5px'>
      <Box mb={10} w="90%">
        <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: "2xl", sm: "3xl" }}>
          Espace médias : {location.state.titreChapitre}
        </Heading>
      </Box>
      <Tabs variant="enclosed">
        <TabList>
          <Tab _selected={{ color: "white", bg: "#089bd7" }}>Documents</Tab>
          <Tab _selected={{ color: "white", bg: "#089bd7" }}>Videos</Tab>
          <Tab _selected={{ color: "white", bg: "#089bd7" }}>Liens</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box
              w={"full"}
              maxW="full"
              bg={useColorModeValue("white", "gray.700")}
              rounded={"lg"}
              px={6}
              py={2}
            >
              <TableDocs idChapitre={location.state.idChapitre} />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box
              w={"full"}
              maxW="full"
              bg={useColorModeValue("white", "gray.700")}
              rounded={"lg"}
              px={6}
              py={2}
            >
              <TableVideos idModule={location.state.idChapitre}/>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box
              w={'full'}
              maxW='full'
              bg={useColorModeValue('white', 'gray.700')}
              rounded={'lg'}
              px={6}
              py={2}
            >
              <TableLinks idModule={location.state.idChapitre} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default TableMedia;
