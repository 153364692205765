import { Text } from '@chakra-ui/react';
import Chart from 'react-apexcharts'

const BarChart = ({title, data}) => {
  return (
    <div style={{width:"100%" , minHeight:'250px'}}>
        {/* <Text fontSize={20}>{title}</Text> */}
        <Chart  options={data.options} series={data.series} type="bar" height={"100%"} width={"100%"} />
    </div>
  )
}

export default BarChart