import {
  Flex,
  Box,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Alert,
  AlertIcon,
  ModalFooter,
  FormControl,
  FormLabel,
  InputGroup,
  Select,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  Text,
  Tag,
  TagLabel,
  IconButton,
  RadioGroup,
  Radio,
  Progress,
  Avatar,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState, useCallback } from "react";
import axiosClient from "../../axios-client";
import { SearchIcon, AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import MyPagination from "../../components/MyPagination";
import { AiFillFileAdd } from "react-icons/ai";
import {
  BsFiletypePdf,
  BsFiletypePpt,
  BsFillCloudArrowUpFill,
} from "react-icons/bs";
import { useStateContext } from "../../context/ContextProvider";
import { BiLink } from "react-icons/bi";
import { useNavigate } from "react-router";
import { GrCircleAlert } from "react-icons/gr";
import { MdHistory } from "react-icons/md";
function TableDocs({ idChapitre }) {



  // current user
  const { user, setUser } = useStateContext();
  const [listDocs, setListDocs] = useState([]);
  const [listDocsEdit, setListDocsEdit] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deletedId, setDeletedId] = useState();
  const cancelRef = React.useRef();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////
  const navigate = useNavigate();

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };
  useEffect(() => {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });

  }, [user.id]);

  useEffect(() => {
    axiosClient.get(`/docs/getAllDocs/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&chapitre=${idChapitre}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setListDocs(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [user, pageSize, currentPage, search])

  function update() {
    setCheckLengthData(false)

    axiosClient.get(`/docs/getAllDocs/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&chapitre=${idChapitre}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setListDocs(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }
  const toast = useToast();
  const deleteDocs = (id) => {
    axiosClient.delete(`/docs/${id}/`).then((res) => {
      setListDocs((prevData) => prevData.filter((row) => row.id !== id));
      toast({
        title: "Suppression",
        description: `Le Documment est suppprimé avec succès`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
  };
  const [changeModal, setChangeModal] = useState(true);
  const [name, setName] = useState("");
  const [type, setType] = useState();
  const [oldType, setoldType] = useState();
  const [typeDoc, setTypeDoc] = useState("");
  const [lienPPT, setLienPPT] = useState();
  const [file, setFile] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [titre, settitre] = useState("");
  const [tp, settp] = useState("");
  const [oldFileName, setOldFileName] = useState('')
  const [message, setMessage] = useState("");
  const [etatMessage, setEtatMessage] = useState(false);
  // Fonction pour générer un nom de fichier aléatoire désordonné
  const generateRandomFileName = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const fileNameLength = 10; // Longueur du nom de fichier désordonné

    let randomFileName = '';
    for (let i = 0; i < fileNameLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomFileName += characters.charAt(randomIndex);
    }
    const timestamp = Date.now().toString();
    return `${randomFileName}_${timestamp}`;
  };

  const [fileName, setFileName] = useState()

  const handleChangeInputFile = (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)

    // Séparer le nom de fichier et l'extension
    const fileNameParts = file?.name.split(".");
    const fileExtension = fileNameParts?.pop();
    const originalFileNameWithoutExtension = fileNameParts?.join(".");

    // Utiliser la fonction generateRandomFileName pour obtenir un nom aléatoire désordonné
    const newFileNameWithoutExtension = generateRandomFileName();

    // Former le nouveau nom de fichier avec le nouveau nom aléatoire et l'extension d'origine
    const newFileName = `${newFileNameWithoutExtension}.${fileExtension}`;
    // Créer un nouvel objet File avec le nouveau nom

    const renameFile = new File([file], newFileName, { type: file?.type });

    setFile(renameFile);
    if (!file) {
      setFile(null)
      return
    }
    // Afficher le nouveau nom de fichier
  };

  const [uploaded, setUploaded] = useState(null);
  const addDocs = () => {
    if (!name || !type || (name.trim() === "")) {
      setMessage("Veuillez remplir les champs");
      setEtatMessage(true);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("fileName", fileName)
    formData.append("typeDoc", "fichier");
    formData.append("file", file);
    formData.append("chapitre", idChapitre);
    formData.append("added_by", user.id);
    formData.append(
      "modification",
      JSON.stringify([
        {
          edited_by: `${user.first_name} ${user.last_name}`,
          edited_at: edited_at,
          field: {
            name: name,
            type: type,
            file: fileName,
          },
        },
      ])
    );
    if (listDocs.length > 0) {
      const check = listDocs.find(obj => {
        return obj.name.toLowerCase() === name.toLocaleLowerCase().trim()
      })
      if (check) {
        setEtatMessage(true)
        return setMessage('Ce document existe déjà dans ce chapitre !')
      }
    }
    axiosClient
      .post("/docs/", formData, {
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        },
      })
      .then((response) => {
        setListDocs([response.data, ...listDocs]);
        update()
        setEtatMessage(false);
        setName("");
        setType("");
        setLienPPT();
        setFile("");
        toast({
          title: "Ajout",
          description: `Le Document ${name} est ajouté avec succès`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setUploaded(null);
        onClose();
      });
    // window.location.reload()
  };
  const openModalUpdate = (id) => {
    setListDocsEdit([]);
    axiosClient.get(`/docs/${id}/`).then((res) => setListDocsEdit(res.data));
    onOpen();
  };

  const [modification, setModification] = useState([]);
  const date = new Date();
  date.setDate(date.getDate());
  const [edited_by, setedited_by] = useState();
  const [edited_at, setedited_at] = useState(date);

  const modalUpdate = (id, myModification, titre, tp, oldFileName) => {
    if (!name || !type || (name.trim() === "")) {
      setMessage("Veuillez remplir les champs");
      setEtatMessage(true);
      return;
    }
    // if (!file) {
    //   setEtatMessage(true)
    //   setMessage("waaakwaaak")
    //   return
    // }

    const mod = myModification;
    mod.push({
      edited_by: `${user.first_name} ${user.last_name}`,
      edited_at: edited_at,
      field: {
        name: name !== titre ? name : null,
        type: type !== tp ? type : null,
        file: !fileName ? null : fileName
      },
    });

    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    if (fileName) formData.append("fileName", fileName)

    formData.append("typeDoc", "fichier");
    if (file) formData.append("file", file);

    if (name !== titre || type !== tp || (file && fileName !== oldFileName)) {
      formData.append("modification", JSON.stringify(mod));
    }
    const newList = listDocs.filter((e) => e.id !== id)
    if (newList.length > 0) {
      const check = newList.find(obj => {
        return obj.name.toLowerCase() === name.toLocaleLowerCase().trim()
      })
      if (check) {
        setEtatMessage(true)
        return setMessage('Ce document existe déjà dans ce chapitre !')
      }
    }

    axiosClient
      .patch(`/docs/${deletedId}/`, formData, {
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        },
      })
      .then(() => {
        setListDocs((rows) =>
          rows.map((row) => {
            if (row.id === deletedId) {
              return {
                ...row,
                type: type,
                name: name,
                fileName: !fileName ? oldFileName : fileName
              };
            }
            return row;
          })
        );
        setEtatMessage(false);
        toast({
          description: `le Document est modifié avec succès`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        setEtatMessage(false);
        setUploaded(null);
        if (file) {
          // window.location.reload();
          onClose()
          update()
        }
        else {
          onClose();
        }
      });
    // update()
    setName('')
    setType('')
    setFileName('')
    setFile(null)
  };
  //search method


  function CheckchangeType(new_type, old_type) {
    if (new_type !== old_type) {
      if (file === null) {
        return false
      }
      return true
    }
    return true
  }
  return (
    <>
      <Flex
        justifyContent="space-between"
        mb={3}
        alignItems="center"
        direction={{ base: "column", lg: "row" }}
      >
        <InputGroup mt="10px" w={{ base: "100%", lg: "30%" }} mb={{ base: 2 }}>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Recherche..."
            onChange={(e) => {
              setCurrentPage(0)
              setSearch(e.target.value)
            }}
          />
        </InputGroup>
        <Button
          mt="10px"
          mb={{ base: 5 }}
          w={{ base: "100%", lg: "200px" }}
          colorScheme="blue"
          size="md"
          onClick={() => {
            setChangeModal(true);
            onOpen();
          }}
          leftIcon={<AddIcon />}
        >
          Ajouter un document
        </Button>
      </Flex>
      <TableContainer>
        <Table size={"sm"}>
          <Thead>
            <Tr>
              <Th>Titre</Th>
              <Th>Type Document</Th>

              <Th>Fichier</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading &&
              <Tr>
                <Td> <Skeleton height='20px' /></Td>
                <Td> <Skeleton height='20px' /></Td>
                <Td> <Skeleton height='20px' /></Td>
                <Td> <Skeleton height='20px' /></Td>

              </Tr>}
            {(paginationData?.length !== 0) &&
              listDocs.map((val, key) => (
                <Tr key={key}>
                  <Td>{val.name}</Td>
                  <Td>{val.type}</Td>

                  <Td cursor="pointer" title="Voir le contenu">
                    {val.type === "PPT" ? (
                      <Tag
                        onClick={() => {
                          navigate("/detail_media", {
                            state: {
                              file: val.file,
                              fileType: val.type.toLowerCase(),
                            },
                          });
                        }}
                        size="lg"
                        colorScheme="messenger"
                        borderRadius="full"
                      >
                        <BsFiletypePpt fontSize={25} />
                        <TagLabel ml={2}>{val.fileName.length > 45 ? `${val.fileName.substring(0, 40)}... .pptx` : val.fileName}
                          {/* {handleSplitNameFile(val.file)?.length > 45
                                ? `${handleSplitNameFile(val.file).substring(
                                  0,
                                  40
                                )}... .ppt`
                                : handleSplitNameFile(val.file)} */}
                        </TagLabel>
                      </Tag>
                    ) : (
                      <Tag
                        onClick={() => {
                          navigate("/detail_media", {
                            state: {
                              file: val.file,
                              fileType: val.type.toLowerCase(),
                            },
                          });
                        }}
                        size="lg"
                        colorScheme="facebook"
                        borderRadius="full"
                      >
                        <BsFiletypePdf fontSize={25} />
                        <TagLabel ml={2}>{val.fileName?.length > 45
                          ? `${val.fileName?.substring(0, 40)}... .pdf`
                          : val.fileName}
                          {/* {handleSplitNameFile(val.file)?.length > 45
                                ? `${handleSplitNameFile(val.file).substring(
                                  0,
                                  40
                                )}... .pdf`
                                : handleSplitNameFile(val.file)} */}
                        </TagLabel>
                        {/* <TagLabel>{val.file}</TagLabel> */}
                      </Tag>
                    )}
                  </Td>
                  <Td display="flex" justifyContent="">
                    {user.role === "SUPERADMIN" || user.role === "MASTER" ? (
                      <>
                        <IconButton
                          size="sm"
                          colorScheme="green"
                          mr={5}
                          icon={<EditIcon />}
                          onClick={() => {
                            setChangeModal(false);
                            setDeletedId(val.id);
                            openModalUpdate(val.id);
                            setName(val.name);
                            setType(val.type);
                            setTypeDoc(val.typeDoc);
                            setLienPPT(val.lienPPT);
                            setType(val.type);
                            setoldType(val.type);
                            settitre(val.name);
                            settp(val.type);
                            setOldFileName(val.fileName)
                            setModification(val.modification);
                            setFile(null)
                            setUploaded(null);
                          }}
                        >
                          Modifier
                        </IconButton>
                        <IconButton
                          size="sm"
                          mr={5}
                          bg="gray.300"
                          icon={<MdHistory />}
                          onClick={() =>
                            navigate("/historique_media", {
                              state: {
                                idMedia: val.id,
                                Type: val.type,
                              },
                            })
                          }
                        >
                          Historique
                        </IconButton>
                        <IconButton
                          size="sm"
                          colorScheme="red"
                          icon={<DeleteIcon />}
                          onClick={() => {
                            setDeletedId(val.id);
                            onOpenAlert();
                          }}
                        >
                          Supprimer
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          size="sm"
                          colorScheme="green"
                          mr={5}
                          icon={<EditIcon />}
                          onClick={() => {
                            setChangeModal(false);
                            setDeletedId(val.id);
                            openModalUpdate(val.id);
                            setName(val.name);
                            setType(val.type);
                            setoldType(val.type);
                            setTypeDoc(val.typeDoc);
                            setLienPPT(val.lienPPT);
                            settitre(val.name);
                            settp(val.type);
                            setOldFileName(val.fileName)
                            setModification(val.modification);
                            setFile(null)
                            setUploaded(null);
                          }}
                        >
                          Modifier
                        </IconButton>
                        <IconButton
                          size="sm"
                          bg="gray.300"
                          icon={<MdHistory />}
                          onClick={() =>
                            navigate("/historique_media", {
                              state: {
                                idMedia: val.id,
                                Type: val.type,
                              },
                            })
                          }
                        >
                          Historique
                        </IconButton>
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
            {(((search) && paginationData?.length === 0) || (checkLengthData)) && (
              <Tr>
                <Td colSpan={6}>
                  Aucune ligne correspondante n'a été trouvée.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
        <Box flex="1">
          <MyPagination
            paginationData={paginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            PAGE_SIZE={pageSize}
          />
        </Box>

        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          {/* Add more options as needed */}
        </Select>
      </Flex>
      {/* Alert for delete */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le Document
            </AlertDialogHeader>
            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action
              ultérieurement.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  deleteDocs(deletedId);
                  onCloseAlert();
                }}
                colorScheme="red"
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {changeModal ? (
        //Modal for Add
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setEtatMessage(false);
            setName("");
            setFile("");
            setType("");
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajout du document</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {etatMessage && (
                <Alert status="error" rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              <Flex direction="column">
                <FormControl id="name" isRequired>
                  <FormLabel>Titre</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <Input
                      maxLength={50}
                      type="text"
                      size="lg"
                      name="name"
                      placeholder="Entrer le titre du document"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </InputGroup>
                  <Flex justify="end">
                    {name.length > 40 ? (
                      <Text fontSize="xs" color="red">
                        {name.length}/50 caractères !
                      </Text>
                    ) : (
                      <Text fontSize="xs">
                        {name.length}/50{" "}
                        {name.length > 1 ? "caractères" : "caractère"}
                      </Text>
                    )}
                  </Flex>
                </FormControl>
                <FormControl mt="3" id="type" isRequired>
                  <FormLabel>Type Document</FormLabel>
                  <Select
                    placeholder="Type de Fichier"
                    name="type"
                    size="lg"
                    onChange={(e) => {
                      setType(e.target.value);
                      setTypeDoc(null);
                      setFileName(null)
                      setFile(null)
                    }}
                  >
                    <option value={"PDF"}>PDF</option>
                    <option value={"PPT"}>PPT</option>
                  </Select>
                </FormControl>
                {type === "PPT" && (
                  <>
                    {/* <FormControl mt="3" id="type_Doc" isRequired>
                      <FormLabel>Type Power Point</FormLabel>
                      <RadioGroup
                        id="type_Doc"
                        name="type_Diplome"
                        onChange={setTypeDoc}
                        value={typeDoc}
                      >
                        <Flex>
                          <Radio
                            mr={5}
                            size="md"
                            value="fichier"
                            onChange={(e) => setTypeDoc(e.target.value)}
                            colorScheme="green"
                          >
                            Fichier
                          </Radio>
                          <Radio
                            size="md"
                            value="lien"
                            onChange={(e) => {
                              setTypeDoc(e.target.value)
                              setFile(null)
                            }}
                            colorScheme="green"
                          >
                            Lien
                          </Radio>
                        </Flex>
                      </RadioGroup>
                    </FormControl> */}
                    <FormControl mt="3" id="file" isRequired>
                      <FormLabel>Fichier</FormLabel>
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                        height="100px"
                        border="1px dashed"
                        position="relative"
                        borderColor="#2b6cb0"
                      >
                        <BsFillCloudArrowUpFill size="70px" color="#2b6cb0" />
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          color="#2b6cb0"
                        >
                          Parcourir le fichier à télécharger
                        </Text>
                      </Flex>
                      <InputGroup
                        borderColor="#E0E1E7"
                        position="absolute"
                        top="8"
                      >
                        <Input
                          opacity="0"
                          cursor="pointer"
                          type="file"
                          height="100px"
                          size="lg"
                          name="file"
                          onChange={handleChangeInputFile}
                          placeholder="Titre de Formation"
                          accept=".pptx, .ppt"
                        />
                      </InputGroup>
                    </FormControl>
                    {file && (
                      <>
                        {fileName?.length - 5 > 70 ? (
                          <Tag
                            mt="10px"
                            size="lg"
                            padding={2}
                            colorScheme="red"
                            borderRadius="full"
                          >
                            <GrCircleAlert size="30px" />
                            <TagLabel ml={3}>
                              Le nom du fichier dépasse 70 caractères !
                            </TagLabel>
                          </Tag>
                        ) : (
                          <Tag
                            mt="10px"
                            size="xl"
                            variant="outline"
                            px="2px"
                            py="5px"
                            position="relative"
                            colorScheme='messenger'
                          >
                            <BsFiletypePpt size="30px" />
                            <Flex direction="column" justifyContent="center">
                              <Text>
                                {fileName?.length > 52
                                  ? `${fileName.substring(0, 48)}... .ppt`
                                  : `${fileName}`}
                              </Text>
                              <Text fontSize="xs">
                                {Math.floor(file.size / 1000) < 1024
                                  ? `${Math.floor(file.size / 1024)} KB`
                                  : `${Math.floor(
                                    file.size / (1024 * 1024)
                                  )} MB`}
                                {uploaded === 0 ? null : (
                                  <Progress
                                    mt="1"
                                    colorScheme="linkedin"
                                    w="28em"
                                    rounded="lg"
                                    h="5px"
                                    size="xs"
                                    hasStripe
                                    value={uploaded}
                                  />
                                )}
                              </Text>
                            </Flex>
                          </Tag>
                        )}
                      </>
                    )}
                  </>
                )}
                {type === "PDF" && (
                  <>
                    <FormControl mt="3" id="file" isRequired>
                      <FormLabel>Fichier</FormLabel>
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                        height="100px"
                        border="1px dashed"
                        position="relative"
                        borderColor="#2b6cb0"
                      >
                        <BsFillCloudArrowUpFill size="70px" color="#2b6cb0" />
                        <Text
                          fontSize="lg"
                          fontWeight="semibold"
                          color="#2b6cb0"
                        >
                          Parcourir le fichier à télécharger
                        </Text>
                      </Flex>
                      <InputGroup
                        borderColor="#E0E1E7"
                        position="absolute"
                        top="8"
                      >
                        <Input
                          opacity="0"
                          cursor="pointer"
                          type="file"
                          height="100px"
                          size="lg"
                          name="file"
                          onChange={handleChangeInputFile}
                          placeholder="Titre de Formation"
                          accept=".pdf"
                        />
                      </InputGroup>
                    </FormControl>
                    {file && (
                      <>
                        {fileName?.length - 5 > 70 ? (
                          <Tag
                            mt="10px"
                            size="lg"
                            padding={2}
                            colorScheme="red"
                            borderRadius="full"
                          >
                            <GrCircleAlert size="30px" />
                            <TagLabel ml={3}>
                              Le nom du fichier dépasse 70 caractères !
                            </TagLabel>
                          </Tag>
                        ) :
                          (
                            <Tag
                              mt="10px"
                              size="xl"
                              variant="outline"
                              px="2px"
                              py="5px"
                              position="relative"
                              colorScheme="facebook"
                            >
                              <BsFiletypePdf size="30px" />
                              <Flex direction="column" justifyContent="center">
                                <Text>
                                  {fileName?.length > 60
                                    ? `${fileName.substring(0, 50)}... .pdf`
                                    : `${fileName}`}
                                </Text>
                                <Text fontSize="xs">
                                  {Math.floor(file.size / 1000) < 1024
                                    ? `${Math.floor(file.size / 1024)} KB`
                                    : `${Math.floor(
                                      file.size / (1024 * 1024)
                                    )} MB`}
                                  {uploaded === 0 ? null : (
                                    <Progress
                                      mt="1"
                                      colorScheme="linkedin"
                                      w="28em"
                                      rounded="lg"
                                      h="5px"
                                      size="xs"
                                      hasStripe
                                      value={uploaded}
                                    />
                                  )}
                                </Text>
                              </Flex>
                            </Tag>
                          )}
                      </>
                    )}
                  </>
                )}
              </Flex>
            </ModalBody>
            <ModalFooter>
              {!name || !type || !file || file.name?.length > 75 ? (
                <Button
                  colorScheme="blue"
                  title="Veuillez remplir tout les champs"
                  isDisabled
                >
                  Ajouter
                </Button>
              ) : uploaded ? (
                <Button
                  isLoading
                  loadingText="En cours"
                  colorScheme="blue"
                  onClick={addDocs}
                >
                  Ajouter
                </Button>
              ) : (
                <Button colorScheme="blue" onClick={addDocs}>
                  Ajouter
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        //Modal for Update
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setEtatMessage(false);
            setName("");
            setFile("");
            setType('')
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Modification du document
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {etatMessage && (
                <Alert status="error" rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              {listDocs.map((val, key) => {
                if (val.id === deletedId) {
                  return (
                    <Flex direction="column" key={key}>
                      {val.typeDoc === "fichier" && (
                        <FormControl>
                          <FormLabel>Ancien fichier</FormLabel>
                          {val.file && (
                            <Tag
                              mb="10px"
                              size="xl"
                              w="100%"
                              variant="outline"
                              px="2px"
                              py="5px"
                              position="relative"
                              colorScheme="yellow"
                            >
                              <AiFillFileAdd size="40px" />
                              <Flex direction="column" justifyContent="center">
                                <Text>
                                  {val.type === "PDF"
                                    ? val.fileName?.length > 50
                                      ? `${val.fileName?.substring(0, 45)}... .pdf`
                                      : `${val.fileName}`
                                    : val.fileName?.length > 50
                                      ? `${val.fileName?.substring(0, 45)}... .ppt`
                                      : `${val.fileName}`}
                                </Text>
                              </Flex>
                            </Tag>
                          )}
                        </FormControl>
                      )}
                      <FormControl id="name" isRequired>
                        <FormLabel>Titre</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <Input
                            maxLength={50}
                            type="text"
                            size="lg"
                            name="name"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </InputGroup>
                        <Flex justify="end">
                          {name.length > 40 ? (
                            <Text fontSize="xs" color="red">
                              {name.length}/50 caractères !
                            </Text>
                          ) : (
                            <Text fontSize="xs">
                              {name.length}/50{" "}
                              {name.length > 1 ? "caractères" : "caractère"}
                            </Text>
                          )}
                        </Flex>
                      </FormControl>
                      <FormControl mt="3" id="type" isRequired>
                        <FormLabel>Type Document {CheckchangeType(type, oldType)}</FormLabel>
                        <Select
                          name="type"
                          defaultValue={type}
                          size="lg"
                          onChange={(e) => {
                            setType(e.target.value);
                            setFileName(null)
                            setFile(null)
                          }}
                        >
                          <option value={"PDF"}>PDF</option>
                          <option value={"PPT"}>PPT</option>
                        </Select>
                      </FormControl>
                      {type === "PPT" && (
                        <>
                          {/* <FormControl mt="3" id="type_Doc" isRequired>
                            <FormLabel>Type Power Point</FormLabel>
                            <RadioGroup
                              id="type_Doc"
                              name="type_Diplome"
                              onChange={setTypeDoc}
                              value={typeDoc}
                            >
                              <Flex>
                                <Radio
                                  mr={5}
                                  size="md"
                                  value="fichier"
                                  onChange={(e) => setTypeDoc(e.target.value)}
                                  colorScheme="green"
                                >
                                  Fichier
                                </Radio>
                                <Radio
                                  size="md"
                                  value="lien"
                                  onChange={(e) => {
                                    setTypeDoc(e.target.value)
                                    setFile(null)
                                  }}
                                  colorScheme="green"
                                >
                                  Lien
                                </Radio>
                              </Flex>
                            </RadioGroup>
                          </FormControl>
                          {typeDoc === "lien" &&
                            <FormControl mt="3" id="lienPPT" isRequired>
                              <FormLabel>Lien PPT</FormLabel>
                              <InputGroup borderColor="#E0E1E7">
                                <Input
                                  type="text"
                                  size="lg"
                                  name="lienPPT"
                                  defaultValue={val.lienPPT === "null" ? "" : val.lienPPT}
                                  placeholder="Entrer le lien du Power Point"
                                  onChange={(e) => setLienPPT(e.target.value)}
                                />
                              </InputGroup>
                            </FormControl>
                          } */}
                          <FormControl mt="3" id="file" isRequired>
                            <FormLabel>Fichier</FormLabel>
                            <Flex
                              position="relative"
                              alignItems="center"
                              justifyContent="center"
                              direction="column"
                              height="100px"
                              border="1px dashed"
                              borderColor="#2b6cb0"
                            >
                              <BsFillCloudArrowUpFill
                                size="70px"
                                color="#2b6cb0"
                              />
                              <Text
                                fontSize="lg"
                                fontWeight="semibold"
                                color="#2b6cb0"
                              >
                                Parcourir le fichier à télécharger
                              </Text>
                              <InputGroup
                                borderColor="#E0E1E7"
                                position="absolute"
                                top="0"
                              >
                                <Input
                                  opacity="0"
                                  cursor="pointer"
                                  type="file"
                                  height="100px"
                                  size="lg"
                                  name="file"
                                  onChange={handleChangeInputFile}
                                  accept=".pptx, .ppt"
                                />
                              </InputGroup>
                            </Flex>
                          </FormControl>
                          {file && (
                            <>
                              {fileName?.length - 5 > 70 ? (
                                <Tag
                                  mt="10px"
                                  size="lg"
                                  padding={2}
                                  colorScheme="red"
                                  borderRadius="full"
                                >
                                  <GrCircleAlert size="30px" />
                                  <TagLabel ml={3}>
                                    Le nom du fichier dépasse 70 caractère !
                                  </TagLabel>
                                </Tag>
                              ) : (
                                <Tag
                                  mt="10px"
                                  size="xl"
                                  variant="outline"
                                  px="2px"
                                  py="5px"
                                  position="relative"
                                  colorScheme="linkedin"
                                >
                                  <AiFillFileAdd size="40px" />
                                  <Flex
                                    direction="column"
                                    justifyContent="center"
                                  >
                                    <Text>
                                      {fileName?.length > 50
                                        ? `${fileName?.substring(
                                          0,
                                          45
                                        )}... .ppt`
                                        : `${fileName}`}
                                    </Text>
                                    <Text fontSize="xs">
                                      {Math.floor(file.size / 1000) < 1024
                                        ? `${Math.floor(file.size / 1024)} KB`
                                        : `${Math.floor(
                                          file.size / (1024 * 1024)
                                        )} MB`}
                                      {uploaded === 0 ? null : (
                                        <Progress
                                          mt="1"
                                          colorScheme="linkedin"
                                          w="28em"
                                          rounded="lg"
                                          h="5px"
                                          size="xs"
                                          hasStripe
                                          value={uploaded}
                                        />
                                      )}
                                    </Text>
                                  </Flex>
                                </Tag>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {type === "PDF" && (
                        <>
                          {" "}
                          <FormControl mt="3" id="file" isRequired>
                            <FormLabel>Fichier</FormLabel>
                            <Flex
                              position="relative"
                              alignItems="center"
                              justifyContent="center"
                              direction="column"
                              height="100px"
                              border="1px dashed"
                              borderColor="#2b6cb0"
                            >
                              <BsFillCloudArrowUpFill
                                size="70px"
                                color="#2b6cb0"
                              />
                              <Text
                                fontSize="lg"
                                fontWeight="semibold"
                                color="#2b6cb0"
                              >
                                Parcourir le fichier à télécharger
                              </Text>
                              <InputGroup
                                borderColor="#E0E1E7"
                                position="absolute"
                                top="0"
                              >
                                <Input
                                  opacity="0"
                                  cursor="pointer"
                                  type="file"
                                  height="100px"
                                  size="lg"
                                  name="file"
                                  onChange={handleChangeInputFile}
                                  accept=".pdf"
                                />
                              </InputGroup>
                            </Flex>
                          </FormControl>
                          {file && (
                            <>
                              {fileName?.length - 5 > 70 ? (
                                <Tag
                                  mt="10px"
                                  size="lg"
                                  padding={2}
                                  colorScheme="red"
                                  borderRadius="full"
                                >
                                  <GrCircleAlert size="30px" />
                                  <TagLabel ml={3}>
                                    Le nom du fichier dépasse 70 caractère !
                                  </TagLabel>
                                </Tag>
                              ) : (
                                <Tag
                                  mt="10px"
                                  size="xl"
                                  variant="outline"
                                  px="2px"
                                  py="5px"
                                  position="relative"
                                  colorScheme="linkedin"
                                >
                                  <AiFillFileAdd size="40px" />
                                  <Flex
                                    direction="column"
                                    justifyContent="center"
                                  >
                                    <Text>
                                      {fileName?.length > 50
                                        ? `${fileName?.substring(
                                          0,
                                          45
                                        )}... .pdf`
                                        : `${fileName}`}
                                    </Text>
                                    <Text fontSize="xs">
                                      {Math.floor(file.size / 1000) < 1024
                                        ? `${Math.floor(file.size / 1024)} KB`
                                        : `${Math.floor(
                                          file.size / (1024 * 1024)
                                        )} MB`}
                                      {uploaded === 0 ? null : (
                                        <Progress
                                          mt="1"
                                          colorScheme="linkedin"
                                          w="28em"
                                          rounded="lg"
                                          h="5px"
                                          size="xs"
                                          hasStripe
                                          value={uploaded}
                                        />
                                      )}
                                    </Text>
                                  </Flex>
                                </Tag>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Flex>
                  );
                }
              })}
            </ModalBody>
            <ModalFooter>
              {!name ||
                !type ||
                !CheckchangeType(type, oldType) ||
                (file && fileName?.length - 5 > 70) ? (
                <Button
                  colorScheme="blue"
                  title="Veuillez remplir tout les champs"
                  isDisabled
                >
                  Modifier
                </Button>
              ) : uploaded ? (
                <Button
                  isLoading
                  loadingText="En cours"
                  colorScheme="blue"
                ></Button>
              ) : (
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    modalUpdate(deletedId, modification, titre, tp, oldFileName)

                  }}
                >
                  Modifier
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
export default TableDocs;
