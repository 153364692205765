// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Switch,
  Text,
  useColorMode,
  Heading,
  SimpleGrid,
  Progress,
  useColorModeValue,
  Card,
  CardBody,
  Badge,
  useToast,
  Button,
  Tooltip,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent

} from "@chakra-ui/react";
import Progres from './Progres'

import axiosClient from "../../axios-client";
import React, { useState, useEffect } from 'react';

import { FaCube } from "react-icons/fa";
import { useLocation, useParams } from 'react-router-dom';
import { IoDocumentsSharp } from "react-icons/io5";
import { progress } from "framer-motion";
import { BsTrash } from "react-icons/bs";

function ApprenantDetail() {

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = React.useRef()

  const [accesId, setAccesId] = useState(null);
  const [mdId, setMdId] = useState(null);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };


  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };


  const location = useLocation()
  const { colorMode } = useColorMode();


  const { id } = useParams();
  const [apprenant, setApprenant] = useState('');

 
  useEffect(() => {
    axiosClient.get(`/apprenants/${location.state.idApprenant}/`)
      .then(({ data }) => {
        setApprenant(data)
      })
  }, [])

  function truncateDescription(description) {
    const words = description.split(' ');
    const truncatedWords = words.slice(0, 15);
    return truncatedWords.join(' ') + (words.length > 15 ? '...' : '');
  }
  const [cpm, setCpm] = useState(0)
  const [certificatCount, setCertificatCount] = useState(0)
  const [moduleCount, setModuleCount] = useState(0)

  
  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const res = await axiosClient.get(`/get_student_data/${location.state.idApprenant}/`);
        setStudentData(res.data);

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    const studentId = location.state.idApprenant;

    axiosClient.get(`/count_certificates_and_modules/${studentId}/`)
      .then((res) => {
        setCertificatCount(res.data.certificat_count)
        setModuleCount(res.data.module_count)

      })
      .catch((error) => {
        console.error("Error fetching counts: ", error);
      });

    fetchStudentData();
  }, [location.state.idApprenant]);



  const toast = useToast()
  const [etat, setEtat] = useState();

  function update() {
    const studentId = location.state.idApprenant;

    axiosClient.get(`/count_certificates_and_modules/${studentId}/`)
      .then((res) => {
        setCertificatCount(res.data.certificat_count)
        setModuleCount(res.data.module_count)

      })
      .catch((error) => {
        console.error("Error fetching counts: ", error);
      });
    axiosClient.get(`/get_student_data/${location.state.idApprenant}/`)
      .then((res) => {
        setStudentData(res.data);

      })
      .catch((error) => {
        console.error("Error fetching student data: ", error);
      });

    
  }

  const handleDelete = async (id, idModule) => {
    try {
      await axiosClient.delete(`/delete_access/${id}/${idModule}/${location.state.idApprenant}/`);
      
      toast({
        description: "L'accès est supprimé avec succès",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right',
      });
      
      update();
    } catch (error) {
      console.error('Error deleting access:', error);
    }
    onCloseAlert();
  };
  // const handleDelete = async (id, idModule) => {
  //   try {
  //     let myMedia = []
  //     const chapitre = listChapitre.filter(row => row.module === idModule)
  //     for (let index = 0; index < chapitre.length; index++) {
  //       const medIds = listMedia
  //         .filter(row => row.chapitre === chapitre[index].id)
  //         .map(filteredMed => filteredMed.id);

  //       myMedia.push(...medIds); // Spread the IDs into the myMedia array
  //     }
  //     const progresCMD = listProgresCMD.filter(row => myMedia.includes(row.media))
  //     for (let index = 0; index < progresCMD.length; index++) {
  //       axiosClient.delete(`/progressmodcontent/${progresCMD[index].id}/`)
  //     }
  //     const progres = listProgres.filter(row => (row.apprenant === location.state.idApprenant && row.module === idModule));
  //     // console.log('progresCMD ', listProgresCMD.filter(row => myMedia.includes(row.media)));

  //     axiosClient.delete(`/acces/${id}/`);
  //     if (progres.length > 0) {
  //       axiosClient.delete(`/progres/${progres[0].id}/`)
  //     }


  //     toast({
  //       description: "L'accès est suprimé avec succès",
  //       status: 'success',
  //       duration: 2000,
  //       isClosable: true,
  //       position: 'bottom-right'
  //     })
  //     update()
  //   } catch (error) {
  //     console.error('Error deleting access:', error);
  //   }
  //   onCloseAlert()

  // };
  const EditActivation = (id, et, re) => {
    axiosClient.patch(`/acces/${id}/`, {
      etat: !et,
      encours: false,
      refus: !re,
    })
      .then((res) => {
       update()

        setStudentData(prevStudentData =>
          prevStudentData.map(val => ({
            ...val,
            modules: val.modules.map(mod => {
              if (mod.access.id === id) {
                return {
                  ...mod,
                  access: { // Correcting to update the access object properly
                    ...mod.access, // Keep existing access properties
                    etat: !et,
                    encours: false,
                    refus: !re
                  }
                };
              }
              return mod; // Return the module unchanged if it doesn't match
            })
          }))
        );

        setEtat(!etat)
      })
    if (!et) {
      toast({
        description: `Le module est activé`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    else {
      toast({
        description: `Le module est desactivé`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }

  };

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("white", "gray.700");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");





  return (
    <Box>
      <Flex shadow={'xl'} direction='column' pt={{ base: "30px", md: "30px", lg: "30px" }}>
        <Flex
          direction={{ sm: "column", md: "row" }}
          maxH='330px'
          justifyContent={{ sm: "center", md: "space-between" }}
          align='center'
          backdropFilter='blur(21px)'
          boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
          border='1.5px solid'
          borderColor={borderProfileColor}
          bg={bgProfile}
          p='24px'
          borderRadius='20px'>
          <Flex
            align='center'
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}>
            <Avatar
              src={apprenant.image}
              me={{ md: "22px" }}
              w='80px'
              h='80px'
              borderRadius='15px'
            />
            <Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
              <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight='bold'
                ms={{ sm: "8px", md: "0px" }}>
                {apprenant.first_name} {apprenant.last_name}
              </Text>
              <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight='semibold'>
                {apprenant.email}

              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={{ sm: "column", lg: "row" }}
            w={{ sm: "100%", md: "50%", lg: "auto" }}>
            <Card p='0px' bg='transparent' variant='no-effects'>
              <Flex
                align='center'
                w={{ sm: "100%", lg: "200px" }}
                borderRadius='8px'
                justifyContent='center'
                py='10px'
                cursor='pointer'>
                <Icon color={'yellow.400'} as={FaCube} me='11px' boxSize='30px' />
                <Box>
                  <Text color={textColor} fontWeight='bold'>
                    Nombre de modules
                  </Text>
                  <Text fontSize={'xl'} color={textColor} fontWeight='bold'>
                    {moduleCount}
                  </Text>
                </Box>
              </Flex>

            </Card>
            <Card p='0px' bg='transparent' variant='no-effects'>
              <Flex
                align='center'
                w={{ lg: "250px" }}
                borderRadius='15px'
                justifyContent='center'
                py='10px'
                mx={{ lg: "1rem" }}
                cursor='pointer'>
                <Icon color={'green.400'} as={IoDocumentsSharp} me='11px' boxSize='30px' />
                <Box>
                  <Text color={textColor} fontWeight='bold'>
                    Nombre de certificats
                  </Text>
                  <Text fontSize={'xl'} color={textColor} fontWeight='bold'>
                    {certificatCount}
                  </Text>
                </Box>
              </Flex>
            </Card>

          </Flex>
        </Flex>
      </Flex >

      {studentData.map((formation, index) => (
        <div key={index}>
          <Heading mt={5} size='md'>Formation {formation.formation.titre}</Heading>
          <SimpleGrid mt={5} spacing={4} columns={{ base: 1, lg: 2 }}>
            {formation.modules.map((module) => (
              <Card
                key={module.id}
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
              >
                <Image
                  objectFit='cover'
                  w={{ base: '100%', sm: '150px' }}
                  src={module.image}
                  alt={module.titre} // Set alt text for accessibility
                />
                <CardBody>
                  <Heading size='md'>{module.titre}</Heading>
                  <Text py='2' mb="8px">
                  {truncateDescription(module.description)} {/* Truncate if needed */}
                  </Text>
                  <Box>
                    {module.progress === "error" ? (
                      <Progress hasStripe  size='sm' isIndeterminate />
                    ) : (
                      <Progress hasStripe  size='sm' rounded={"lg"} colorScheme={module.access.etat ? 'green' : 'red'} value={Math.min(module.progress, 100)} />
                    )}
                    <Flex align="center" justify="space-between" mt={2}>
                      <Flex>
                        {(module.access.etat === false && module.access.encours === true && module.access.refus === true) ?
                          <>
                            <Flex flexDir={'column'}>

                              <Text color={'red'} mb={1}>
                                Demande de réactivation...
                              </Text>
                              <Flex gap={2}>
                              <Switch
                                size="sm"
                                colorScheme="green"
                                isChecked={module.access.etat}
                                onChange={() => {
                                  EditActivation(
                                    module.access.id,
                                    module.access.etat,
                                    module.access.refus
                                  )
                                }
                                }
                              />
                              <Badge
                                rounded="lg"
                                colorScheme={module.access.etat ? "green" : "red"}
                              >
                                {module.access.etat ? "Activé" : "Désactivé"}
                              </Badge>

                              </Flex>
                             
                            </Flex>

                          </>
                          :
                          <>
                           <Flex gap={2}>

                           <Switch
                              size="sm"
                              colorScheme="green"
                              isChecked={module.access.etat}
                              onChange={() => {
                                EditActivation(
                                  module.access.id,
                                  module.access.etat,
                                  module.access.refus
                                )
                              }
                              }
                            />
                            <Badge
                              rounded="lg"
                              colorScheme={module.access.etat ? "green" : "red"}
                            >
                              {module.access.etat ? "Activé" : "Désactivé"}
                            </Badge>
                           </Flex>
                            
                          </>


                        }


                      </Flex>
                      <BsTrash cursor='pointer' color="red" title="supprimer l'accès" fontSize="20" onClick={() => {
                        onOpenAlert();

                        setMdId(module.id)
                        setAccesId(module.access.id)

                        // onOpen();
                      }} />
                    </Flex>
                  </Box>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </div>
      ))}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>

            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Supprimer l'accès
            </AlertDialogHeader>

            <AlertDialogBody>
              Attention ! Cette suppression est irréversible. Êtes-vous sûr de vouloir continuer ? Toutes les données de l'apprenant seront perdues définitivement.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button onClick={() => handleDelete(accesId, mdId)} colorScheme='red' ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default ApprenantDetail;