import {
  SearchIcon
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from '../../components/MyPagination';

function ActivationModuleApprenant({ id_Module }) {

  const location = useLocation();


  const [listAcces, setListAcces] = useState([]);

  const [value, setValue] = useState(null);
  const [search, setSearch] = useState('')
  const [checkLengthData, setCheckLengthData] = useState(false)
  const [paginationData, setPaginationData] = useState()
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0);

  // Pagination variables
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {


    axiosClient.get(`/acces/getEtatAccesInModule/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&etat=${value}&module=${location.state.idModule}`)
      .then((response) => {

        let filteredData = response?.data?.results ? response?.data?.results : [] || [];
        
        setPaginationData(response.data);

        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setListAcces(filteredData);
          setCheckLengthData(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

  }, [pageSize, currentPage, search, value])

  const toast = useToast()
  const EditActivation = (id, et, re) => {
    axiosClient.patch(`/acces/${id}/`, {
      etat: !et,
      encours: false,
      refus: !re,
      firstAcces: false
    })
      .then(() => {
        setListAcces(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !et,
              encours: false,
              refus: !re
            }
          }
          return row
        }))
      })
    if (!et) {
      toast({
        description: `Le module ${location.state.titre} est activé`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    else {
      toast({
        description: `Le module ${location.state.titre} est desactivé`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }

  };



  return (
    <Stack mt="5px">
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <Flex mb={3} justifyContent="space-between">
          <InputGroup>
            <Select
              w={{ base: '50%', lg: "15%" }}
              name="value"
              value={value}
              size="sm"
              onChange={(e) => { setValue(e.target.value === '' ? null : e.target.value === 'true') }}
            >
              <option value="">Tout</option>
              <option value="true">Activé</option>
              <option value="false">Désactivé</option>
            </Select>
          </InputGroup>
          <InputGroup w={{ base: '70%', lg: "30%" }}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              type="tel"
              placeholder="Recherche..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Flex>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Prénom</Th>
                <Th>Module</Th>
                <Th>Etat</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading &&
                <Tr>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>
                  <Td> <Skeleton height='20px' /></Td>

                </Tr>}
              {(paginationData?.length !== 0) &&
                listAcces.map((val, key) => (
                  <Tr key={key}>
                    <Td>

                      {val.last_name}
                    </Td>
                    <Td>

                      {val.first_name}
                    </Td>
                    <Td>{location.state.titre}</Td>
                    <Td>
                      <Switch
                        size="md"
                        colorScheme="green"
                        isChecked={val.etat}
                        onChange={() =>
                          EditActivation(
                            val.id,
                            val.etat,
                            val.refus
                          )
                        }
                      />
                      <Badge
                        rounded="lg"
                        colorScheme={val.etat ? "green" : "red"}
                      >
                        {val.etat ? "Activé" : "Desactivé"}
                      </Badge>
                    </Td>
                  </Tr>

                ))}
              {(((search || value != null) && paginationData?.length === 0) || (checkLengthData)) && (
                <Text>Aucune ligne correspondante n'a été trouvée.</Text>

              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              paginationData={paginationData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              PAGE_SIZE={pageSize}
            />
          </Box>

          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Stack>
    </Stack>
  );
}

export default ActivationModuleApprenant;
