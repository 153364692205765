import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axiosClient from '../../axios-client'
import { useState } from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Avatar,
    Badge,
    Box,
    Button,
    Flex,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Stack,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    WrapItem,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { BsCheckSquareFill } from 'react-icons/bs'
import { AiFillMinusSquare } from 'react-icons/ai'
import MyPagination from '../../components/MyPagination'

function HistoriqueChapitre() {
    const location = useLocation()
    const [chapitre, setchapitre] = useState([])
    useEffect(() => {
        axiosClient
            .get(`/chapitre/${location.state.idChapitre}/`)
            .then((res) => {
                setchapitre(res.data.modification.sort().reverse())
            })
    }, [])


    // Pagination variables
    ////////////////////////////////the size of the table//////////////////////////////
    const [currentPage, setCurrentPage] = useState(0);
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };

    return (
        <Box mt="5px">
            <Box mb={5} w="90%">
                <Heading
                    bgGradient='linear(to-l,  #ffd140, #089bd7)'
                    bgClip="text"
                    fontSize={{ base: "2xl", sm: "3xl" }}
                >
                    Historiques
                </Heading>
            </Box>
            <Stack
                w={"full"}
                maxW="full"
                bg={useColorModeValue("white", "gray.700")}
                rounded={"lg"}
                p={6}
                my={5}
            >
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Titre</Th>
                                <Th>Date de modification</Th>
                                <Th>Modifié par</Th>
                            </Tr>
                        </Thead>
                        {chapitre
                            .slice(
                                currentPage * pageSize,
                                currentPage * pageSize + pageSize
                            )
                            .map((val, key) => {
                                return (
                                    <Tbody>
                                        <Tr key={key}>
                                            <Td>
                                                <Text fontWeight="semibold">
                                                    {val.field.name?.length > 20
                                                        ? `${val.field.name.substring(0, 20)}...`
                                                        : val.field.name}</Text>
                                            </Td>
                                            <Td>{new Date(val.edited_at).toLocaleString('fr-FR')}</Td>
                                            <Td><Text fontWeight="semibold">{val.edited_by}</Text></Td>
                                        </Tr>
                                    </Tbody>
                                )
                            })}
                    </Table>
                </TableContainer>

                <Flex justify="space-between" align="center" w="100%">
                    <Box flex="1">
                        <MyPagination
                            data={chapitre}
                            PAGE_SIZE={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Box>

                    <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        {/* Add more options as needed */}
                    </Select>
                </Flex>
            </Stack>
        </Box>
    )
}

export default HistoriqueChapitre