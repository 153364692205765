import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  useToast,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useColorModeValue,
  Textarea,
  VStack,
  Wrap,
  Alert,
  AlertIcon,
  WrapItem,
  Image,
  Badge,
  Avatar,
  AvatarBadge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from "react-icons/md";
import {
  BsGithub,
  BsDiscord,
  BsPerson,
  BiSolidImage,
  BsImageFill,
} from "react-icons/bs";
import { TbCameraPlus } from "react-icons/tb";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import axiosClient from "../../axios-client";
import imgAddFormation from "../../assets/img/add_formation.png";
import defaultImage from "../../assets/img/default-image.jpg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useStateContext } from "../../context/ContextProvider";
import { AiOutlineCheck } from "react-icons/ai";

function AddFormation() {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 1196px)");
  const [message, setMessage] = useState("");

  //
  const [titre, setTitre] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [etat, setEtat] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // current user
  const { user } = useStateContext();

  const date = new Date();
  date.setDate(date.getDate());

  const navigate = useNavigate();
  const addFormation = async (title) => {
    setLoading(true)
    if ((!titre || !description || !selectedImage) || (titre.trim() === "" || description.trim() === "")) {
      setMessage("Veuillez remplir les champs!");
      setLoading(false)
      return;
    }

    // const noNumber = /^[0-9]/.test(str)

    if (/^[^<>{}\"/|;:.,~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©0-9_+]/.test(titre)) {
      setMessage("Veuillez saisir un titre correct");
      setLoading(false)
      return;
    }
    const check = await axiosClient.get(`check_exist/?check=Formation&value=${titre}`);
    if (check.data.exists) {
      //   setIsLoading(false);
      window.scrollTo(0, 0);
      setMessage("Cette formation existe déjà !");
      setLoading(false)
      return;
    }
    const formData = new FormData();
    formData.append("description", description);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    formData.append("titre", titre);
    formData.append("etat", etat);
    formData.append("added_by", user.id);
    formData.append(
      "modification",
      JSON.stringify([
        {
          edited_by: `${user.first_name} ${user.last_name}`,
          edited_at: date,
          field: {
            etat: etat,
            titre: titre,
            description: description,
            image: "Ajoutée",
          },
        },
      ])
    );


    axiosClient
      .post(`/formation/`, formData)
      .then(() => {
        toast({
          description: `la formation ${title} est ajoutée avec succès`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        setLoading(false)
        navigate("/formations");
      })
      .catch(err => {
        console.error(err.response.data);
        setLoading(false)
        setMessage("Une erreur s'est produite!")
      })

  };

  return (
    <Fragment>
      <Box mb={5} w="90%">
        <Heading
          bgGradient="linear(to-l,  #ffd140, #089bd7)"
          bgClip="text"
          fontSize={{ base: "2xl", sm: "3xl" }}
        >
          Nouvelle Formation
        </Heading>
      </Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Box
          w={isNotSmallerScreen ? "50%" : "100%"}
          bg={useColorModeValue("white", "gray.700")}
          borderRadius="lg"
          m={{ sm: 2, md: 2, lg: 2 }}
          p={{ sm: 4, md: 4, lg: 4 }}
        >
          <Box p={4} rounded="xl" align="center" justify="center">
            <VStack spacing={5} w="90%">
              {message && (
                <Alert status="error" rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              )}

              <Avatar
                size="xl"
                src={!selectedImage ? defaultImage : image}
                cursor="pointer"
                position="relative"
              >
                <AvatarBadge
                  borderColor="papayawhip"
                  bg="tomato"
                  boxSize="1.25em"
                >
                  <TbCameraPlus size={20} color="black" />
                </AvatarBadge>
                <InputGroup
                  borderColor="#E0E1E7"
                  position="absolute"
                  top="0"
                  left={0}
                >
                  <Input
                    opacity="0"
                    cursor="pointer"
                    type="file"
                    h={105}
                    name="file"
                    accept="image/*"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                    placeholder="Titre de Formation"
                  />
                </InputGroup>
              </Avatar>
              {/*  */}
              <FormControl id="name" isRequired>
                <FormLabel>Titre Formation</FormLabel>
                <InputGroup borderColor="#E0E1E7">
                  <Input
                    maxLength={50}
                    type="text"
                    size="lg"
                    name="titre"
                    bg="none"
                    onChange={(e) => setTitre(e.target.value)}
                    placeholder="Titre de Formation"
                  />
                </InputGroup>
                <Flex justify="end">
                  {titre.length > 40 ? (
                    <Text fontSize="xs" color="red">
                      {titre.length}/50 caractères !
                    </Text>
                  ) : (
                    <Text fontSize="xs">
                      {titre.length}/50{" "}
                      {titre.length > 1 ? "caractères" : "caractère"}
                    </Text>
                  )}
                </Flex>
              </FormControl>
              <FormControl id="description" isRequired>
                <FormLabel>Description</FormLabel>
                <Textarea
                  maxLength={250}
                  borderColor="gray.300"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                  _hover={{
                    borderRadius: "gray.300",
                  }}
                  placeholder="Description"
                />
                <Flex justify="end">
                  {description.length > 240 ? (
                    <Text fontSize="xs" color="red">
                      {description.length}/250 caractères !
                    </Text>
                  ) : (
                    <Text fontSize="xs">
                      {description.length}/250{" "}
                      {description.length > 1 ? "caractères" : "caractère"}
                    </Text>
                  )}
                </Flex>
              </FormControl>
              <FormControl id="etat" display="flex" justifyContent="start">
                <Checkbox
                  size="lg"
                  colorScheme="green"
                  name="etat"
                  onChange={() => setEtat(!etat)}
                >
                  Activez la formation
                </Checkbox>
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="end"
                id="name"
                float="right"
              >
                {!titre || !description || !selectedImage ? (
                  <Button
                    variant="solid"
                    bg="#ffd140"
                    color="black"
                    _hover="none"
                    title="Veuillez remplir tout les champs"
                    isDisabled
                  >
                    Ajouter Formation
                  </Button>
                ) : (
                  <Button
                  isLoading={loading}
                    variant="solid"
                    bg="#ffd140"
                    color="black"
                    _hover="none"
                    onClick={() => addFormation(titre)}
                  >
                    Ajouter Formation
                  </Button>
                )}
              </FormControl>
            </VStack>
          </Box>
        </Box>
        <Box boxSize="lg" display={isNotSmallerScreen ? "block" : "none"} p={5}>
          <Image src={imgAddFormation} alt="Dan Abramov" />
        </Box>
      </Flex>

      <Modal
        closeOnOverlayClick={!selectedImage ? true : false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajout de l'image</ModalHeader>
          <ModalBody pb={6}>
            <FormControl
              id="image"
              isRequired
              display="flex"
              justifyContent="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                height="200px"
                w="80%"
                border={!selectedImage ? "1px dashed" : null}
                position="relative"
                borderColor="#2b6cb0"
                cursor="pointer"
              >
                {!selectedImage && (
                  <>
                    <BsImageFill size="70px" color="#2b6cb0" />
                    <Text
                      fontSize={{ base: "md", md: "lg", lg: "lg" }}
                      fontWeight="semibold"
                      color="#2b6cb0"
                    >
                      Parcourir l'image à télécharger
                    </Text>
                  </>
                )}
                {image && selectedImage && (
                  <Box textAlign="center">
                    <Image
                      src={image}
                      w="full"
                      h="auto"
                      maxH="200px"
                      objectFit="contain"
                      alt="Dan Abramov"
                      rounded="lg"
                    />
                  </Box>
                )}
              </Flex>
              <InputGroup
                borderColor="#E0E1E7"
                position="absolute"
                top="0"
                left={10}
              >
                <Input
                  opacity="0"
                  cursor="pointer"
                  type="file"
                  height="200px"
                  w="80%"
                  size="lg"
                  name="file"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                  placeholder="Titre de Formation"
                  accept="image/jpg, image/png, image/jpeg, image/svg, image/tif"
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          {selectedImage && (
            <ModalFooter>
              <Button
                onClick={() => {
                  setSelectedImage(null)
                  onClose()
                }}
                colorScheme="red"
                mr={3}>
                <FaTimes />
              </Button>
              <Button
                onClick={onClose}
                colorScheme="whatsapp"
              >
                <AiOutlineCheck />
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Fragment>
  );
}

export default AddFormation;
