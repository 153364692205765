import { SearchIcon } from '@chakra-ui/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Stack,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { BsFillInfoSquareFill } from 'react-icons/bs';
import axiosClient from "../../axios-client";
import MyPagination from '../../components/MyPagination';
import { useStateContext } from "../../context/ContextProvider";

function DemandeAcces() {

    // current user
    const { user ,fetchCounts} = useStateContext();
    const [search, setSearch] = useState('')
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [paginationData, setPaginationData] = useState()
    const [loading, setLoading] = useState(true)
    //list acces
    const [listAcces, setListAcces] = useState([])
    //pagination

    const [currentPage, setCurrentPage] = useState(0);

    // Pagination variables
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    
    useEffect(() => {
        if (user.role === "RESPO") {

            axiosClient.get(`/acces/getDemandAccesByResponsable/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&idResponsable=${user.id}`)
                .then((response) => {

                    let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                    setPaginationData(response.data);

                    if (filteredData.length === 0) {
                        setCheckLengthData(true)
                    }
                    else {
                        setListAcces(filteredData);
                        setCheckLengthData(false)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false)
                });
        }
        if (user.role === 'SUPERADMIN' || user.role === "MASTER" || user.role === 'ADMIN') {
            axiosClient.get(`/acces/getAllDemandAcces/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}`)
                .then((response) => {

                    let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                    setPaginationData(response.data);

                    if (filteredData.length === 0) {
                        setCheckLengthData(true)
                    }
                    else {
                        setListAcces(filteredData);
                        setCheckLengthData(false)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false)
                });

        }

    }, [ pageSize, currentPage, search])

    function update(){
        if (user.role === "RESPO") {

            axiosClient.get(`/acces/getDemandAccesByResponsable/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}&idResponsable=${user.id}`)
                .then((response) => {

                    let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                    setPaginationData(response.data);

                    if (filteredData.length === 0) {
                        setCheckLengthData(true)
                    }
                    else {
                        setListAcces(filteredData);
                        setCheckLengthData(false)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false)
                });
        }
        if (user.role === 'SUPERADMIN' || user.role === "MASTER" || user.role === 'ADMIN') {
            axiosClient.get(`/acces/getAllDemandAcces/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${search}`)
                .then((response) => {

                    let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                    setPaginationData(response.data);

                    if (filteredData.length === 0) {
                        setCheckLengthData(true)
                    }
                    else {
                        setListAcces(filteredData);
                        setCheckLengthData(false)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false)
                });

        }
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef();


    const toast = useToast();

    //Accepter L'apprenant
    const Accept = (id, idM, idA) => {
        axiosClient.patch(`/acces/${id}/`, {
            etat: true,
            encours: false,
            firstAcces: true
        })
            .then(() => {
                update()
                setListAcces((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: `l'apprenant est accepté`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right",
                });
                fetchCounts()
            })

    }

    //Refuser l'apprenant
    const refus1 = (id) => {
        axiosClient.delete(`/acces/${id}/`)
            .then(() => {
                update()
                fetchCounts()
                setListAcces((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: `l'apprenant est refusé`,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right",
                });
            })
        onClose()

    }


    return (
        <Fragment>
            <Box>
                <Flex justifyContent="end" alignItems="center" mb={5}>
                    <InputGroup w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" />}
                        />
                        <Input
                            type="tel"
                            placeholder="Recherche..."
                            onChange={(e) => {
                                setCurrentPage(0)
                                setSearch(e.target.value)}}
                        />
                    </InputGroup>
                </Flex>
                {(paginationData?.length !== 0) &&
                    listAcces.map((val, key) => (
                                <Stack
                                    key={key}
                                    h="auto"
                                    borderRadius="lg"
                                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                    border="1px solid rgba(0, 0, 0, 0.25)"
                                    p={4}
                                    mb={3}
                                >

                                    <Flex align={'center'} direction={{ base: 'column', lg: 'row' }} justifyContent={'space-between'}>
                                        <Box flexGrow={1} display="flex">

                                            <Icon as={BsFillInfoSquareFill} h="32px" w="32px" mr="10px" />
                                            <Box >
                                                L'apprenant {val.first_name} {val.last_name} demande l'accès au module {val.module_name}
                                            </Box>
                                        </Box>
                                        <Flex mt={{ base: 2, lg: 0 }} align={'center'} justify={'center'}>
                                            <Button mr={2} colorScheme='teal' onClick={() => Accept(val.id, val.module, val.apprenant)}>Accepter</Button>
                                            <Button colorScheme='red' onClick={() => {
                                                setDeleteId(val.id);
                                                onOpen();
                                            }}>Refuser</Button>
                                        </Flex>
                                    </Flex>

                                </Stack>
                            ))}
                {(((search) && paginationData?.length === 0) || (checkLengthData)) && (
                    <Text>Aucune ligne correspondante n'a été trouvée.</Text>

                )}
              <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                    <Box flex="1">
                        <MyPagination
                            paginationData={paginationData}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            PAGE_SIZE={pageSize}
                        />
                    </Box>

                    <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                    </Select>
                </Flex>


            </Box>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Refus d'activation
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            êtes-vous sûr ? Vous ne pourrez pas annuler cette action
                            ultérieurement.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button
                                onClick={() => refus1(deleteId)}
                                colorScheme="red"
                                ml={3}
                            >
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Fragment>
    )
}

export default DemandeAcces
