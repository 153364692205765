import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../MyPagination";
import { MdHistory } from 'react-icons/md';
import moment from 'moment';
import { jsx, css, keyframes } from '@emotion/react';


import {
    Flex,
    Box,
    Heading,
    Button,
    Stack,
    Table,
    Thead,
    Tbody,
    InputGroup,
    InputLeftElement,
    Tr,
    Th,
    Avatar,
    Td,
    Input,
    FormControl,
    FormLabel,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    IconButton,
    Switch,
    Badge,
    useToast,
    Tooltip,
    Tab,
    TabList,
    Tabs,
    TabPanels,
    TabPanel,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    Text,
    SkeletonCircle,
    Skeleton,

} from '@chakra-ui/react';
import { useStateContext } from '../../context/ContextProvider';
import { TbHomeCog } from 'react-icons/tb';

export const GestApprenant = () => {
    const { user, setUser } = useStateContext();
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedSociety, setSelectedSociety] = useState('');
    const [currentPage, setCurrentPage] = useState(0);

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const buttonAnimation = keyframes`
    from {
      width: 40px; /* Adjust the initial width of the button as needed */
    }
    to {
      width: 200px; /* Adjust the expanded width of the button as needed */
    }
  `;
    const fadeInAnimation = keyframes`
  from {
    width: 20px; /* Adjust the initial width of the button as needed */
  }
  to {
    width: 100px; /* Adjust the expanded width of the button as needed */
  }
`;


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };
    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const [dataCompany, setDataCompany] = useState([]);

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Apprenant data
    const [dataE, setDataE] = useState([]);
    const [dataI, setDataI] = useState([]);
    const [dataInterne, setDataInterne] = useState([])

    const [checkLengthDataE, setCheckLengthDataE] = useState(false)
    const [checkLengthDataI, setCheckLengthDataI] = useState(false)

    const [paginationDataE, setPaginationDataE] = useState()
    const [paginationDataI, setPaginationDataI] = useState()
    const [loading, setLoading] = useState(true)
    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the apprenant data

    useEffect(() => {
        axiosClient.get(`/apprenants/getAllApprenantsInternes/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&society=${selectedSociety}`)
            .then((response) => {

                let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                setPaginationDataI(response.data || []);

                if (filteredData.length === 0) {
                    setCheckLengthDataI(true)
                }
                else {
                    setDataI(filteredData);
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });

        axiosClient.get(`/apprenants/getAllApprenantsExternes/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {

                let filteredData = response?.data?.results ? response?.data?.results : [] || [];
                setPaginationDataE(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthDataE(true)
                }
                else {
                    setDataE(filteredData);
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false)
            });

    }, [user, selectedSociety, selectedEtat, pageSize, currentPage, searchTerm])

    //get the company data
    useEffect(() => {
        axiosClient.get('/company/?page=1&page_size=10000')
            .then((response) => {
                setDataCompany(response.data.results);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);


    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/apprenants/${id}/`)
            .then((response) => {
                setDataI((prevData) => prevData.filter((row) => row.id !== id));
                setDataE((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "l'apprenant est supprimé avec succès",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //edit l'etat d'apprenant ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/etatAppr_update/${id}/`, formData)
            .then((response) => {
                const updatedRow = response.data;
                // Update the row object with the new etat value
                setDataI(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                            date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                            edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                        };
                    }
                    return row;
                }));
                setDataE(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                            date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                            edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: "le compte est activé",
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: "le compte est désactivé",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }

    const [switchEtat, setSwitchEtat] = useState(0)

    const viderInputs = () => {
        setSearchTerm('')
        setSelectedSociety('')
    }
    const formatDate = (dateString) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

    };

    return (
        <Box mt="5px">
            <Flex>
                <Box w="90%" >
                    <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: '2xl', sm: '3xl' }}
                    >
                        Utilisateurs du système de formation
                    </Heading>
                </Box>
            </Flex>
            <Box
                justify={'end'}
                align={'end'}
                mt={5}
            >
                {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&

                    <Button
                        mt="10px"
                        onClick={() => navigate('/addApprenant')}
                        colorScheme="blue"
                        mb={{ base: 5, lg: 0 }}
                        leftIcon={<AddIcon />}>
                        Ajouter Apprenant
                    </Button>}
            </Box>
            <Tabs variant="enclosed">
                <TabList>
                    <Tab _selected={{ color: "white", bg: "#089bd7" }} onClick={() => {
                        viderInputs()
                        setSelectedEtat(null)
                    }}>Apprenants internes</Tab>
                    <Tab _selected={{ color: "white", bg: "#089bd7" }} onClick={() => {
                        viderInputs()
                        setSelectedEtat(null)
                    }}>Apprenants externes</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Flex>
                            <Stack
                                w={'full'}
                                maxW='full'
                                bg={useColorModeValue('white', 'gray.700')}
                                rounded={'lg'}
                                p={6}
                                my={5}>
                                {/* Search input */}
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction={{ base: 'column', lg: 'row' }}
                                >
                                    <Flex w={"100%"} direction={{ base: 'column', lg: 'row' }}>
                                        <Flex w={"100%"}>
                                            <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={3} value={selectedSociety} onChange={(e) => {
                                                setCurrentPage(0)
                                                setSelectedSociety(e.target.value)
                                            }}>

                                                <option value="">Toutes les sociétés</option>
                                                {dataCompany.map((company, key) => (
                                                    <option key={key} value={company.name}>
                                                        {company.name}
                                                    </option>
                                                ))}
                                            </Select>

                                            <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '30%', xl: "20%" }} mr={{ base: 0, lg: 5 }} mb={3} value={selectedEtat} onChange={(e) => {
                                                setCurrentPage(0)
                                                setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')
                                            }}>
                                                <option value="">Tout</option>
                                                <option value="true">Activé</option>
                                                <option value="false">Désactivé</option>
                                            </Select>
                                        </Flex>
                                        {user.role !== "RESPO" &&
                                            <Flex w={{ base: "100%", lg: "fit-content" }}>
                                                <Button
                                                    className="hoverable"
                                                    size={{ base: "sm", md: "md", lg: "md" }}
                                                    mb={{ base: 3, lg: 0 }}
                                                    onClick={() => {
                                                        navigate('/societe')
                                                    }}
                                                    w={{ base: "100%" }}
                                                    mr={{ base: 0, md: 2, lg: 2 }}
                                                    leftIcon={<TbHomeCog fontSize={20} />}

                                                >Gestion société</Button>
                                            </Flex>}

                                        {/* <Flex>
                                            <Button
                                                _hover={{
                                                    ".myText": {
                                                        display: "block",
                                                        animation: `${fadeInAnimation} 0.5s ease forwards`,

                                                    },
                                                }}
                                                
                                                className="hoverable"
                                                size={"md"}
                                                leftIcon={<TbHomeCog fontSize={20} />}
                                            >
                                                <Text className="myText" style={{"transition": '.5 ease',}} display="none">Gestion société</Text>
                                            </Button>
                                        </Flex> */}
                                    </Flex>

                                    <InputGroup w={{ base: '100%', lg: "30%" }}>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<SearchIcon color='gray.300' />}
                                        />
                                        <Input
                                            placeholder="Recherche..."
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setCurrentPage(0)
                                                setSearchTerm(e.target.value)
                                            }}
                                            mb={4}
                                            sx={{
                                                marginLeft: 'auto',
                                            }}
                                        />  </InputGroup>
                                </Flex>

                                <TableContainer>
                                    <Table >
                                        <Thead>
                                            <Tr>
                                                <Th>Image</Th>
                                                <Th>Code</Th>
                                                <Th>Nom</Th>
                                                <Th>Prénom</Th>
                                                <Th>Email</Th>
                                                <Th >Adresse</Th>
                                                <Th >Pays</Th>
                                                <Th>Etat</Th>
                                                <Th>Date Creation</Th>
                                                <Th>Options</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody >
                                            {loading &&
                                                <Tr>
                                                    <Td><SkeletonCircle size='10' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                </Tr>}
                                            {(paginationDataI?.length !== 0) &&
                                                dataI.map((row) => (
                                                    <Tr key={row.id} >
                                                        <Td> <Avatar src={row.image} /></Td>
                                                        <Td>{row.code}</Td>
                                                        <Td>{row.last_name}</Td>
                                                        <Td>{row.first_name}</Td>
                                                        <Td>{row.email}</Td>
                                                        <Td>
                                                            {/* <Tooltip label={row.address} closeOnClick={true}> */}

                                                            <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                                {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                                            </Tooltip>

                                                        </Td>
                                                        <Td>{row.pays}</Td>
                                                        <Td p={5}>
                                                            <Flex>
                                                                {user.role !== "RESPO" &&
                                                                    <Stack direction='row' mr="3px">
                                                                        <Switch
                                                                            colorScheme='green'
                                                                            isChecked={row.etat}
                                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                                        />

                                                                    </Stack>
                                                                }

                                                                <Badge
                                                                    rounded={'lg'}
                                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                                >
                                                                    {row.etat ? 'activé' : 'désactivé'}
                                                                </Badge>
                                                            </Flex>
                                                        </Td>
                                                        <Td>{new Date(row.date_creation).toLocaleDateString('fr-FR')}</Td>
                                                        <Td>
                                                            <IconButton
                                                                onClick={() => navigate(`/apprenant`, {
                                                                    state: {
                                                                        idApprenant: row.id
                                                                    }
                                                                })}
                                                                size="sm"
                                                                ml="5px"
                                                                colorScheme='blue'
                                                                icon={<AiFillEye />}>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => navigate(`/editApprenant`, {
                                                                    state: {
                                                                        idApprenant: row.id
                                                                    }
                                                                })}
                                                                size="sm"
                                                                ml="5px"
                                                                colorScheme='green'
                                                                icon={<EditIcon />}>
                                                            </IconButton>

                                                            <Popover placement="left" >
                                                                <PopoverTrigger>
                                                                    <IconButton
                                                                        size="sm"
                                                                        ml="5px"
                                                                        bg="gray.300"
                                                                        color={"black"}
                                                                        icon={<MdHistory />}>
                                                                    </IconButton>
                                                                </PopoverTrigger>
                                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                                        Historique
                                                                    </PopoverHeader>
                                                                    <PopoverArrow bg="blue.200" />
                                                                    <PopoverCloseButton />
                                                                    <PopoverBody>
                                                                        <div style={{ marginBottom: '8px' }}>
                                                                            Ajouté par : {row.added_by}
                                                                        </div>
                                                                        <div style={{ marginBottom: '8px' }}>
                                                                            Modifié par : {row.edited_by}
                                                                        </div>

                                                                        <div>
                                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                                        </div>
                                                                    </PopoverBody>
                                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                                    </PopoverHeader>
                                                                </PopoverContent>
                                                            </Popover>

                                                            {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                                                <IconButton
                                                                    size="sm"
                                                                    ml="5px"
                                                                    onClick={() => {
                                                                        setDeleteId(row.id);
                                                                        onOpenAlert();
                                                                    }}
                                                                    colorScheme='red'
                                                                    icon={<DeleteIcon />}>

                                                                </IconButton>}



                                                        </Td>
                                                    </Tr>

                                                ))}
                                            {/* {(((searchTerm || selectedEtat !== null || selectedSociety !== null) && paginationDataI?.length === 0) || (checkLengthDataI)) && (
                                                <Tr>
                                                    <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                                                </Tr>
                                            )} */}
                                            {(paginationDataI?.length === 0 || paginationDataI?.results?.length === 0) && (
                                                <Tr>
                                                    <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>

                                </TableContainer>
                                {/* Pagination */}
                                <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                                    <Box flex="1">
                                        <MyPagination
                                            paginationData={paginationDataI}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            PAGE_SIZE={pageSize}
                                        />
                                    </Box>

                                    <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        {/* Add more options as needed */}
                                    </Select>
                                </Flex>
                            </Stack>
                        </Flex>
                    </TabPanel>

                    <TabPanel>
                        <Flex>
                            <Stack
                                w={'full'}
                                maxW='full'
                                bg={useColorModeValue('white', 'gray.700')}
                                rounded={'lg'}
                                p={6}
                                my={5}>
                                {/* Search input */}
                                <Flex
                                    justifyContent="end"
                                    alignItems="center"
                                >

                                    <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '15%' }} mr={5} mb={4} value={selectedEtat} onChange={(e) => {
                                        setCurrentPage(0)
                                        setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')
                                    }}>
                                        <option value="">Tout</option>
                                        <option value="true">Activé</option>
                                        <option value="false">Désactivé</option>
                                    </Select>

                                    <InputGroup w={{ base: '70%', lg: "30%" }}>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<SearchIcon color='gray.300' />}
                                        />
                                        <Input
                                            placeholder="Chercher"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setCurrentPage(0)
                                                setSearchTerm(e.target.value)
                                            }}
                                            mb={4}
                                            sx={{
                                                marginLeft: 'auto',
                                            }}
                                        />  </InputGroup>
                                </Flex>

                                <TableContainer>
                                    <Table >
                                        <Thead>
                                            <Tr>
                                                <Th>Image</Th>
                                                <Th>Code</Th>
                                                <Th>Nom</Th>
                                                <Th>Prénom</Th>
                                                <Th>Email</Th>
                                                <Th >Adresse</Th>
                                                <Th >Pays</Th>
                                                <Th>Etat</Th>
                                                <Th>Date Creation</Th>
                                                <Th>Options</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody >
                                            {loading &&
                                                <Tr>
                                                    <Td><SkeletonCircle size='10' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                </Tr>}
                                            {(paginationDataE?.length !== 0) &&
                                                dataE.map((row) => (
                                                    <Tr key={row.id} >
                                                        <Td> <Avatar src={row.image} /></Td>
                                                        <Td>{row.code}</Td>
                                                        <Td>{row.last_name}</Td>
                                                        <Td>{row.first_name}</Td>

                                                        <Td>{row.email}</Td>
                                                        <Td>
                                                            {/* <Tooltip label={row.address} closeOnClick={true}> */}

                                                            <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                                {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                                            </Tooltip>

                                                        </Td>
                                                        <Td>{row.pays}</Td>
                                                        <Td p={5}>
                                                            <Flex>
                                                                {user.role !== "RESPO" &&
                                                                    <Stack direction='row' mr="3px">
                                                                        <Switch
                                                                            colorScheme='green'
                                                                            isChecked={row.etat}
                                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                                        />

                                                                    </Stack>
                                                                }

                                                                <Badge
                                                                    rounded={'lg'}
                                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                                >
                                                                    {row.etat ? 'activé' : 'désactivé'}
                                                                </Badge>
                                                            </Flex>
                                                        </Td>
                                                        <Td>{new Date(row.date_creation).toLocaleDateString('fr-FR')}</Td>
                                                        <Td>
                                                            <IconButton
                                                                onClick={() => navigate(`/apprenant`, {
                                                                    state: {
                                                                        idApprenant: row.id
                                                                    }
                                                                })}
                                                                size="sm"
                                                                ml="5px"
                                                                colorScheme='blue'
                                                                icon={<AiFillEye />}>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => navigate(`/editApprenant`, {
                                                                    state: {
                                                                        idApprenant: row.id
                                                                    }
                                                                })}
                                                                size="sm"
                                                                ml="5px"
                                                                colorScheme='green'
                                                                icon={<EditIcon />}>
                                                            </IconButton>
                                                            <Popover placement="left" >
                                                                <PopoverTrigger>
                                                                    <IconButton
                                                                        size="sm"
                                                                        ml="5px"
                                                                        bg="gray.300"
                                                                        color={"black"}
                                                                        icon={<MdHistory />}>
                                                                    </IconButton>
                                                                </PopoverTrigger>
                                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                                        Historique
                                                                    </PopoverHeader>
                                                                    <PopoverArrow bg="blue.200" />
                                                                    <PopoverCloseButton />
                                                                    <PopoverBody>
                                                                        <div style={{ marginBottom: '8px' }}>
                                                                            Ajouté par : {row.added_by}
                                                                        </div>
                                                                        <div style={{ marginBottom: '8px' }}>
                                                                            Modifié par : {row.edited_by}
                                                                        </div>

                                                                        <div>
                                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                                        </div>
                                                                    </PopoverBody>
                                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                                    </PopoverHeader>
                                                                </PopoverContent>
                                                            </Popover>

                                                            {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                                                <IconButton
                                                                    size="sm"
                                                                    ml="5px"
                                                                    onClick={() => {
                                                                        setDeleteId(row.id);
                                                                        onOpenAlert();
                                                                    }}
                                                                    colorScheme='red'
                                                                    icon={<DeleteIcon />}>

                                                                </IconButton>}



                                                        </Td>
                                                    </Tr>

                                                ))}
                                            {(paginationDataE?.length === 0 || paginationDataE?.results?.length === 0) && (
                                                <Tr>
                                                    <Td colSpan={10}>Aucune ligne correspondante n'a été trouvée.</Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>

                                </TableContainer>
                                {/* Pagination */}
                                <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                                    <Box flex="1">
                                        <MyPagination
                                            paginationData={paginationDataE}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            PAGE_SIZE={pageSize}
                                        />
                                    </Box>

                                    <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        {/* Add more options as needed */}
                                    </Select>
                                </Flex>
                            </Stack>
                        </Flex>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Supprimer apprenant
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Attention ! Cette suppression est irréversible. Êtes-vous sûr de vouloir continuer ? Toutes les données de l'apprenant seront perdues définitivement.</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Annuler
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Modal
                isOpen={isModalOpen}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                onClose={onCloseModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create your account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>First name</FormLabel>
                            <Input ref={initialRef} placeholder='First name' />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Last name</FormLabel>
                            <Input placeholder='Last name' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3}>
                            Save
                        </Button>
                        <Button onClick={onCloseModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default GestApprenant